import "toastify-js/src/toastify.css";
import notification from "@/assets/notification.mp3";
import Toastify from "toastify-js";
import { ref } from "vue";

const defaultText = "Что-то пошло не так";
const types = {
  error: "error",
  warning: "warn",
  success: "success",
  info: "info",
};

const toaster = (type) => {
  return (msg, onClickFunction) => {
    new Audio(notification).play();
    const toast = Toastify({
      text: msg,
      className: type,
      duration: 5000,
      escapeMarkup: false,
      onClick() {
        if (onClickFunction) {
          onClickFunction();
        }
        toast.hideToast();
      },
    });
    toast.showToast();
  };
};

const Toast = {
  error: toaster("error"),
  warning: toaster("warn"),
  success: toaster("success"),
  info: toaster("info"),
};

export default defineNuxtPlugin((nuxtApp) => {
  const doNoty = {};

  for (const key in types) {
    doNoty[key] = (msg, cb) => {
      return new Promise((resolve) => {
        const msgText = msg || defaultText;
        Toast[key](msgText, cb);
        resolve(true);
      });
    };
  }

  nuxtApp.provide("doNoty", doNoty);
});
