<template>
  <div class="order-modal__specialist-block order-modal__step1-block">
    <div class="order-modal__subtitle">Выберите услугу</div>
    <div class="order-modal__specialist-blockInner custom-scroll">
      <client-only>
        <div class="row">
          <swiper
            v-bind="swiperOptions"
            ref="servicesSlider"
            class="order-modal__services-swiper swiper"
            :class="{
              'order-modal__services-swiper--no-arrows': !showServicesSliderArrows,
            }">
              <swiper-slide
                v-for="service in services"
                :key="service.id"
                class="swiper-slide"
                @click="selectService(service)">
                <ServiceCard
                  class="order-modal__service"
                  :service="service"
                  :selected="
                    order.serviceId === service.serviceId &&
                    order.price === service.price
                  "
                  hide-booling
                  hide-favorite
                  is-small />
              </swiper-slide>
            <button
              v-if="services.length > 4"
              class="order-modal__specialist-button order-modal__services-button-next"
            ></button>
            <button
              v-if="services.length > 4"
              class="order-modal__specialist-button order-modal__services-button-prev"
            ></button>
          </swiper>
        </div>
      </client-only>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import ServiceCard from "@/views/Salon/components/ServiceCard.vue";

export default {
  name: "SelectService",
  components: {
    ServiceCard,
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      swiperOptions: {
        slidesPerView: 4,
        spaceBetween: 15,
        loop: false,
        navigations: {
          nextEl: ".order-modal__services-button-next",
          prevEl: ".order-modal__services-button-prev",
        },
        breakpoints: {
          0: {
            slidesPerView: 2,
          },
          575: {
            slidesPerView: 2,
          },
          767: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 4,
          },
        },
      },
      swiperModules: [Navigation],
    };
  },

  computed: {
    order() {
      return useOrder();
    },
    order() {
      return this.orderModule.order;
    },

    services() {
      if (!this.orderModule.searchServices) return [];
      const services = this.orderModule.searchServices
        .filter((item) => item.categoryId === this.order.categoryId);

      return services;
    },

    showServicesSliderArrows() {
      if (!this.$refs.servicesSlider) return true;
      const breakpoint = this.$refs.servicesSlider.swiper.currentBreakpoint;
      return (
        this.services.length >
        this.swiperOption.breakpoints[breakpoint].slidesPerView
      );
    },
  },

  created() {
    if (this.services.length === 1) {
      this.selectService(this.services[0]);
    }
  },

  methods: {
    selectService(service) {
      if (
        this.order.serviceId === service.serviceId &&
        this.order.price === service.price
      ) {
        this.orderModule.updateOrder({
          serviceId: 0,
          serviceTitle: "",
          price: 0,
        });
        return;
      }
      this.orderModule.updateOrder({
        serviceId: service.serviceId,
        serviceTitle: service.serviceTitle,
        price: service.price,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.order-modal__service {
  width: auto;
}
</style>
