import { Model } from "@/stores/models/Model";
import { getRatingTitle } from "@/helpers/rating";
import { getNumberWithoutPercent } from "@/helpers/percents";
import { Salon } from "./Salon";
import { SpecialistService } from "./SpecialistService";
import { getImageFromSrc } from "@/helpers/images";

export class SearchService extends Model {
  constructor(raw = {}) {
    super();
    this.id = Number(raw.id);

    this.duration = Number(raw.duration);
    this.price = Number(raw.price || 0);
    this.priceWithDiscount =
      Math.floor(
        getNumberWithoutPercent(this.price, import.meta.env.VITE_DISCOUNT)
      ) || 0;
    this.priceWithCancelDiscount =
      Math.floor(
        getNumberWithoutPercent(this.price, import.meta.env.VITE_DISCOUNT_WITH_CANCEL)
      ) || 0;
    this.gender = String(raw.gender || "");

    this.specialistService = new SpecialistService(raw.specialist_service);
    this.specialistServiceId = Number(raw.specialist_service_id);

    this.salon = new Salon(raw.salon);

    const service = raw.specialist_service.service || {};
    const category = raw.category || {};
    const data = raw.specialist_service.data || {};
    this.title = service.title || "";
    this.image = getImageFromSrc(data.img);
    this.cover = getImageFromSrc(raw.data?.cover);
    this.isBestPrice = Boolean(data.is_best_price);
    this.serviceId = Number(service.id) || 0;
    this.categoryId = category.id || 0;
    this.categoryTitle = category.title || "";

    this.reviewsCount = Number(raw.review_count || 0);
    this.rating = Number(raw.rating || 0);
    this.ratingTitle = getRatingTitle(this.rating);
  }
}
