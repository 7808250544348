const SALONS_STORAGE_KEY = "favorite_salons";
const SERVICES_STORAGE_KEY = "favorite_services";
const SPECIALISTS_STORAGE_KEY = "favorite_specialists";

import { useAccount } from "./account";

export const useFavoritesModule = defineStore("favoritesModule", {
  state: () => ({
    favoriteSalons: [],
    favoriteServices: [],
    favoriteSpecialists: [],
  }),

  getters: {},

  actions: {
    init() {
      this.loadFavoriteSalons();
      this.loadFavoriteServices();
      this.loadFavoriteSpecialists();
    },

    loadFavoriteSalons() {
      const accountModule = useAccount();
      const userFavorites = accountModule.userInfo.favoriteSalons || [];
      if (userFavorites.length) {
        this.favoriteSalons = userFavorites;
        return;
      }

      const raw = localStorage.getItem(SALONS_STORAGE_KEY);
      try {
        this.favoriteSalons = JSON.parse(raw) || [];
      } catch (err) {
        this.favoriteSalons = [];
      }
    },

    setFavoriteSalon({ salonId, isFavorite }) {
      const accountModule = useAccount();
      if (isFavorite) {
        this.favoriteSalons = [...this.favoriteSalons, salonId];
      } else {
        this.favoriteSalons = this.favoriteSalons.filter(
          (item) => item !== salonId
        );
      }

      if (accountModule.userId) {
        accountModule.updateUserInfo({
          params: { favoriteSalons: this.favoriteSalons },
          successMessage: isFavorite
            ? "Добавлено в избранное"
            : "Удалено из избранного",
        });
      } else {
        localStorage.setItem(
          SALONS_STORAGE_KEY,
          JSON.stringify(this.favoriteSalons)
        );
      }
    },

    loadFavoriteServices() {
      const accountModule = useAccount();
      const userFavorites = accountModule.userInfo.favoriteServices || [];
      if (userFavorites.length) {
        this.favoriteServices = userFavorites;
        return;
      }

      const raw = localStorage.getItem(SERVICES_STORAGE_KEY);
      try {
        this.favoriteServices = JSON.parse(raw) || [];
      } catch (err) {
        this.favoriteServices = [];
      }
    },

    setFavoriteService({ serviceId, specialistId, isFavorite }) {
      const accountModule = useAccount();
      let specialistIndex = this.favoriteServices.findIndex(
        (item) => specialistId === item.specialistId
      );

      if (!~specialistIndex) {
        this.favoriteServices.push({
          specialistId,
          items: [],
        });
        specialistIndex = this.favoriteServices.length - 1;
      }
      const services = this.favoriteServices[specialistIndex].items;
      if (isFavorite) {
        this.favoriteServices[specialistIndex].items = [
          ...new Set([...services, serviceId]),
        ];
      } else {
        this.favoriteServices[specialistIndex].items = services.filter(
          (item) => item !== serviceId
        );
      }

      this.favoriteServices = this.favoriteServices.filter(
        (item) => item.items.length
      );

      if (accountModule.userId) {
        accountModule.updateUserInfo({
          params: { favoriteServices: this.favoriteServices },
          successMessage: isFavorite
            ? "Добавлено в избранное"
            : "Удалено из избранного",
        });
      } else {
        localStorage.setItem(
          SERVICES_STORAGE_KEY,
          JSON.stringify(this.favoriteServices)
        );
      }
    },

    loadFavoriteSpecialists() {
      const accountModule = useAccount();
      const userFavorites = accountModule.userInfo.favoriteSpecialists || [];
      if (userFavorites.length) {
        this.favoriteSpecialists = userFavorites;
        return;
      }

      const raw = localStorage.getItem(SPECIALISTS_STORAGE_KEY);
      try {
        this.favoriteSpecialists = JSON.parse(raw) || [];
      } catch (err) {
        this.favoriteSpecialists = [];
      }
    },

    setFavoriteSpecialist({ specialistId, isFavorite }) {
      const accountModule = useAccount();
      if (isFavorite) {
        this.favoriteSpecialists = [...this.favoriteSpecialists, specialistId];
      } else {
        this.favoriteSpecialists = this.favoriteSpecialists.filter(
          (item) => item !== specialistId
        );
      }

      if (accountModule.userId) {
        accountModule.updateUserInfo({
          params: { favoriteSpecialists: this.favoriteSpecialists },
          successMessage: isFavorite
            ? "Добавлено в избранное"
            : "Удалено из избранного",
        });
      } else {
        localStorage.setItem(
          SERVICES_STORAGE_KEY,
          JSON.stringify(this.favoriteSpecialists)
        );
      }
    },
  },
});
