<template>
  <b-modal id="postpone-modal" hide-header hide-footer @show="onShow">
    <div
      class="modal show"
      id="salon-page__postpone-modal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      @click="$bvModal.hide('postpone-modal')"
    >
      <div
        v-if="order"
        class="modal-dialog modal-dialog-scrollable modal-dialog-centered"
        @click.stop
      >
        <div class="modal-content">
          <div ref="body" class="modal-body">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="$bvModal.hide('postpone-modal')"
            />

            <div class="postpone-modal__step1">
              <div class="postpone-modal__step1-header">
                <div class="postpone-modal__title">
                  Выберите новую дату и время
                </div>
                <div class="modal-header__subtitle">
                  {{ service.serviceTitle }}
                </div>
              </div>

              <div
                class="postpone-modal__data-block postpone-modal__step1-block"
              >
                <DateTimePicker
                  v-model="selectedDate"
                  :slots="timeSlots"
                  :loading="isLoading"
                  @input="selectDate"
                >
                  <template #title>
                    <div class="postpone-modal__subtitle">
                      Выберите дату и время
                    </div>
                  </template>
                </DateTimePicker>

                <p
                  v-if="errors.date || !isDateValid"
                  class="postpone-modal__error"
                >
                  <template v-if="!isDateValid">
                    Пожалуйста, выберите другое время
                  </template>
                  <template v-else>Пожалуйста, выберите время</template>
                </p>
              </div>

              <button
                type="button"
                class="postpone-modal__step1-btnNext"
                :disabled="isDateChecking"
                @click="onNextClick"
              >
                <template v-if="isDateChecking">
                  Проверка расписания…
                </template>
                <template v-else> Далее </template>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import DateTimePicker from "@/components/Common/DateTimePicker.vue";
import BModal from "@/components/Modals/BModal/BModal.vue";

import { getDateString, getDayStartDate } from "@/helpers/date";
import { mainApi } from "@/stores/api/main-api";

export default {
  name: "PostponeModal",
  components: {
    DateTimePicker,
    BModal,
  },

  data() {
    return {
      isLoading: false,
      isDateChecking: false,
      selectedDate: getDayStartDate(),
      isDateValid: true,
      errors: {
        date: false,
      },
      schedule: [],
      date: null,
    };
  },

  computed: {
    order() {
      const ordersModule = useOrdersModule();
      return ordersModule.selectedOrder;
    },

    service() {
      return this.order.specialistService;
    },

    duration() {
      return this.service ? this.service.duration : 0;
    },

    timeSlots() {
      if (!this.schedule.length) return [];

      const slots = [];
      this.schedule.forEach((slot) => {
        const HALF_HOUR = 30 * 60 * 1000;
        let startTime = new Date(slot[0]).getTime();
        let endTime = new Date(slot[1]).getTime();

        startTime = Math.max(startTime, Date.now());
        endTime -= this.service.duration * 60 * 1000;

        if (startTime % HALF_HOUR > 0) {
          startTime = startTime - (startTime % HALF_HOUR) + HALF_HOUR;
        }

        while (startTime <= endTime) {
          slots.push(new Date(startTime));
          startTime += HALF_HOUR;
        }
      });

      return slots.map((slot) => {
        const hoursStr = String(slot.getHours()).padStart(2, "0");
        const minutesStr = String(slot.getMinutes()).padStart(2, "0");
        return `${hoursStr}:${minutesStr}`;
      });
    },
  },

  methods: {
    onShow() {
      this.isLoading = false;
      this.isDateChecking = false;
      this.selectedDate = getDayStartDate();
      this.isDateValid = true;
      this.errors = { date: false };
      this.schedule = [];
      this.date = null;
      this.loadSchedule();
    },

    selectDate(val) {
      const date = new Date(val);
      this.selectedDate = date;

      if (date.getHours() === 0 && date.getMinutes() === 0) {
        this.date = null;
        this.loadSchedule();
        return;
      }

      this.date = val;
      this.errors.date = "";
      this.isDateValid = true;
    },

    async loadSchedule() {
      this.isLoading = true;
      try {
        this.schedule = [];
        const date = getDateString(this.selectedDate);
        const { data } = await mainApi.getFreeTime({
          specialist_id: this.order.specialistId,
          date,
        });

        this.schedule = data.free_time;
      } catch (err) {
        const { $doNoty: doNoty } = useNuxtApp();
        doNoty.error(err);
        console.error(err);
      }

      this.isLoading = false;
    },

    async onNextClick() {
      const ordersModule = useOrdersModule();
      if (!this.validateForm()) return;

      this.isDateChecking = true;
      try {
        const startDate = this.date;
        const endDate = new Date(startDate);
        endDate.setTime(endDate.getTime() + this.service.duration * 60 * 1000);

        const { data } = await mainApi.allowOrder({
          specialist_id: this.order.specialistId,
          start_at: this.formatDate(startDate),
          finish_at: this.formatDate(endDate),
        });

        if (!data.allow_order) {
          this.isDateValid = false;
          this.date = null;
          this.loadSchedule();
          return;
        }

        await ordersModule.postponeOrder({
          startAt: this.formatDate(startDate),
          finishAt: this.formatDate(endDate),
        });
        this.$bvModal.hide("postpone-modal");
      } catch (err) {
        const { $doNoty: doNoty } = useNuxtApp();
        doNoty.error(err);
      } finally {
        this.isDateChecking = false;
      }
    },

    validateForm() {
      this.errors.date = this.order.date === null;
      return !this.errors.date;
    },

    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");

      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
  },
};
</script>

<style lang="scss">
@import "postpone-modal";

#postpone-modal {
  & .modal-dialog > .modal-content {
    padding: 0px;
  }

  & > .modal-dialog > .modal-content > .modal-body {
    padding: 0;
  }

  .modal {
    display: initial;
  }

  .date-time-picker__timepicker {
    max-width: 330px;
  }
}
</style>
