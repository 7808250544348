import { Model } from "@/stores/models/Model";
import { Salon } from "./Salon";
import { getImageFromSrc } from "@/helpers/images";

export class Review extends Model {
  constructor(raw = {}) {
    super();
    this.id = Number(raw.id);

    this.salonId = Number(raw.salon_id) || 0;
    this.salonName = String(raw.salon_name || "");
    this.salonIcon = String(raw.salon_icon || "");

    this.rating = Number(raw.rating || 0);
    this.text = String(raw.body || "");

    const data = raw.data || {};
    this.name = String(data.name || "–");
    this.avatarSrc = getImageFromSrc(data.avatar_src);

    this.salon = new Salon(raw.reviewable || {});
  }
}
