import { reactive } from "vue";
export default defineNuxtPlugin((nuxtApp) => {
  const events = reactive({});
  nuxtApp.provide("eventHub", {
    $on(event, func) {
      if (!events[event]) {
        events[event] = [];
      }
      if (!events[event].find((savedFunc) => savedFunc === func)) {
        events[event].push(func);
      }
    },
    $off(event, func) {
      if (!events[event]) {
        events[event] = [];
      }
      const index = events[event].findIndex((savedFunc) => savedFunc === func);
      if (index !== -1) {
        events[event].splice(index, 1);
      }
    },
    $emit(event, data) {
      if (!events[event]) {
        events[event] = [];
      }
      for (const func of events[event]) {
        func(data);
      }
    },
  });
});
