import { Model } from "@/stores/models/Model";
import { getRatingTitle } from "@/helpers/rating";
import { getNumberWithoutPercent } from "@/helpers/percents";
import { Service } from "./Service";
import { getImageFromSrc } from "@/helpers/images";

export class SpecialistService extends Model {
  constructor(raw = {}) {
    super();
    this.id = Number(raw.id);
    this.specialistId = Number(raw.specialist_id);

    this.duration = Number(raw.duration);
    this.price = Number(raw.price || 0);
    this.priceWithDiscount =
      Math.floor(
        getNumberWithoutPercent(this.price, import.meta.env.VITE_DISCOUNT)
      ) || 0;
    this.priceWithCancelDiscount =
      Math.floor(
        getNumberWithoutPercent(this.price, import.meta.env.VITE_DISCOUNT_WITH_CANCEL)
      ) || 0;
    this.gender = String(raw.gender || "");

    this.service = new Service(raw.service);
    this.serviceId = this.service.id || raw.service_id;
    this.serviceTitle = this.service.title || "";
    this.title = this.service.title || "";

    this.categoryId = this.service.categoryId;
    this.categoryTitle = this.service.categoryTitle;

    const data = raw.data || {};
    this.bodyPartId = data.body_part_id;
    this.description = data.description || "";
    this.image = getImageFromSrc(data.img);
    this.cover = getImageFromSrc(data.cover);
    this.isActive = Boolean(data.is_active);
    this.isBestPrice = Boolean(data.is_best_price);

    this.salonId = Number(raw.specialist?.salon_id || 0);

    this.reviewsCount = Number(raw.review_count || 0);
    this.rating = Number(raw.rating || 0);
    this.ratingTitle = getRatingTitle(this.rating);
  }
}
