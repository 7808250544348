import ymaps from "ymaps";

let maps;

export class YandexMap {
  constructor() {
    this.map = null;
    this.objectManager = null;
    this.lastMarkId = 0;
  }

  /**
   *
   * @returns {Promise<YandexMap>}
   */
  static async create({ elementId, center = [55.76, 37.64], zoom = 13 }) {
    if (!maps) {
      maps = await ymaps.load(
        `https://api-maps.yandex.ru/2.1/?apikey=${import.meta.env.VITE_YANDEX_MAPS_API_KEY}&lang=ru_RU`
      );
    }

    const yandexMap = new YandexMap();
    yandexMap.map = new maps.Map(elementId, {
      center,
      zoom,
    });
    const objectManager = new maps.ObjectManager({
      clusterize: true,
    });
    yandexMap.objectManager = objectManager;
    yandexMap.map.geoObjects.add(objectManager);

    return yandexMap;
  }

  addMarks(data = []) {
    const marks = data.map(
      (mark) => this.createMark(mark)
    );
    this.objectManager.add(marks);
    if (marks.length) {
      this.map.geoObjects.add(this.objectManager);
    }
  }

  createMark({
    center,
    title,
    text,
    image,
    layout = null,
    width = 24,
    height = 24,
  }) {
    return {
      type: "FeatureCollection",
      id: ++this.lastMarkId,
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: center,
      },
      properties: {
        iconContent: text,
        hintContent: title,
        balloonContent: layout,
      },
      options: {
        preset: "islands#darkBlueStretchyIcon",
      },
    };
  }

  createPlacemark({
    center,
    title,
    text,
    image,
    layout = null,
    width = 24,
    height = 24,
  }) {
    let balloonContentLayout;
    if (layout) {
      balloonContentLayout = maps.templateLayoutFactory.createClass(layout);
    }

    const iconOpts = text
      ? { preset: "islands#darkBlueStretchyIcon" }
      : {
          iconLayout: "default#image",
          iconImageHref: image,
          iconImageSize: [width, height],
          iconImageOffset: [-16, -16],
        };

    const placemark = new maps.Placemark(
      center,
      {
        hintContent: title,
        name: title,
        iconContent: text,
      },
      {
        ...iconOpts,
        hideIconOnBalloonOpen: false,
        iconContent: "11",
        balloonContentLayout,
      }
    );

    return placemark;
  }

  addMark(mark) {
    const placemark = this.createMark(mark);

    this.objectManager.add(placemark);
    this.map.geoObjects.add(this.objectManager);
  }

  addRoute({ from, to }) {
    const multiRoute = new maps.multiRouter.MultiRoute(
      {
        referencePoints: [from, to],
        params: { results: 1 },
      },
      { boundsAutoApply: true }
    );

    this.map.geoObjects.add(multiRoute);
  }

  getRadiusKm(p1, p2) {
    const distance = maps.coordSystem.geo.getDistance(p1, p2);
    return distance / 2 / 1000 / Math.sqrt(2);
  }

  removeAllMarks() {
    this.objectManager.removeAll();
  }

  setCenter(coords) {
    this.map.setCenter(coords);
    this.map.setZoom(13);
  }
}
