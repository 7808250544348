<template>
  <div class="registration__group">
    <label for="telephone" class="auth__label">{{
      $t("auth.phone")
    }}</label>
    <div class="send__message--field">
      <div class="reg__field--block" :class="{ error: v$.telephone.$error }">
        <input
          type="tel"
          id="telephone"
          v-model="form.telephone"
          name="telephone"
          @blur="setTelephone"
          @input="setTelephone"
          class="reg__field"
          autocomplete="off" />
        <span class="choose__region">
          <button
            type="button"
            @click="clickOpenPopup()"
            class="active__region">
            <span class="region">
              <country-flag
                :country="activeRegion.region"
                rounded
                size="normal" />
            </span>
          </button>
        </span>
      </div>
      <div v-if="v$.telephone.$error" class="errors">
        <p class="error__text" v-if="!v$.telephone.required">
          {{ $t("auth.requiredToFill") }}
        </p>
        <p class="error__text" v-else-if="!v$.telephone.numeric">
          {{ $t("auth.phoneWrong") }}
        </p>
        <p class="error__text" v-else>
          {{ $t("auth.phoneWrong") }}
        </p>
      </div>
      <AuthPopupRegion
        @change-country="onChangeCountry"
        :activePopup="activePopup" />

      <div
        class="message__block"
        :class="{ 'message__block--active': showCodeWindow }">
        <div class="message__block-content">
          <div class="message__code--block">
            <h3 class="message__code--title">{{ $t("auth.acceptPhone") }}</h3>
            <div
              class="message__code--input"
              :class="{ error: v$.pinCode.$error }">
              <PincodeInput
                v-model="form.pinCode"
                placeholder="0"
                :digits="6"
                @input="setTelephonePin()" />
            </div>
            <div v-if="v$.pinCode.$error" class="errors">
              <p class="error__text" v-if="!v$.pinCode.required">
                {{ $t("auth.requiredToFill") }}
              </p>
              <p class="error__text" v-if="!v$.pinCode.numeric">
                {{ $t("auth.onlyNumber") }}
              </p>
              <p class="error__text" v-if="!v$.pinCode.minLength">
                {{ $t("auth.pinLength") }}
              </p>
            </div>
          </div>

          <div class="message__send--block">
            <h5 class="message__send--title">
              <template v-if="!isFirstSend">Не пришло оповещение?</template>
            </h5>
            <button
              type="button"
              @click="sendRepeat"
              :disabled="rep_send.isRunning === true"
              class="message__send--btn">
              {{ isFirstSend ? $t("auth.sendCode") : $t("auth.sendCodeAgain") }}
            </button>
            <div class="message__send--timer">{{ rep_send.displayTime }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import CountryFlag from "vue-country-flag-next";
import AuthPopupRegion from "@/components/Auth/AuthPopupRegion/AuthPopupRegion";
import PincodeInput from "vue3-pincode-input";

import { computed, ref, reactive } from "vue";
import { required, numeric, minLength } from "@vuelidate/validators";
const sendConfirmTime = 60 * 1;
const authStore = useAuth();
const { $eventHub } = useNuxtApp();

const emit = defineEmits(["setTelephonePin", "setTelephone"]);

const isFirstSend = ref(true);
const showCodeWindow = ref(false);
const activePopup = ref(false);
const rep_send = reactive({
  isRunning: false,
  minute: 1,
  second: 0,
  time: 0,
  timer: null,
  displayTime: prettyTime(sendConfirmTime),
});
const form = reactive({
  telephone: "+7",
  pinCode: "",
});
const rules = computed(() => ({
  telephone: {
    isTelephone: (value) => /^\+?\d{11,20}$/.test(value),
  },
  pinCode: {
    required,
    numeric,
    minLength: minLength(6),
  },
}));
import { useVuelidate } from "@vuelidate/core";
const v$ = useVuelidate(rules, form);

const popupStore = usePopup();
const activeRegion = computed(() => popupStore.activeRegion);
const regions = computed(() => popupStore.regions);

const hasStringNumber = computed(() => /^\+?([0-9]*)\d*$/.test(form.telephone));


function telephoneCheck() {
  v$.value.$touch();
}
function prettyTime(timeParam) {
  let time = timeParam / 60;
  let minutes = parseInt(time);
  let secondes = Math.round((time - minutes) * 60);
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (secondes < 10) {
    secondes = `0${secondes}`;
  }
  return minutes + ":" + secondes;
}
function onChangeCountry(country) {
  form.telephone = country.number;
  activePopup.value = false;
}
function clickOpenPopup() {
  activePopup.value = true;
}
function startTimer(duration) {
  rep_send.isRunning = true;
  let durationTemp = duration;
  rep_send.displayTime = prettyTime(durationTemp);
  const timerId = setInterval(() => {
    if (durationTemp > 0) {
      durationTemp--;
    } else {
      clearInterval(timerId);
      rep_send.isRunning = false;
    }
    rep_send.displayTime = prettyTime(durationTemp);
  }, 1000);
}
function setTelephonePin() {
  emit("setTelephonePin", {
    val: form.pinCode,
    hasError: v$.value.pinCode.$error,
  });
}
function setTelephone() {
  emit("setTelephone", {
    val: form.telephone.replace("+", ""),
    hasError: v$.value.telephone.$error,
  });
  setTelephonePin();
  v$.value.$touch();
  showCodeWindow.value = false;
  if (!v$.value.telephone.$error && form.telephone.length) {
    showCodeWindow.value = true;
  }
}
function sendCode() {
  if (!v$.value.telephone.$error) {
    authStore.sendConfirmCode({
      phone: form.telephone,
    });
  }
}
const registrationClinicModule = useRegistrationClinicModule();
function sendRepeat() {
  if (rep_send.isRunning === false) {
    registrationClinicModule.sendTelephone(form.telephone);
    startTimer(sendConfirmTime);
    sendCode();
    form.pinCode = "";
  }
  isFirstSend.value = false;
}

useAsyncData(() => {
  $eventHub.$on("telephoneCheck", telephoneCheck);
});
</script>

<style lang="scss" scoped>
.error_confirm {
  border: 1px solid #ff3f3f;
}
.send__message--field {
  width: 100%;
  position: relative;
}
</style>
