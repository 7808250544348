<template>
  <!-- Modal with map -->
  <b-modal
    id="map-order-modal"
    size="xl"
    hide-header
    hide-footer
    @show="onShow"
    @hide="onHide"
  >
    <div class="modal">
      <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content h-100 w-100">
          <button
            type="button"
            class="btn-close modal-btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="$bvModal.hide('map-order-modal')"
          ></button>

          <div class="modal-map-order__body h-100">
            <div class="map-modal__header">
              <div class="map-modal__header-inner position-relative">
                <button
                  type="button"
                  class="btn-close arrow-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  @click="$bvModal.hide('map-order-modal')"
                ></button>
                Карта

                <span
                  class="close-icon"
                  @click="$bvModal.hide('map-order-modal')"
                ></span>
              </div>
            </div>

            <div class="map-modal__blocks h-100">
              <div id="modal-map-order" class="map-modal__block map">
                <button class="map-modal__location" @click="setLocation">
                  <i class="fas fa-location-arrow"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import BModal from "@/components/Modals/BModal/BModal.vue";
import locationImg from "@/assets/img/location.png";

import { YandexMap } from "@/services/yandex-map";

export default {
  name: "MapModal",

  components: {
    BModal,
  },

  data: () => ({
    isFilterOpened: false,
    isShown: false,
    drawerTop: 0,
    savedDrawerTop: 0,
    touchStartY: 0,
    deltaTouch: 0,
    screenHeight: 0,
    map: null,
    isLoading: false,
    center: [0, 0],
    radius: 0,
    userMark: null,
    route: null,
  }),

  computed: {
    isMobile() {
      return this.screenHeight < 768;
    },

    minDrawerTop() {
      return this.screenHeight - 180;
    },

    maxDrawerTop() {
      return this.screenHeight * 0.1;
    },

    toCoordinates() {
      const orderModule = useOrder();
      return orderModule.processedOrder.salon?.coordinates || null;
    },
  },

  watch: {
    toCoordinates: {
      deep: true,
      immediate: true,
      handler() {
        if (!this.isShown) return;

        this.isLoading = true;

        this.createRoute();
      },
    },
  },

  created() {
    this.savedDrawerTop = this.minDrawerTop;
    this.drawerTop = this.minDrawerTop;
  },

  mounted() {
    this.screenHeight = window.innerHeight;
  },

  methods: {
    async setLocation() {
      return new Promise((resolve) => {
        navigator.geolocation.getCurrentPosition((val) => {
          const coords = [val.coords.latitude, val.coords.longitude];
          this.map.setCenter(coords);
          this.userMark = {
            center: coords,
            title: "",
            image: locationImg,
            height: 32,
          };

          this.map.addMark(this.userMark);
          return resolve(true);
        });
      });
    },

    focusToMark(coords) {
      this.map.setCenter(coords);
    },

    onTouchStart(e) {
      const touch = e.touches[0];
      if (!touch) return;

      this.touchStartY = touch.clientY;
      this.savedDrawerTop = this.drawerTop;
    },

    onTouchMove(e) {
      const touch = e.touches[0];
      if (!touch) return;

      const deltaTouch = touch.clientY - this.touchStartY;
      this.drawerTop = this.savedDrawerTop + deltaTouch;
    },

    onTouchEnd() {
      this.touchStartY = 0;
      this.drawerTop =
        this.drawerTop > this.screenHeight * 0.5
          ? this.minDrawerTop
          : this.maxDrawerTop;
    },

    async onShow() {
      const accountModule = useAccount();
      if (this.isMobile) {
        document.documentElement.style.overflow = "hidden";
        document.body.style.overflow = "hidden";
      }

      this.isShown = true;
      this.center = [
        Number(accountModule.latitude) || 0,
        Number(accountModule.longitude) || 0,
      ];
      this.radius = 12;

      await this.$nextTick();
      this.map = await YandexMap.create({
        elementId: "modal-map-order",
        center: this.center,
        zoom: 11,
      });

      await this.setLocation();
      this.createRoute();

      this.map.map.events.add("boundschange", (e) => {
        if (this.route) return;

        this.center = e.get("newCenter");
        this.radius = this.map.getRadiusKm(...e.get("newBounds"));

        this.isLoading = true;
      });
    },

    async createRoute() {
      if (!this.toCoordinates || !this.userMark) return;

      this.route = this.toCoordinates;
      this.map.addRoute({
        from: this.userMark.center,
        to: this.toCoordinates,
      });
      await this.$nextTick();
      this.isLoading = false;
    },

    onHide() {
      this.isShown = false;
      this.map.map.destroy();
      this.route = null;

      if (this.isMobile) {
        document.documentElement.style.overflow = "";
        document.body.style.overflow = "";
      }
    },
  },
};
</script>

<style lang="scss">
#map-order-modal {
  .modal-body {
    padding: 0;
  }

  .modal {
    display: initial;
  }

  .modal-dialog {
    width: 100%;
    min-width: calc(100vw - 80px);
    min-height: calc(100vh - 80px);
    height: 100%;
    margin: 0 auto;
  }

  .search-filter__title {
    display: none;
  }

  .map-modal__block.filter .search-filter__block.rating .custom-input__label {
    display: flex !important;
  }

  .map-salon {
    &__name {
      color: inherit;
      text-decoration: none;
    }
    &__items {
      @media (max-width: 767px) {
        padding-bottom: 20px;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@import "map-modal";

.map-modal {
  &__location {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    z-index: 1000;
    background-color: $blue;
    color: $white;
    border-radius: 50%;
    border: none;
    outline: none;
    width: 32px;
    height: 32px;

    @media (max-width: 575px) {
      top: unset;
      bottom: 200px;
    }
  }
}
.modal-content {
  max-height: 80vh;
  max-width: 800px;
  margin: auto;
}
</style>

<style lang="scss">
#modal-map-order {
  .ymaps-2-1-79-controls-pane {
    display: block !important;
  }

  .ymaps-2-1-79-controls__toolbar,
  .ymaps-2-1-79-controls__bottom {
    // display: none;
  }
}
</style>
