<template>
<svg width="126" height="17" viewBox="0 0 126 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_3121_93976)">
<path d="M24.3008 15.7723H26.1917H38.2014V14.435H26.2428V10.126H38.2014V8.78877H26.2428V5.17321H38.2014V3.83594H24.3008V15.7723Z" fill="#2A284D"/>
<path d="M77.2461 15.7723H79.137H91.1467V14.435H79.1881V10.126H91.1467V8.78877H79.1881V5.17321H91.1467V3.83594H77.2461V15.7723Z" fill="#2A284D"/>
<path d="M16.9406 13.8407L12.5966 3.83594H10.5524H10.5013V3.88547L6.31069 13.8902L2.78444 3.83594H0.740234L4.93085 15.7723H7.02616L11.2679 5.66849L15.6629 15.7723H17.7071H17.7582V15.7227L21.8978 3.935L21.9489 3.83594H20.4668L16.9406 13.8407Z" fill="#2A284D"/>
<path d="M54.4543 8.93666C55.0165 8.39185 55.3231 7.64892 55.3231 6.85647C55.3231 5.22203 53.9433 3.88477 52.2568 3.88477H41.3203V15.8211H43.2112H52.5634C54.6587 15.8211 56.3452 14.1867 56.3452 12.156C56.3963 10.7692 55.6808 9.58053 54.4543 8.93666ZM52.7167 9.77864C53.8922 9.97675 54.7609 10.9673 54.7609 12.1065C54.7609 13.2456 53.8922 14.2362 52.7167 14.4343H52.6656H52.6145H43.3134V9.77864H52.6145C52.6145 9.77864 52.6656 9.77864 52.7167 9.77864ZM43.3134 8.44137V5.17251H52.0013C52.9212 5.17251 53.6877 5.91543 53.6877 6.80694C53.6877 7.69845 52.9212 8.44137 52.0013 8.44137H43.3134Z" fill="#2A284D"/>
<path d="M72.3899 8.93666C72.952 8.39185 73.2587 7.64892 73.2587 6.85647C73.2587 5.22203 71.8788 3.88477 70.1924 3.88477H59.2559V15.8211H61.1468H70.499C72.5943 15.8211 74.2808 14.1867 74.2808 12.156C74.3319 10.7692 73.6164 9.58053 72.3899 8.93666ZM61.249 9.77864H70.5501H70.6012H70.6523C71.8277 9.97675 72.6965 10.9673 72.6965 12.1065C72.6965 13.2456 71.8277 14.2362 70.6523 14.4343H70.6012H70.5501H61.249V9.77864ZM69.9368 8.44137H61.249V5.17251H69.9368C70.8567 5.17251 71.6233 5.91543 71.6233 6.80694C71.6233 7.69845 70.9078 8.44137 69.9368 8.44137Z" fill="#2A284D"/>
<path d="M104.588 11.1661C106.684 11.1661 108.37 9.5317 108.37 7.50104C108.37 5.47038 106.684 3.83594 104.588 3.83594H94.0605V15.7723H96.0025V11.1661H99.631L106.07 15.7723H109.341L102.902 11.1661H104.588ZM106.479 7.79821C106.326 8.93736 105.253 9.82887 104.026 9.82887H96.0536V5.17321H104.179C104.844 5.17321 105.508 5.47038 105.968 5.96566C106.326 6.51047 106.53 7.15434 106.479 7.79821Z" fill="#2A284D"/>
<path d="M118.538 15.9691H118.027C114.092 15.9691 110.924 12.8984 110.924 9.08469V3.78516H112.764V9.08469C112.764 11.9078 115.114 14.6814 118.027 14.6814H118.538C121.451 14.6814 123.802 11.9078 123.802 9.08469V3.78516H125.642V9.08469C125.642 12.8984 122.422 15.9691 118.538 15.9691Z" fill="#2A284D"/>
</g>
<defs>
<clipPath id="clip0_3121_93976">
<rect width="125.208" height="15.8491" fill="white" transform="translate(0.792969 0.169922)"/>
</clipPath>
</defs>
</svg>
</template>
