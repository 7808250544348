import { i18n } from "@/plugins/i18n.js";

export const COUNTRY_FLAGS = [
  {
    region: "ru",
    name: i18n.global.t("countries.russia"),
    number: "+7",
  },
  {
    region: "us",
    name: i18n.global.t("countries.usa"),
    number: "+1",
  },
  {
    region: "de",
    name: i18n.global.t("countries.germany"),
    number: "+49",
  },
  {
    region: "ua",
    name: i18n.global.t("countries.ukraine"),
    number: "+380",
  },
  {
    region: "mx",
    name: i18n.global.t("countries.mexico"),
    number: "+52",
  },
  {
    region: "br",
    name: i18n.global.t("countries.brazil"),
    number: "+55",
  },
  {
    region: "in",
    name: i18n.global.t("countries.india"),
    number: "+91",
  },
  {
    region: "cn",
    name: i18n.global.t("countries.china"),
    number: "+86",
  },
  {
    region: "gb",
    name: i18n.global.t("countries.grateBritan"),
    number: "+44",
  },
  {
    region: "kz",
    name: i18n.global.t("countries.kazahstan"),
    number: "+7",
  },
  {
    region: "by",
    name: i18n.global.t("countries.belarus"),
    number: "+375",
  },
  {
    region: "fr",
    name: i18n.global.t("countries.france"),
    number: "+33",
  },
  {
    region: "pl",
    name: i18n.global.t("countries.poland"),
    number: "+48",
  },
  {
    region: "tr",
    name: i18n.global.t("countries.turkey"),
    number: "+90",
  },
  {
    region: "au",
    name: i18n.global.t("countries.australia"),
    number: "+61",
  },
  {
    region: "at",
    name: i18n.global.t("countries.austria"),
    number: "+43",
  },
  {
    region: "az",
    name: i18n.global.t("countries.azeibardzan"),
    number: "+994",
  },
  {
    region: "al",
    name: i18n.global.t("countries.albania"),
    number: "+355",
  },
  {
    region: "vg",
    name: i18n.global.t("countries.usaIsland"),
    number: "+1",
  },
  {
    region: "ai",
    name: i18n.global.t("countries.angilia"),
    number: "+1",
  },
  {
    region: "ao",
    name: i18n.global.t("countries.angola"),
    number: "+244",
  },
  {
    region: "ad",
    name: i18n.global.t("countries.andora"),
    number: "+376",
  },
  {
    region: "ag",
    name: i18n.global.t("countries.antigua"),
    number: "+1",
  },
  {
    region: "ae",
    name: i18n.global.t("countries.aoe"),
    number: "+971",
  },
  {
    region: "ar",
    name: i18n.global.t("countries.argentina"),
    number: "+54",
  },
  {
    region: "am",
    name: i18n.global.t("countries.armenia"),
    number: "+374",
  },
  {
    region: "aw",
    name: i18n.global.t("countries.aruba"),
    number: "+297",
  },
  {
    region: "af",
    name: i18n.global.t("countries.afganistan"),
    number: "+93",
  },
  {
    region: "bs",
    name: i18n.global.t("countries.bagams"),
    number: "+1",
  },
  {
    region: "bd",
    name: i18n.global.t("countries.bangladesh"),
    number: "+880",
  },
  {
    region: "bb",
    name: i18n.global.t("countries.barbados"),
    number: "+1",
  },
  {
    region: "bh",
    name: i18n.global.t("countries.bahrein"),
    number: "+973",
  },
  {
    region: "bz",
    name: i18n.global.t("countries.beliz"),
    number: "+501",
  },
  {
    region: "bel",
    name: i18n.global.t("countries.belgia"),
    number: "+32",
  },
  {
    region: "bg",
    name: i18n.global.t("countries.benin"),
    number: "+229",
  },
  {
    region: "bm",
    name: i18n.global.t("countries.bermuds"),
    number: "+1",
  },
  {
    region: "bg",
    name: i18n.global.t("countries.bolgaria"),
    number: "+359",
  },
  {
    region: "bo",
    name: i18n.global.t("countries.bolivia"),
    number: "+591",
  },
  {
    region: "ba",
    name: i18n.global.t("countries.bosnia"),
    number: "+387",
  },
  {
    region: "bw",
    name: i18n.global.t("countries.botsvana"),
    number: "+267",
  },
  {
    region: "vg",
    name: i18n.global.t("countries.britishIsland"),
    number: "+1",
  },
  {
    region: "bn",
    name: i18n.global.t("countries.brunei"),
    number: "+673",
  },
  {
    region: "bf",
    name: i18n.global.t("countries.burkinaFaso"),
    number: "+226",
  },
  {
    region: "bi",
    name: i18n.global.t("countries.burundi"),
    number: "+257",
  },
  {
    region: "bt",
    name: i18n.global.t("countries.butan"),
    number: "+275",
  },
  {
    region: "vu",
    name: i18n.global.t("countries.vanuatu"),
    number: "+678",
  },
  {
    region: "gb",
    name: i18n.global.t("countries.british"),
    number: "+44",
  },
  {
    region: "hu",
    name: i18n.global.t("countries.vengria"),
    number: "+36",
  },
  {
    region: "ve",
    name: i18n.global.t("countries.venesuela"),
    number: "+58",
  },
  {
    region: "tl",
    name: i18n.global.t("countries.timor"),
    number: "+670",
  },
  {
    region: "vn",
    name: i18n.global.t("countries.vietnam"),
    number: "+84",
  },
  {
    region: "ga",
    name: i18n.global.t("countries.gabon"),
    number: "+241",
  },
  {
    region: "ht",
    name: i18n.global.t("countries.gaiti"),
    number: "+509",
  },
  {
    region: "gm",
    name: i18n.global.t("countries.gambia"),
    number: "+220",
  },
  {
    region: "gh",
    name: i18n.global.t("countries.gana"),
    number: "+233",
  },
  {
    region: "gp",
    name: i18n.global.t("countries.gvadelupa"),
    number: "+590",
  },
  {
    region: "gt",
    name: i18n.global.t("countries.gvatemala"),
    number: "+502",
  },
  {
    region: "gn",
    name: i18n.global.t("countries.gvinea"),
    number: "+224",
  },
  {
    region: "gw",
    name: i18n.global.t("countries.gvineaBissau"),
    number: "+245",
  },
  {
    region: "gi",
    name: i18n.global.t("countries.gibraltar"),
    number: "+350",
  },
  {
    region: "gd",
    name: i18n.global.t("countries.grenada"),
    number: "+1",
  },
  {
    region: "gl",
    name: i18n.global.t("countries.grenlandia"),
    number: "+299",
  },
  {
    region: "gr",
    name: i18n.global.t("countries.greece"),
    number: "+30",
  },
  {
    region: "ge",
    name: i18n.global.t("countries.gruzia"),
    number: "+995",
  },
  {
    region: "gu",
    name: i18n.global.t("countries.guana"),
    number: "+592",
  },
  {
    region: "dk",
    name: i18n.global.t("countries.dania"),
    number: "+45",
  },
  // {
  //   region: "",
  //   name: i18n.global.t("countries."),
  //   number: "+",
  // },
  // {
  //   region: "",
  //   name: i18n.global.t("countries."),
  //   number: "+",
  // },
  // {
  //   region: "",
  //   name: i18n.global.t("countries."),
  //   number: "+",
  // },
];
