<template>
  <div class="order-modal__step2">
    <div class="order-modal__step2-header">
      <div class="order-modal__step2-back" @click="$emit('prev-click')"></div>
      <div class="order-modal__step2-title">Оформление заказа</div>
    </div>

    <div class="order-modal__step2-top">
      <div class="order-modal__salon-banner">
        <img v-if="salon.images[0]" :src="salon.images[0]" />
        <img v-else src="@/assets/img/no-image.png" />
        <div class="order-modal__salon-name d-flex align-items-center">
          <div class="logo">
            <img :src="salon.logo" alt="" />
          </div>
          {{ salon.name }}
        </div>
      </div>

      <div class="order-modal__order-info">
        <div class="row">
          <div class="col-xl-6 col-lg-6 col-md-12">
            <div class="order-modal__info-block specialist">
              <div class="order-modal__info-photo">
                <img :src="selectedSpecialist.avatarSrc" alt="" />
              </div>

              <div class="order-modal__info-content">
                <div class="order-modal__info-title">Специалист</div>

                <div class="order-modal__info-text">
                  {{ selectedSpecialist.name }}
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-6 col-lg-6 col-md-12">
            <div class="order-modal__info-block time">
              <div class="order-modal__info-icon">
                <img src="@/assets/img/icon/calendar-black.svg" alt="" />
              </div>

              <div class="order-modal__info-content">
                <div class="order-modal__info-title">
                  Выбранная дата и время
                </div>

                <div class="order-modal__info-text">
                  <span class="date">{{ formattedDate }}</span>
                  <span class="time">{{ formattedTime }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-12">
            <div class="order-modal__info-block address">
              <div class="order-modal__info-icon">
                <img src="@/assets/img/icon/carbon_location.svg" alt="" />
              </div>
              <div class="order-modal__info-content">
                <div class="order-modal__info-title">Адрес</div>
                <div class="order-modal__info-text">
                  {{ salon.address }}
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-12">
            <div class="order-modal__info-block service">
              <div class="order-modal__info-icon">
                <img src="@/assets/img/icon/brush.svg" alt="" />
              </div>
              <div class="order-modal__info-content">
                <div class="order-modal__info-title">Выбранная услуга:</div>
                <div class="order-modal__info-text">
                  {{ order.serviceTitle }}
                  ({{ selectedService.duration }} мин)
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <template v-if="salon.isConfirmed">
        <PriceSelect />

        <div class="order-modal__bonus" v-if="false">
          <div class="order-modal__bonus-title">Бонусы</div>
          <div class="order-modal__bonus-block">
            <div class="order-modal__bonus-actions">
              <label class="order-modal__bonus-label">
                <input
                  v-model="bonuses"
                  type="radio"
                  value="hold"
                  name="bonus_action"
                  checked
                />
                <span class="order-modal__bonus-text">Накопить</span>
              </label>

              <label class="order-modal__bonus-label">
                <input
                  v-model="bonuses"
                  type="radio"
                  value="spend"
                  name="bonus_action"
                />
                <span class="order-modal__bonus-text">Потратить</span>
              </label>
            </div>

            <div class="order-modal__bonus-summa">
              {{ formatMoney(displayedBalance) }}
            </div>
          </div>
        </div>

        <div class="order-modal__pay">
          <div class="order-modal__pay-title">Выберите способ оплаты</div>
          <PaymentTypeSelect :book="isBookButtonShown" />
        </div>

        <div class="order-modal__total">
          <div class="order-modal__total-title">Итого</div>
          <div class="order-modal__summa">
            <div class="order-modal__total-summa">
              {{ formatMoney(order.price - balance) }}
            </div>

            <div v-if="false" class="order-modal__summa-cashback">
              Кэшбек {{ cashback }}%
              <button
                type="button"
                class="order-modal__tooltip"
                role="tooltip"
                data-microtip-position="top-left"
                data-microtip-size="large"
                aria-label="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Arcu purus felis, non commodo, quis placerat tortor."
              >
                ?
              </button>
            </div>
          </div>
        </div>

        <div class="block-agree">
          <div class="block-agree__inner">
            <label class="block-agree__label checkbox-label">
              <input
                v-model="isTermsAccepted"
                type="checkbox"
                class="block-agree__input checkbox-input"
              />
              <span class="block-agree__text checkbox-text">
                Я принимаю и соглашаюсь со всеми условиями, которые содержит
                <a href="#">условия оплаты</a> и
                <a href="#">условия отмены</a>
              </span>
            </label>
          </div>
        </div>
        <div class="order-modal__footer">
          <button
            type="submit"
            class="order-modal__btn btn btn-black"
            :disabled="!isTermsAccepted || isProcessing"
            @click="linkCard"
            v-if="isLinkCardButtonShown"
          >
            Привязать карту
          </button>
          <button
            type="submit"
            class="order-modal__btn btn btn-black"
            :disabled="!isTermsAccepted || isProcessing"
            @click="bookOrder"
            v-else-if="isBookButtonShown"
          >
            <template v-if="isProcessing"> Создание заказа… </template>

            <template v-else>Забронировать</template>
          </button>
          <button
            type="submit"
            class="order-modal__btn btn btn-black"
            :disabled="!isTermsAccepted || isProcessing"
            @click="payOrder"
            v-else
          >
            <template v-if="isProcessing"> Создание заказа… </template>

            <template v-else>Перейти к оплате</template>
          </button>
        </div>
      </template>

      <p v-else class="order-modal__not-confirmed">
        Компания не подтверждена, оплата невозможна
      </p>
    </div>
  </div>
</template>

<script setup>
import { formatMoney } from "@/helpers/formatters";
</script>
<script>
import PriceSelect from "./PriceSelect.vue";
import PaymentTypeSelect from "./PaymentTypeSelect.vue";
import get from "lodash/get";

export default {
  name: "ConfirmStep",
  components: {
    PriceSelect,
    PaymentTypeSelect,
  },

  data() {
    return {
      isTermsAccepted: false,
      isProcessing: false,
      bonuses: "hold",
      now: 0,
    };
  },

  computed: {
    accountModule() {
      return useAccount();
    },
    orderModule() {
      return useOrder();
    },
    paymentModule() {
      return usePayment();
    },
    salon() {
      return this.orderModule.salon;
    },

    order() {
      return this.orderModule.order;
    },

    selectedSpecialist() {
      return this.orderModule.selectedSpecialist || {};
    },

    selectedService() {
      return this.orderModule.selectedService;
    },

    formattedDate() {
      const formatter = new Intl.DateTimeFormat(this.$i18n.locale, {
        day: "numeric",
        month: "numeric",
        year: "numeric",
      });

      return formatter.format(this.order.date);
    },

    formattedTime() {
      const formatter = new Intl.DateTimeFormat(this.$i18n.locale, {
        hour: "numeric",
        minute: "numeric",
      });

      return formatter.format(this.order.date);
    },

    balance() {
      return this.bonuses === "spend"
        ? Math.floor(Math.min(this.accountModule.balance, this.order.price * 0.2))
        : 0;
    },

    displayedBalance() {
      return this.bonuses === "spend"
        ? this.balance
        : Math.floor(
            this.order.price * this.accountModule.currentCashbackLevel.cashback
          );
    },

    cashback() {
      return this.accountModule.currentCashbackLevel.cashback * 100;
    },
    isBookButtonShown() {
      const bookTimeMin = import.meta.env.VITE_MIN_BOOK_TIME || 60;
      const timeLeft = new Date(this.order.start_at).getTime() - this.now;
      return timeLeft >= bookTimeMin * 60 * 1000;
    },
    isLinkCardButtonShown() {
      return this.paymentModule.savedCards.length === 0 && this.isBookButtonShown;
    },
  },
  created() {
    this.now = Date.now();
  },
  methods: {
    async payOrder() {
      this.isProcessing = true;
      try {
        await this.orderModule.payOrder(this.balance);
        await this.orderModule.loadOrder(this.order.id);

        this.$router.replace({
          query: {
            ...this.$route.query,
            orderId: this.order.id,
          },
        });
        this.$bvModal.hide("order-modal");
        this.$bvModal.show("checkout-modal");
      } catch (err) {
        const { $doNoty: doNoty } = useNuxtApp();
        const errors = get(err, "response.data.errors", {});
        const error = get(Object.values(errors), "[0][0]");
        doNoty.error(error || err);
      } finally {
        this.isProcessing = false;
      }
    },
    async bookOrder() {
      this.isProcessing = true;
      try {
        await this.orderModule.bookOrder();
        await this.orderModule.loadOrder(this.order.id);

        this.$emit("next-click");
      } catch (err) {
        const { $doNoty: doNoty } = useNuxtApp();
        const errors = get(err, "response.data.errors", {});
        const error = get(Object.values(errors), "[0][0]");
        doNoty.error(error || err);
      } finally {
        this.isProcessing = false;
      }
    },
    async linkCard() {
      this.isProcessing = true;
      try {
        await this.paymentModule.linkCard();
        this.$bvModal.show("checkout-modal");
      } catch (err) {
        const { $doNoty: doNoty } = useNuxtApp();
        const errors = get(err, "response.data.errors", {});
        const error = get(Object.values(errors), "[0][0]");
        doNoty.error(error || err);
      } finally {
        this.isProcessing = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.order-modal {
  &__order-info {
    .row {
      flex-wrap: wrap;
    }
  }

  &__not-confirmed {
    margin-top: 15px;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    color: $red;
  }
}
</style>
