export const useRegistrationClinicModule = defineStore("registrationClinicModule", {
  state: () => ({
    registrationClinicForm: {
      company_name: "",
      inn: "",
      name: "",
      password: "",
      telephone: "",
      email: "",
      recaptchaToken: "",
      work_from_home: "",
    },
    validation: {
      email: -1,
      telephone: -1,
    },
    rep_password: "",
  }),

  getters: {},

  actions: {
    setRecaptchaToken(token) {
      this.registrationClinicForm.recaptchaToken = token;
    },
    setInnToken(inn) {
      this.registrationClinicForm.inn = inn;
    },
    setwork_from_homeToken(work_from_home) {
      this.registrationClinicForm.work_from_home = work_from_home;
    },
    setPassword(password) {
      this.registrationClinicForm.password = password;
    },
    setName(name) {
      this.registrationClinicForm.name = name;
    },
    setCompanyName(company_name) {
      this.registrationClinicForm.company_name = company_name;
    },
    setRepPassword(rep_password) {
      this.rep_password = rep_password;
    },
    setEmail(email) {
      this.registrationClinicForm.email = email;
    },
    setTelephone(telephone) {
      this.registrationClinicForm.telephone = telephone;
    },
    sendEmail(email) {
      this.validation.email = email;
    },
    sendTelephone(telephone) {
      this.validation.telephone = telephone;
    },
  },
});
