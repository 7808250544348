<template>
  <b-modal id="message-modal" hide-header hide-footer>
    <div class="modal" id="modal-sale">
      <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="modal-sale__body">
              <div class="modal-sale__header">
                <div class="modal-sale__title">Поздравляем!</div>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  @click="closeModal"
                ></button>
              </div>

              <div class="modal-sale__content">
                <div class="modal-sale__text">
                  <template v-if="isInitialOffer">
                    Поздравляем! Вам доступна скидка до 35% на услуги* перейдите
                    и получите
                  </template>

                  <template v-else>
                    Поздравляем с регистрацией на Webberu, Вам доступна скидка
                    до 35% на услуги*
                  </template>
                </div>

                <div class="modal-sale__hint">
                  <template v-if="isInitialOffer">
                    * в рамках платной ежемесячной подписки Решай Сам
                  </template>

                  <template v-else>
                    * в рамках платной ежемесячной подписки Решай Сам
                  </template>
                </div>

                <router-link :to="route">
                  <button
                    type="button"
                    class="btn btn-black modal-sale__btn"
                    @click="closeModal"
                  >
                    <template v-if="isInitialOffer"> Подробнее </template>
                    <template v-else> Перейти </template>
                  </button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import BModal from "@/components/Modals/BModal/BModal.vue";
import jsCookie from "js-cookie";

const INITIAL_OFFER_KEY = "initialOffer";
const CLIENT_OFFER_KEY = "clientOffer";

export default {
  name: "MessageModal",

  components: {
    BModal,
  },

  computed: {
    accountModule() {
      return useAccount();
    },
    userId() {
      return this.accountModule.userId;
    },

    isInitialOffer() {
      return !jsCookie.get(INITIAL_OFFER_KEY) && !this.accountModule.userId;
    },

    isClientOffer() {
      return !jsCookie.get(CLIENT_OFFER_KEY) && this.accountModule.userId;
    },

    route() {
      return this.isInitialOffer
        ? { path: "/signup" }
        : { path: "/search" };
    },
  },

  async mounted() {
    await this.accountModule.loadUserInfo();
    // this.$watch(
    //   () => this.accountModule.userId,
    //   (val) => {
    //     if (val && this.isClientOffer) {
    //       this.$bvModal.show("message-modal");
    //     }
    //   }
    // );

    // if (this.isInitialOffer || this.isClientOffer) {
    //   this.$bvModal.show("message-modal");
    // }
  },

  methods: {
    closeModal() {
      jsCookie.set(
        this.isInitialOffer ? INITIAL_OFFER_KEY : CLIENT_OFFER_KEY,
        "true"
      );
      this.$bvModal.hide("message-modal");
    },
  },
};
</script>

<style lang="scss">
#message-modal {
  .modal-body {
    padding: 0;
  }

  .modal {
    display: initial;
  }
}
</style>

<style lang="scss">
#message-modal {
  .modal-content {
    border: 0;
    background: $white;
    border-radius: 5px;
  }
  .btn-close {
    background: $lightgrey2 url(@/assets/img/icon/banner-filter-remove.svg)
      no-repeat;
    width: 38px;
    height: 38px;
    background-size: 14px;
    background-position: center center;
    opacity: 0.5;
    border-radius: 50%;
    right: 0;
    margin-top: -10px;
    top: 50%;
    transform: translateY(-50%);
    &:hover {
      opacity: 1;
    }
    @media (max-width: 575px) {
      width: 25px;
      height: 25px;
      right: 15px;
      margin: 0;
    }
  }
  .modal-body {
    padding: 0;
  }
}

.modal-sale {
  &__body {
    padding: 40px 45px 30px;
    @media (max-width: 575px) {
      padding: 0;
    }
  }
  &__header {
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 15px;
    border-bottom: 1px solid rgba($color: $black, $alpha: 0.1);
    @media (max-width: 575px) {
      padding: 25px;
      margin-bottom: 10px;
    }
  }
  &__content {
    @media (max-width: 575px) {
      padding: 0 25px 25px;
    }
  }
  &__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
  }
  &__text {
    font-size: 16px;
    line-height: 29px;
    margin-bottom: 10px;
  }
  &__hint {
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 20px;
  }
  &__btn {
    width: 100%;
    border-radius: 63px;
    font-size: 18px;
    font-weight: 600;
    padding: 13px;
  }
}
</style>
