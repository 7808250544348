import { Model } from "@/stores/models/Model";
import { getNumberWithoutPercent } from "@/helpers/percents";
import { formatMoney } from "@/helpers/formatters.js";
import { getImageFromSrc } from "@/helpers/images";

export class Category extends Model {
  constructor(raw = {}) {
    super();
    this.id = Number(raw.id);
    this.title = String(raw.title || "");
    this.isActive = Boolean(raw.is_active);
    this.minPrice = Number(raw.min_price) || 0;
    this.minPriceFormatted = formatMoney(this.minPrice);
    this.minPriceWithDiscount =
      Math.floor(
        getNumberWithoutPercent(raw.min_price, import.meta.env.VITE_DISCOUNT)
      ) || 0;
    this.minPriceWithDiscountFormatted = this.minPriceWithDiscount
      ? formatMoney(this.minPriceWithDiscount)
      : 0;

    const data = raw.data || {};
    this.showInHeader = Boolean(data.showInHeader);
    this.showInSearch = Boolean(data.showInSearch);
    this.sortInHeader = Number(data.sortInHeader) || 0;
    this.sortInSearch = Number(data.sortInSearch) || 0;
    this.image = getImageFromSrc(data.img);
    this.cover = getImageFromSrc(data.cover);
    this.icon = data.icon || "";
  }
}
