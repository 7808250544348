<template>
  <div class="auth__remember">
    <label for="remember_me" class="label__remember">
      <div class="remember__me-checkbox">
        <input
          v-model="remember"
          type="checkbox"
          class="remember_me--real"
          id="remember_me" />
        <span class="remember_me--fake"></span>
      </div>
      {{ $t("auth.rememberMe") }}
    </label>
  </div>
</template>

<script>
import { auth } from "@/helpers/auth";

export default {
  name: "AuthRemember",

  data() {
    return {
      remember: false,
    };
  },

  watch: {
    remember(newValue) {
      auth.setRemember(newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.auth__remember {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.remember__me-checkbox {
  position: relative;
  cursor: pointer;
  margin-right: 10px;
}

.remember_me--fake {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 1px solid rgba(27, 25, 24, 0.5);
  display: flex;
}

.remember_me--real {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  font-size: 0;

  transform: translate(-50%, -50%);

  &:checked + .remember_me--fake {
    background: $black;
    border-color: $black;
  }

  &:checked + .remember_me--fake:before {
    width: 14px;
    height: 14px;
    content: "";
    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url("@/assets/img/auth/check.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: initial;
  }
}

.label__remember {
  cursor: pointer;
  font-weight: 400;
  color: black;
  display: flex;
  font-size: 16px;
}
</style>
