<template>
  <form class="search-filter" @submit.prevent>
    <div class="search-filter__inner">
      <div class="search-filter__close" @click="close">
        <div class="search-filter__close-text">Назад</div>
      </div>

      <h1 class="search-filter__title">Найти объект</h1>
      <div class="search-filter__content">
        <div class="search-filter__blocks">
          <div class="search-filter__block">
            <div class="search-filter__block-title">Выбор услуги</div>

            <div class="search-filter__block-content">
              <div
                v-click-outside="() => {
                  isSearch = false
                }"
                class="search-filter__search">
                <input
                  ref="serviceFilter"
                  v-model="serviceFilter"
                  type="text"
                  name=""
                  class="search-filter__search-input"
                  @keydown.enter="updateServiceFilter"
                  @input="searchServices"
                  @focus="isSearch = true" />

                <button
                  type="button"
                  class="search-filter__search-btn"
                  @click="updateServiceFilter" />

                <i
                  v-if="serviceFilter"
                  class="mx-icon-clear"
                  @click="clearService">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1024 1024"
                    width="1em"
                    height="1em">
                    <path d="M810.005333 274.005333l-237.994667 237.994667 237.994667 237.994667-60.010667 60.010667-237.994667-237.994667-237.994667 237.994667-60.010667-60.010667 237.994667-237.994667-237.994667-237.994667 60.010667-60.010667 237.994667 237.994667 237.994667-237.994667z" />
                  </svg>
                </i>

                <div
                  v-if="isSearch && serviceFilter"
                  class="search-filter__search-dropdown custom-scroll">
                  <SemipolarSpinner
                    v-if="isLoading"
                    class="search-filter__search-loading"
                    :animation-duration="2000"
                    :size="30" />
                  <template v-else-if="filteredServices.length">
                    <div
                      class="search-filter__search-dropdown-item"
                      v-for="service in filteredServices"
                      :key="service.id"
                      @click="selectService(service)">
                      <img
                        v-if="service.categoryIcon"
                        class="search-filter__search-dropdown-item-icon"
                        :src="service.categoryIcon"
                      />
                      <p>{{ service.title }}</p>
                    </div>
                  </template>
                  <div
                    v-else
                    class="search-filter__search-dropdown-item search-filter__search-dropdown-item--empty">
                    Ничего не найдено
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="search-filter__block">
            <div class="search-filter__block-title">Выбор даты и времени</div>
            <div class="search-filter__block-content">
              <div class="search-filter__data">
                <client-only>
                  <vue-date-picker
                    :teleport="false"
                    :auto-position="false"
                    v-model="filters.date"
                    type="datetime"
                    input-class="search-filter__data-input"
                    :lang="{ formatLocale: { firstDayOfWeek: 1 } }"
                    :show-second="false"
                    placeholder="28.06.2021 18:00"
                    format="dd.MM.yyyy HH:mm"
                    locale="ru"
                    :append-to-body="false"
                    confirm></vue-date-picker>
                </client-only>
              </div>
            </div>
          </div>

          <div class="search-filter__block rating">
            <div class="search-filter__block-title">Рейтинг клиники</div>
            <div class="search-filter__block-content">
              <label
                v-for="i in 5"
                :key="i"
                class="custom-input__label d-inline-flex"
              >
                <input
                  v-model="filters.rating"
                  type="radio"
                  name="clinic-rating"
                  class="custom-input__input"
                  :value="String(10 - i + 1)"
                />
                <span class="custom-input__text"> {{ 10 - i + 1 }} </span>
              </label>
            </div>
          </div>

          <div class="search-filter__block price">
            <div class="search-filter__block-title">Стоимость</div>
            <div class="search-filter__block-content">
              <client-only>
                <Slider
                  style="padding: 10px 13px 20px"
                  :value="filters.price"
                  :min="MIN_PRICE"
                  :max="MAX_PRICE"
                  :merge="7000"
                  :format="formatPriceTooltip"
                  :tooltips="false"
                  :lazy="false"
                  @update="filterPrice = $event" />
              </client-only>
              <div v-if="filterPrice" class="search-filter__price-tooltips">
                <div class="search-filter__price-tooltip">
                  {{ formatMoney(filterPrice[0]) }}
                </div>
                <div class="search-filter__price-tooltip">
                  {{ formatMoney(filterPrice[1]) }}
                </div>
              </div>
            </div>
          </div>

          <div class="search-filter__block">
            <div class="search-filter__block-title">Категория услуги</div>
            <div class="search-filter__block-content">
              <label
                v-for="category in filteredCategories"
                :key="category.id"
                class="custom-input__label"
              >
                <input
                  type="checkbox"
                  name="category-service"
                  :value="String(category.id)"
                  :checked="filters.categoryId.includes(category.id)"
                  @click="toggleArrayValue('categoryId', category.id)"
                  class="custom-input__input"
                />
                <span class="custom-input__text">{{ category.title }}</span>
              </label>
            </div>
          </div>

          <div class="search-filter__block">
            <div class="search-filter__block-title">Зоны тела</div>
            <div class="search-filter__block-content">
              <label
                v-for="part in searchStore.bodyParts"
                :key="part.id"
                class="custom-input__label"
              >
                <input
                  type="checkbox"
                  name="body-zone"
                  :value="String(part.id)"
                  :checked="filters.bodyPartId.includes(part.id)"
                  @click="toggleArrayValue('bodyPartId', part.id)"
                  class="custom-input__input"
                />
                <span class="custom-input__text">{{ part.title }}</span>
              </label>
            </div>
          </div>

          <div class="search-filter__block">
            <div class="search-filter__block-title">Гендер</div>
            <div class="search-filter__block-content">
              <label
                v-for="gender in searchStore.genderOptions"
                :key="gender.id"
                class="custom-input__label"
              >
                <input
                  v-model="filters.gender"
                  type="radio"
                  name="gender"
                  :value="gender.id"
                  class="custom-input__input"
                />
                <span class="custom-input__text">{{ gender.title }}</span>
              </label>
            </div>
          </div>

          <div class="search-filter__block">
            <div class="search-filter__block-title">Отзывы</div>
            <div class="search-filter__block-content">
              <label
                v-for="option in searchStore.reviewOptions"
                :key="option.id"
                class="custom-input__label">
                <input
                  v-model="filters.reviews"
                  type="radio"
                  name="clinic-reviews"
                  class="custom-input__input"
                  :value="option.id" />
                <span class="custom-input__text"> {{ option.title }} </span>
              </label>
            </div>
          </div>

          <div class="search-filter__block">
            <div class="search-filter__block-title">Удобства</div>
            <div class="search-filter__block-content">
              <div class="search-filter__block-line">
                <label class="custom-input__label-checkbox">
                  <input
                    type="checkbox"
                    class="custom-input__input-checkbox"
                    :checked="Boolean(filters.flags.parking)"
                    @change="filters.flags.parking = !filters.flags.parking" />
                  <span class="custom-input__text-checkbox">
                    Наличие парковки
                  </span>
                </label>
              </div>

              <div class="search-filter__block-line">
                <label class="custom-input__label-checkbox">
                  <input
                    type="checkbox"
                    class="custom-input__input-checkbox"
                    :checked="Boolean(filters.flags.kids)"
                    @change="filters.flags.kids = !filters.flags.kids"
                  />
                  <span class="custom-input__text-checkbox">
                    Можно с детьми
                  </span>
                </label>
              </div>
              <div class="search-filter__block-line">
                <label class="custom-input__label-checkbox">
                  <input
                    type="checkbox"
                    class="custom-input__input-checkbox"
                    :checked="Boolean(filters.flags.animals)"
                    @change="filters.flags.animals = !filters.flags.animals"
                  />
                  <span class="custom-input__text-checkbox">
                    Можно с животными
                  </span>
                </label>
              </div>
            </div>
          </div>

          <div class="search-filter__block">
            <div class="search-filter__block-title">
              Здоровье и безопасность
            </div>
            <div class="search-filter__block-content">
              <label class="custom-input__label-checkbox">
                <input
                  type="checkbox"
                  class="custom-input__input-checkbox"
                  :checked="Boolean(filters.flags.health)"
                  @change="filters.flags.health = !filters.flags.health"
                />
                <span class="custom-input__text-checkbox">
                  Обьекты, в которых принимают дополнительные меры по защите
                  здоровья и безопаснсти
                </span>
              </label>
            </div>
          </div>

          <div class="search-filter__block" v-if="false">
            <div class="search-filter__block-title">
              Специальные предложения
            </div>
            <div class="search-filter__block-content">
              <label class="custom-input__label-checkbox">
                <input
                  type="checkbox"
                  class="custom-input__input-checkbox"
                  :checked="Boolean(filters.flags.special)"
                  @change="filters.flags.special = !filters.flags.special"
                />
                <span class="custom-input__text-checkbox">
                  Все предложения
                </span>
              </label>
            </div>
          </div>

          <div class="search-filter__block" v-if="false">
            <div class="search-filter__block-title">Правила бронирования</div>
            <div class="search-filter__block-content">
              <label
                v-for="option in searchStore.bookingOptions"
                :key="option.id"
                class="custom-input__label"
              >
                <input
                  v-model="filters.booking"
                  type="radio"
                  name="rules-booking"
                  class="custom-input__input"
                  :value="option.id"
                />
                <span class="custom-input__text">
                  {{ option.title }}
                </span>
              </label>
            </div>
          </div>

          <div class="search-filter__block">
            <div class="search-filter__block-title">Тип заведения</div>
            <div class="search-filter__block-content">
              <label
                v-for="type in searchStore.salonTypes"
                :key="type.id"
                class="custom-input__label"
              >
                <input
                  type="checkbox"
                  name="category-service"
                  :value="String(type.id)"
                  :checked="filters.salonTypeId.includes(type.id)"
                  @click="toggleArrayValue('salonTypeId', type.id)"
                  class="custom-input__input"
                />
                <span class="custom-input__text">{{ type.title }}</span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script setup>
import { formatMoney } from "@/helpers/formatters";
</script>

<script>
import { SemipolarSpinner } from "epic-spinners";
import Slider from "@vueform/slider";

import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import ClickOutside from "vue-click-outside";
import moment from "moment";

import { formatMoney } from "@/helpers/formatters";
import isEqual from "lodash/isEqual";
import debounce from "lodash/debounce";
import { parseIntegerArrayFromQuery } from "@/helpers/uri-query";

const MIN_PRICE = 0;
const MAX_PRICE = 30000;

moment.locale("ru");

export default {
  name: "SearchFilter",
  components: {
    SemipolarSpinner,
  },

  directives: {
    ClickOutside,
  },

  props: {
    useShortDate: { type: Boolean, default: true },
  },

  data() {
    return {
      serviceFilter: "",
      isLoading: false,
      isSearch: false,
      filteredServices: [],
      searchDebounced: () => {},
      updatePriceDebounced: () => {},
      filterPrice: [],

      filters: {
        service: "",
        date: "",
        rating: "",
        price: [MIN_PRICE, MAX_PRICE],

        categoryId: [],
        bodyPartId: [],
        reviews: "",
        booking: "",
        brand: "",
        salonTypeId: [],

        flags: {
          parking: false,
          kids: false,
          animals: false,
          health: false,
          special: false,
        },
      },
    };
  },

  computed: {
    searchStore() {
      return useSearch();
    },

    filteredCategories() {
      return this.searchStore.serviceCategories
        .filter((item) => item.showInSearch)
        .sort((a, b) => a.sortInSearch - b.sortInSearch);
    },

    MIN_PRICE: () => MIN_PRICE,
    MAX_PRICE: () => MAX_PRICE,
  },

  watch: {
    filters: {
      deep: true,
      handler(val) {
        const newQuery = {
          ...this.$route.query,
          service: val.service || undefined,
          date: val.date ? new Date(val.date).toISOString() : undefined,
          rating: val.rating || undefined,
          priceFrom: String(val.price[0]) || undefined,
          priceTo: String(val.price[1]) || undefined,

          categoryId: val.categoryId?.length
            ? val.categoryId.join(',')
            :  undefined,
          bodyPartId: val.bodyPartId?.length
            ? val.bodyPartId.join(',')
            : undefined,
          gender: val.gender || undefined,
          reviews: val.reviews || undefined,
          booking: val.booking || undefined,
          brand: val.brand || undefined,
          salonTypeId: val.salonTypeId?.length
            ? val.salonTypeId.join(',')
            : undefined,

          parking: Boolean(val.flags.parking) || undefined,
          kids: Boolean(val.flags.kids) || undefined,
          animals: Boolean(val.flags.animals) || undefined,
          health: Boolean(val.flags.health) || undefined,
          special: Boolean(val.flags.special) || undefined,
        };

        Object.keys(newQuery).forEach((item) => {
          if (!newQuery[item]) {
            delete newQuery[item];
          }
        });

        if (isEqual(newQuery, this.$route.query)) return;
        this.$router.replace({
          query: {
            ...newQuery,
          },
          params: {
            savedPosition: { x: 0, y: window.scrollY },
          },
        }).catch((err) => err);
      },
    },

    "filters.price"(value) {
      this.filterPrice = value;
    },

    "$route.query": {
      deep: true,
      handler(val, oldVal) {
        if (isEqual(val, oldVal)) return;

        this.updateFiltersFromQuery();
      },
    },

    filterPrice(value) {
      this.updatePriceDebounced(value);
    },
  },

  created() {
    this.updateFiltersFromQuery();
    this.searchDebounced = debounce(async () => {
      await this.searchStore.getServices(this.serviceFilter);
      this.isLoading = false;
      this.filteredServices = this.searchStore.services;
    }, 500);
    this.updatePriceDebounced = debounce((value) => {
      this.filters.price = value;
    }, 500);
  },

  methods: {
    close() {
      this.$emit("close");
    },

    updateFiltersFromQuery() {
      const query = this.$route.query;
      this.serviceFilter = query.service || "";
      this.filters = {
        service: query.service || "",
        date: query.date ? new Date(query.date) : "",
        rating: query.rating || "",
        price: [query.priceFrom || 0, query.priceTo || 30000],

        categoryId: [],
        bodyPartId: [],
        gender: query.gender || "",
        salonTypeId: [],
        reviews: query.reviews || "",
        booking: query.booking || "",
        brand: query.brand || "",

        flags: {
          parking: query.parking === "true",
          kids: query.kids === "true",
          animals: query.animals === "true",
          health: query.health === "true",
          special: query.special === "true",
        },
      };

      const arrayFilters = ["categoryId", "bodyPartId", "salonTypeId"];

      arrayFilters.forEach((item) => {
        if (typeof query[item] === "string") {
          this.filters[item] = parseIntegerArrayFromQuery(query[item]);
        } else if (query[item]) {
          this.filters[item] = [query[item]];
        }
      });
    },

    searchServices() {
      this.filteredServices = [];
      if (this.serviceFilter.length < 3) return;

      this.isLoading = true;
      this.searchDebounced();
    },

    updateServiceFilter() {
      this.filters.service = this.serviceFilter;
      this.filteredServices = [];
    },

    selectService(service) {
      this.serviceFilter = service.title;
      this.updateServiceFilter();
    },

    clearService() {
      this.serviceFilter = "";
      this.filters.service = "";
    },

    toggleArrayValue(code, id) {
      const index = this.filters[code]
        .findIndex((item) => item === id);
      if (~index) {
        this.filters[code].splice(index, 1);
      } else {
        this.filters[code].push(id);
      }
    },

    formatPriceTooltip(value) {
      return formatMoney(value);
    },

    dateFormatter() {
      const dateFormat = this.useShortDate
        ? "DD.MM.YYYY HH:mm"
        : "ddd D MMMM YYYY HH:mm";
      return {
        stringify: (date) => (
          date ? moment(date).format(dateFormat) : ''
        ),
        parse: (value) => (
          value ? moment(value, dateFormat).toDate() : null
        ),
      };
    },
  },
};
</script>

<style lang="scss">
@import "search-filter";
@import "@vueform/slider/themes/default.css";

$default-color: #555;

:root {
  --slider-handle-width: 24px;
  --slider-handle-height: 24px;
}

.slider-horizontal .slider-handle {
  border: 4px solid $black;
  box-shadow: unset;
}

.slider-base {
  height: 2px;
}

.slider-connect {
  height: 2px;
  background: $black;
}

.slider-tooltip {
  color: $blue;
  border: none;
  background: none;
}

.slider-handle-upper + .slider-tooltip-bottom {
  right: 0 !important;
}

.slider-tooltip-bottom:before,
.mx-icon-calendar {
  display: none;
}

.search-filter__price-tooltips {
  display: flex;
  justify-content: space-between;
}
.search-filter__price-tooltip {
  font-weight: 500;
  font-size: 14px;
  line-height: 108%;
}
</style>
