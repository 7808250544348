import plugin_vue3_A0OWXRrUgq from "/builds/wellfinder/site-frontend/client/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/builds/wellfinder/site-frontend/client/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/builds/wellfinder/site-frontend/client/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/wellfinder/site-frontend/client/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "/builds/wellfinder/site-frontend/client/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_hwlJ5PmHMh from "/builds/wellfinder/site-frontend/client/node_modules/nuxt-lazy-load/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/builds/wellfinder/site-frontend/client/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import auth_client_E5M9zMdRCQ from "/builds/wellfinder/site-frontend/client/plugins/auth.client.js";
import bvModal_client_FkzoktRDQ0 from "/builds/wellfinder/site-frontend/client/plugins/bvModal.client.js";
import doNoty_client_vjaLQ9SdVK from "/builds/wellfinder/site-frontend/client/plugins/doNoty.client.js";
import eventHub_YIW5hN5hb3 from "/builds/wellfinder/site-frontend/client/plugins/eventHub.js";
import i18n_sVHQBgnb3t from "/builds/wellfinder/site-frontend/client/plugins/i18n.js";
import media_DRkVZGwz8r from "/builds/wellfinder/site-frontend/client/plugins/media.js";
import noty_uYvwx4ZVdU from "/builds/wellfinder/site-frontend/client/plugins/noty.js";
import yandex_gAC4Z8gZV4 from "/builds/wellfinder/site-frontend/client/plugins/yandex.js";
export default [
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  plugin_hwlJ5PmHMh,
  chunk_reload_client_UciE0i6zes,
  auth_client_E5M9zMdRCQ,
  bvModal_client_FkzoktRDQ0,
  doNoty_client_vjaLQ9SdVK,
  eventHub_YIW5hN5hb3,
  i18n_sVHQBgnb3t,
  media_DRkVZGwz8r,
  noty_uYvwx4ZVdU,
  yandex_gAC4Z8gZV4
]