<template>
  <b-modal
    id="registration-user-modal"
    hide-footer>
    <template #modal-header>
      <div class="registration-modal__title">
        <span>Регистрация на</span>
        <img src="@/assets/img/logo-black.svg" />
      </div>
      <button
        @click="$bvModal.hide('registration-user-modal')"
        class="registration-modal__close"></button>
    </template>
    <div class="registration-modal">
      <div v-if="isLoading" class="registration-modal__loader">
        <semipolar-spinner
          :animation-duration="2000"
          :size="50"
          style="
            position: absolute;
            left: calc(50% - 25px);
            top: calc(50% - 25px);
          " />
      </div>
      <RegistrationForm @onRegister="onRegister" />
    </div>
  </b-modal>
</template>

<script>
import BModal from "@/components/Modals/BModal/BModal.vue";
import RegistrationForm from "@/components/Registration/Registration_user/RegistrationForm";
import { SemipolarSpinner } from "epic-spinners";

export default {
  components: {
    BModal,
    RegistrationForm,
    SemipolarSpinner,
  },

  data: () => ({
    isLoading: false,
  }),

  methods: {
    async onRegister(params) {
      const authStore = useAuth();
      this.isLoading = true;
      const response = await authStore.registerClient(params);

      if (response) {
        this.$bvModal.hide("registration-user-modal");
        this.$bvModal.show("auth-modal");
      }

      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss">
@import "registration-modal.scss";
</style>
