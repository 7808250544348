import { Model } from "@/stores/models/Model";

export class Company extends Model {
  constructor(raw = {}) {
    super();
    this.id = Number(raw.id || 0);
    this.inn = Number(raw.inn || 0);
    this.isConfirmed = Boolean(raw.is_confirmed);
    this.type = Number(raw.type || 0);
    this.phone = raw.phone || "";
  }
}
