<template>
  <div class="dropdown">
    <button
      class="btn order-modal__pay-btn dropdown-toggle"
      :class="{ show: isOpen, warn: selectedOption.warn }"
      type="button"
      v-if="selectedOption"
      @click="isOpen = !isOpen"
    >
      <span class="order-modal__pay-icon">
        <img :src="selectedOption.icon" alt="credit-card" />
      </span>

      {{ selectedOption.label }}
    </button>

    <ul
      class="dropdown-menu order-modal__pay-menu"
      :class="{ show: isOpen }"
      aria-labelledby="order-modal__paydropdown"
    >
      <div
        v-for="option in paymentTypeOptions"
        :key="option.value"
        class="order-modal__pay-item"
        @click="selectOption(option)"
      >
        <span class="order-modal__pay-icon">
          <img :src="option.icon" alt="credit-card" />
        </span>

        <div class="order-modal__pay-name">{{ option.label }}</div>
      </div>
    </ul>
  </div>
</template>

<script>
import { PAYMENT_TYPES } from "@/stores/order";
import creditCardIcon from "@/assets/img/icon/credit-card.svg";

export default {
  name: "PaymentTypeSelect",

  props: {
    book: Boolean,
  },
  data() {
    return {
      isOpen: false,
    };
  },

  computed: {
    paymentTypeOptions() {
      const savedCards = this.savedCards.map((item) => ({
        value: item.payment_id,
        label:
          item.title || `${item.card_type} ${item.first6} **** ${item.last4}`,
        icon: creditCardIcon,
        primary: item.primary,
      }));
      if (this.book) {
        return [
          !this.savedCards.length && {
            value: PAYMENT_TYPES.save,
            warn: true,
            label: "У вас нет сохраненной карты, пожалуйста привяжите",
            icon: creditCardIcon,
          },
          ...savedCards,
        ].filter(Boolean);
      }
      return [
        {
          value: PAYMENT_TYPES.save,
          label: "С сохранением карты",
          icon: creditCardIcon,
        },
        {
          value: PAYMENT_TYPES.noSave,
          label: "Без сохранения карты",
          icon: creditCardIcon,
        },
        ...savedCards,
      ];
    },

    selectedOption() {
      const orderModule = useOrder();
      return this.paymentTypeOptions.find(
        (item) => item.value === orderModule.order.paymentType
      );
    },

    savedCards() {
      const paymentModule = usePayment();
      return paymentModule.savedCards;
    },
  },

  created() {
    const paymentModule = usePayment();
    paymentModule.loadSavedCards();
  },

  methods: {
    selectOption(option) {
      const orderModule = useOrder();
      orderModule.updateOrder({ paymentType: option.value });
      this.isOpen = false;
    },
  },
  watch: {
    paymentTypeOptions(options) {
      if (this.paymentTypeOptions.length) {
        const primaryCard = options.find((item) => item.primary);
        this.selectOption(primaryCard || options[0]);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#salon-page__order-modal .order-modal {
  &__pay-btn {
    &::after {
      transition: transform 250ms ease;
    }
    &.warn {
      color: $red;
    }
    &.show {
      &::after {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }
}
</style>
