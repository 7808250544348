import { setupFetch, getAxiosResponse } from "@/helpers/fetch";
import Cookie from "js-cookie";

const apiUrl = import.meta.env.VITE_API_PROXY_URL && !process.client && !/^http/.test(import.meta.env.VITE_API_URL)
    ? `${import.meta.env.VITE_API_PROXY_URL}${import.meta.env.VITE_API_URL}`
    : import.meta.env.VITE_API_URL;
const _fetch = setupFetch(`${apiUrl}/payment-back/api/v1`, {
  headers: { "Content-Type": "application/json" },
});

class PaymentsApi {
  getPayments(body) {
    const accessToken = Cookie.get("accessToken");
    return _fetch("/getPayments", {
      method: "POST",
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
      body: JSON.stringify(body),
    }).then((response) => getAxiosResponse(response));
  }

  createPayment(body) {
    const accessToken = Cookie.get("accessToken");
    return _fetch("/createPayment", {
      method: "POST",
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
      body: JSON.stringify(body),
    }).then((response) => getAxiosResponse(response));
  }

  getTransactions(body) {
    const accessToken = Cookie.get("accessToken");
    return _fetch("/getTransactions", {
      method: "POST",
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
      body: JSON.stringify(body),
    }).then((response) => getAxiosResponse(response));
  }

  addTransaction(body) {
    const accessToken = Cookie.get("accessToken");
    return _fetch("/addTransaction", {
      method: "POST",
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
      body: JSON.stringify(body),
    }).then((response) => getAxiosResponse(response));
  }

  getBalance(body) {
    const accessToken = Cookie.get("accessToken");
    return _fetch("/getBalance", {
      method: "POST",
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
      body: JSON.stringify(body),
    }).then((response) => getAxiosResponse(response));
  }

  getAccountStatus(body) {
    const accessToken = Cookie.get("accessToken");
    return _fetch("/getAccountStatus", {
      method: "POST",
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
      body: JSON.stringify(body),
    }).then((response) => getAxiosResponse(response));
  }

  getCashbackSettings() {
    const accessToken = Cookie.get("accessToken");
    return _fetch("/getCashbackSettings", {
      method: "POST",
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
      body: "{}",
    }).then((response) => getAxiosResponse(response));
  }

  getSpentAmount(body) {
    const accessToken = Cookie.get("accessToken");
    return _fetch("/getSpentAmount", {
      method: "POST",
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
      body: JSON.stringify(body),
    }).then((response) => getAxiosResponse(response));
  }

  withdrawBalance(body) {
    const accessToken = Cookie.get("accessToken");
    return _fetch("/withdrawBalance", {
      method: "POST",
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
      body: JSON.stringify(body),
    }).then((response) => getAxiosResponse(response));
  }

  topUpBalance(body) {
    const accessToken = Cookie.get("accessToken");
    return _fetch("/topUpBalance", {
      method: "POST",
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
      body: JSON.stringify(body),
    }).then((response) => getAxiosResponse(response));
  }

  getSavedCards() {
    const accessToken = Cookie.get("accessToken");
    return _fetch("/getSavedCards", {
      method: "POST",
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
      body: JSON.stringify({
        access_token: Cookie.get("accessToken"),
      }),
    }).then((response) => getAxiosResponse(response));
  }

  setPrimaryCard(id) {
    const accessToken = Cookie.get("accessToken");
    return _fetch("/makeSavedCardPrimary", {
      method: "POST",
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
      body: JSON.stringify({
        access_token: Cookie.get("accessToken"),
        payment_id: id,
      }),
    }).then((response) => getAxiosResponse(response));
  }
}

export const paymentsApi = new PaymentsApi();
