<template>
  <b-modal id="payment-success-modal" hide-header hide-footer>
    <div class="modal" id="modal-sale">
      <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="modal-sale__body">
              <div class="modal-sale__header">
                <div class="modal-sale__title">Оплата прошла успешно</div>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  @click="closeModal"
                ></button>
              </div>

              <div class="modal-sale__content">
                <div class="modal-sale__text">
                  Вы успешно оплатили бронирование услуги в салоне
                  {{ salonName }}
                </div>

                <router-link to="/user/orders">
                  <button
                    type="button"
                    class="btn btn-black modal-sale__btn"
                    @click="closeModal"
                  >
                    Перейти к бронированиям
                  </button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import BModal from "@/components/Modals/BModal/BModal.vue";

export default {
  name: "PaymentSuccessModal",

  components: {
    BModal,
  },

  computed: {
    salonName() {
      const salonModule = useSalon();
      return salonModule.salon.name;
    },
  },

  methods: {
    closeModal() {
      this.$router.replace({ hash: "" });
      this.$bvModal.hide("payment-success-modal");
    },
  },
};
</script>

<style lang="scss">
#payment-success-modal {
  .modal-body {
    padding: 0;
  }

  .modal {
    display: initial;
  }
}
</style>

<style lang="scss" scoped>
@import "payment-modal";
</style>
