import { Model } from "@/stores/models/Model";
import { Specialist } from "./Specialist";
import { Category } from "./Category";
import { Company } from "./Company";
import { SalonService } from "./SalonService";
import { getRatingTitle } from "@/helpers/rating";
import { getImageFromSrc } from "@/helpers/images";

export class Salon extends Model {
  constructor(raw = {}) {
    super();
    const data = raw.data || {};

    this.id = Number(raw.id);
    this.slug = String(raw.slug || "");
    this.companyId = Number(raw.company_id);
    this.salonTypeId = Number(raw.salon_type_id);
    this.city = String(raw.city || "moscow");
    this.coordinates = [Number(raw.latitude || 0), Number(raw.longitude || 0)];
    this.isBestPrice = Boolean(raw.is_best_price);
    this.salonTypeId = Number(raw.salon_type_id);

    this.name = String(data.title || "");
    this.description = String(data.description || "");
    this.logo = getImageFromSrc(data.logo);
    this.cover = getImageFromSrc(data.cover);
    this.hasOffer = Boolean(data.hasOffer);
    this.address = String(data.address || "–");
    this.metro = String(data.metro || "");
    this.galleryId = Number(data.galleryId || 0);
    this.images = (data.images || []).map(
      (image) => getImageFromSrc(image, true)
    );
    this.isDecideYourself = Boolean(data.is_decide_yourself);

    this.startTime = String(data.start_time || "");
    this.endTime = String(data.end_time || "");

    this.reviewsCount = Number(raw.review_count || 0);
    this.rating = Number(raw.rating || 0);
    this.ratingTitle = getRatingTitle(this.rating);

    const specialists = [...(raw.specialists || [])];
    this.specialists = specialists.map((item) => new Specialist(item));

    const services = [...(raw.salon_services || [])];
    this.salonServices = services.map((item) => new SalonService(item));

    this.company = new Company(raw.company || {});
    this.isConfirmed = this.company.isConfirmed;

    this.categories = (raw.categories || [])
      .map((item) => new Category(item))
      .filter((item) => item.isActive);
  }

  get services() {
    return this.specialists.reduce(
      (acc, item) => [...acc, ...item.services],
      []
    );
  }
}
