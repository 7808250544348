<template>
  <!-- Modal with map -->
  <b-modal
    id="map-modal"
    size="xl"
    hide-header
    hide-footer
    @show="onShow"
    @hide="onHide">
    <div class="modal">
      <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content h-100">
          <button
            type="button"
            class="btn-close modal-btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="$bvModal.hide('map-modal')"></button>

          <div class="modal-map__body h-100">
            <div class="map-modal__header">
              <div class="map-modal__header-inner position-relative">
                <button
                  type="button"
                  class="btn-close arrow-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  @click="$bvModal.hide('map-modal')"
                ></button>
                Карта
                <span class="filter-icon" @click="isFilterOpened = true"></span>
              </div>
            </div>

            <div class="map-modal__blocks h-100">
              <div v-if="isSearchRoute" class="map-modal__block filter">
                <div class="map-modal__block-inner custom-scroll h-100">
                  <div class="map-modal__block-title">Фильтры</div>
                  <SearchFilter
                    :class="{ active: isFilterOpened }"
                    @close="isFilterOpened = false"
                  />
                </div>
              </div>

              <div
                v-if="isSearchRoute"
                class="map-modal__block salons"
                :style="{
                  top: `${drawerTop}px`,
                  transition: touchStartY ? '' : 'top 500ms',
                }">
                <div
                  class="map-modal__block-modalScroll"
                  @touchstart="onTouchStart"
                  @touchmove="onTouchMove"
                  @touchend="onTouchEnd"
                ></div>

                <div class="map-modal__block-inner custom-scroll h-100">
                  <div class="map-modal__block-title">Места поблизости</div>
                  <div class="map-modal__salons-found">
                    Найдено мест: {{ searchStore.mapSalonsCount }}
                  </div>
                  <ModalSalons
                    class="map-modal__salons-items"
                    :class="{
                      'map-modal__salons-items_top': drawerTop === maxDrawerTop,
                      'map-modal__salons-items_fill': touchStartY !== 0,
                    }"
                    :is-loading="isLoading"
                    @click="$bvModal.hide('map-modal')"
                    @focusToMark="focusToMark"
                  />
                </div>
              </div>

              <div id="modal-map" class="map-modal__block map">
                <button class="map-modal__location" @click="setLocation">
                  <i class="fas fa-location-arrow"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script setup>
import BModal from "@/components/Modals/BModal/BModal.vue";
import SearchFilter from "@/components/Sidebars/SearchFilter/SearchFilter";
import ModalSalons from "@/components/ModalSalons/ModalSalons";

import { formatMoney } from "@/helpers/formatters";
import locationImg from "@/assets/img/location.png";
import { ref, computed, watch, onMounted, nextTick } from "vue";
const accountModule = useAccount();
const searchStore = useSearch();
import { YandexMap } from "@/services/yandex-map";
import { composeSalonLink } from "@/helpers/links";
import debounce from "lodash/debounce";
import { useRoute } from "vue-router";
const route = useRoute();

const isFilterOpened = ref(false);
const isShown = ref(false);
const drawerTop = ref(0);
const savedDrawerTop = ref(0);
const touchStartY = ref(0);
const deltaTouch = ref(0);
const screenHeight = ref(0);
let map = null;
const isLoading = ref(false);
const center = ref([0, 0]);
const radius = ref(12);
const userMark = ref(null);
const routeInner = ref(null);
const loadSalonsDebounced = debounce(loadSalons, 1000);

const isMobile = computed(() => screenHeight.value < 768);
const minDrawerTop = computed(() => screenHeight.value - 180);
const maxDrawerTop = computed(() => screenHeight.value * 0.1);
const isSearchRoute = computed(() => route.path === "/search");
const salonId = computed(() => +route.query.showMapSalon);

watch(() => searchStore.mapMarks, (val) => {
  if (!map || !isShown.value) return;
  displayMapMarks();
}, { deep: true });

watch(() => route.query, () => {
  if (!isShown.value) return;

  isLoading.value = true;

  map.removeAllMarks();
  loadSalonsDebounced();
}, { deep: true, immediate: true });

function init() {
  savedDrawerTop.value = minDrawerTop.value;
  drawerTop.value = minDrawerTop.value;
}

useAsyncData(() => {
  if (process.client) {
    init();
  }
});

async function loadSalons() {
  isLoading.value = true;
  const params = {
    ...route.query,
    latitude: center.value[0],
    longitude: center.value[1],
    radius: radius.value,
  };
  await searchStore.getMapSalons(params);
  searchStore.getMapMarks(params);
  isLoading.value = false;
}
function setLocation() {
  navigator.geolocation.getCurrentPosition((val) => {
    const coords = [val.coords.latitude, val.coords.longitude];
    map.setCenter(coords);
    userMark.value = {
      center: coords,
      title: "",
      image: locationImg,
      height: 32,
    };

    map.addMark(userMark.value);
  });
}
function focusToMark(coords) {
  map.setCenter(coords);
}
function onTouchStart(e) {
  const touch = e.touches[0];
  if (!touch) return;

  touchStartY.value = touch.clientY;
  savedDrawerTop.value = drawerTop.value;
}
function onTouchMove(e) {
  const touch = e.touches[0];
  if (!touch) return;

  const deltaTouch = touch.clientY - touchStartY.value;
  drawerTop.value = savedDrawerTop.value + deltaTouch;
}
function onTouchEnd() {
  touchStartY.value = 0;
  drawerTop.value =
    drawerTop.value > screenHeight.value * 0.5
      ? minDrawerTop.value
      : maxDrawerTop.value;
}
async function onShow() {
  await nextTick();
  if (isMobile.value) {
    document.documentElement.style.overflow = "hidden";
    document.body.style.overflow = "hidden";
  }
  center.value = [
    Number(accountModule.latitude) || 0,
    Number(accountModule.longitude) || 0,
  ];
  radius.value = 12;
  await createMap();

  if (!salonId.value) {
    setLocation();
    await loadSalons();
  } else {
    await loadSalons();
    const salon = searchStore.salonById(salonId.value);
    map.setCenter(salon.coordinates);
  }

  isShown.value = true;
  displayMapMarks();
}
async function createMap() {
  await nextTick();
  map = await YandexMap.create({
    elementId: "modal-map",
    center: center.value,
    zoom: 11,
  });

  map.map.events.add("boundschange", (e) => {
    if (routeInner.value) return;

    center.value = e.get("newCenter");
    radius.value = map.getRadiusKm(...e.get("newBounds"));

    isLoading.value = true;
    loadSalonsDebounced();
  });
}
function onHide() {
  isShown.value = false;
  map.map.destroy();
  routeInner.value = null;

  if (isMobile.value) {
    document.documentElement.style.overflow = "";
    document.body.style.overflow = "";
  }
}
async function displayMapMarks() {
  map.removeAllMarks();
  if (userMark.value) {
    map.addMark(userMark.value);
  }

  window.onRouteClick = (...coordinates) => {
    if (routeInner.value) return;
    routeInner.value = coordinates;
    map.addRoute({
      from: userMark.value.center,
      to: coordinates,
    });
  };
  const marks = searchStore.mapMarks.map((salon) => {
    const routeLayout = userMark.value
      ? `<a href="#" onclick="window.onRouteClick(${salon.coordinates})">Маршрут</a>`
      : "";

    return {
      center: salon.coordinates,
      title: salon.name,
      text: salon.minPrice ? formatMoney(salon.minPrice) : undefined,
      image: salon.logo,
      layout: `
        <div>
          <h3>${salon.name}</h3>
          <p>${salon.address}</p>
          <a href="${composeSalonLink(salon)}">Забронировать</a>
          ${routeLayout}
        </div>
      `,
    };
  });

  map.addMarks(marks);
}

onMounted(() => {
  screenHeight.value = window.innerHeight;
});
</script>

<style lang="scss">
#map-modal {
  .modal-body {
    padding: 0;
  }

  .modal {
    display: initial;
  }

  .modal-dialog {
    width: 100%;
    min-width: calc(100vw - 80px);
    min-height: calc(100vh - 80px);
    height: 100%;
    margin: 0 auto;
  }

  .search-filter__title {
    display: none;
  }

  .map-modal__block.filter .search-filter__block.rating .custom-input__label {
    display: flex !important;
  }

  .map-salon {
    &__name {
      color: inherit;
      text-decoration: none;
    }
    &__items {
      @media (max-width: 767px) {
        padding-bottom: 20px;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@import "map-modal";

.map-modal {
  &__salons-items {
    padding-bottom: 20px;

    @media (max-width: 767px) {
      padding-bottom: 0px;
      max-height: 50px;
      height: 100%;
      overflow-y: auto;

      &_top {
        max-height: 70vh;
      }

      &_fill {
        max-height: 100%;
      }
    }
  }

  &__location {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    z-index: 1000;
    background-color: $blue;
    color: $white;
    border-radius: 50%;
    border: none;
    outline: none;
    width: 32px;
    height: 32px;

    @media (max-width: 575px) {
      top: unset;
      bottom: 200px;
    }
  }
}

.map-modal__block.salons {
  z-index: 9999;
}
</style>

<style lang="scss">
#modal-map {
  .ymaps-2-1-79-controls-pane {
    display: block !important;
  }

  .ymaps-2-1-79-controls__toolbar,
  .ymaps-2-1-79-controls__bottom {
    display: none;
  }
}
</style>
