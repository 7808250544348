import { Order } from "@/stores/models/Order";
import { Review } from "@/stores/models/Review";
import { mainApi } from "@/stores/api/main-api";
import { useAccount } from "@/stores/account";

const ORDERS_STATUSES = {
  booked: {
    "status[0]": "booked_customer_price",
    "status[1]": "booked_without_cancel",
    "status[2]": "booked_with_cancel",
    "status[3]": "payed_customer_price",
    "status[4]": "payed_without_cancel",
    "status[5]": "payed_with_cancel",
    "status[6]": "new",
  },
  canceled: {
    "status[0]": "user-cancel",
    "status[1]": "salon-cancel",
    "status[2]": "cancel",
  },
  completed: {
    status: "complete",
  },
};

export const useOrdersModule = defineStore("ordersModule", {
  state: () => ({
    isCanceledLoading: false,
    isCompletedLoading: false,
    isBookedLoading: false,

    _orders: [],
    _canceledOrders: [],
    _completedOrders: [],
    _bookedOrders: [],
    _reviews: [],

    canceledOrdersCount: 0,
    completedOrdersCount: 0,
    bookedOrdersCount: 0,

    canceledOrdersAmount: 0,
    completedOrdersAmount: 0,
    bookedOrdersAmount: 0,

    selectedOrder: null,
  }),

  getters: {
    orders() {
      return this._orders.map((item) => new Order(item));
    },

    pendingOrders() {
      return this._bookedOrders.map((item) => new Order(item));
    },

    completedOrders() {
      return this._completedOrders.map((item) => new Order(item));
    },

    canceledOrders() {
      return this._canceledOrders.map((item) => new Order(item));
    },

    getReview() {
      return (id) => {
        const review = this._reviews.find((item) => item.id === id);
        return review ? new Review(review) : null;
      };
    },
  },

  actions: {
    setSelectedOrder(order) {
      this.selectedOrder = order;
    },

    async loadOrders() {
      try {
        await Promise.all([
          this.loadCanceledOrders(),
          this.loadBookedOrders(),
          this.loadCompletedOrders(),
        ]);
      } catch (err) {
        console.log("loadOrders err", [err]);
      }
    },

    async loadCanceledOrders(loadNext = false) {
      const { $doNoty: doNoty } = useNuxtApp();
      const accountModule = useAccount();
      this.isCanceledLoading = true;

      try {
        const { data } = await mainApi.getOrders({
          user_id: accountModule.userId,
          limit: 3,
          ...ORDERS_STATUSES.canceled,
          offset: loadNext ? Math.floor(this._canceledOrders.length / 3) : 0,
          "order_by[start_at]": "desc",
        });

        if (loadNext) {
          this._canceledOrders = this._canceledOrders.concat(data.data);
        } else {
          this.canceledOrdersCount = data.count;
          this.canceledOrdersAmount = data.priceSum;
          this._canceledOrders = data.data;
        }
      } catch (err) {
        doNoty.error(err);
      }

      this.isCanceledLoading = false;
    },

    async loadCompletedOrders(loadNext = false) {
      const { $doNoty: doNoty } = useNuxtApp();
      const accountModule = useAccount();
      this.isCompletedLoading = true;

      try {
        const { data } = await mainApi.getOrders({
          user_id: accountModule.userId,
          limit: 3,
          ...ORDERS_STATUSES.completed,
          offset: loadNext ? Math.floor(this._completedOrders.length / 3) : 0,
          "order_by[start_at]": "desc",
        });

        if (loadNext) {
          this._completedOrders = this._completedOrders.concat(data.data);
        } else {
          this.completedOrdersCount = data.count;
          this.completedOrdersAmount = data.priceSum;
          this._completedOrders = data.data;
        }
      } catch (err) {
        doNoty.error(err);
      }

      this.isCompletedLoading = false;
    },

    async loadBookedOrders(loadNext = false) {
      const { $doNoty: doNoty } = useNuxtApp();
      const accountModule = useAccount();
      this.isBookedLoading = true;

      try {
        const { data } = await mainApi.getOrders({
          user_id: accountModule.userId,
          limit: 3,
          ...ORDERS_STATUSES.booked,
          offset: loadNext ? Math.floor(this._bookedOrders.length / 3) : 0,
          "order_by[start_at]": "asc",
        });

        if (loadNext) {
          this._bookedOrders = this._bookedOrders.concat(data.data);
        } else {
          this.bookedOrdersCount = data.count;
          this.bookedOrdersAmount = data.priceSum;
          this._bookedOrders = data.data;
        }
      } catch (err) {
        doNoty.error(err);
      }

      this.isBookedLoading = false;
    },

    async updateCanceledOrders() {
      const { $doNoty: doNoty } = useNuxtApp();
      const accountModule = useAccount();
      this.isCanceledLoading = true;

      try {
        const { data } = await mainApi.getOrders({
          user_id: accountModule.userId,
          limit: this._canceledOrders.length,
          ...ORDERS_STATUSES.canceled,
        });

        this.canceledOrdersCount = data.count;
        this.canceledOrdersAmount = data.priceSum;
        this._canceledOrders = data.data;
      } catch (err) {
        doNoty.error(err);
      }

      this.isCanceledLoading = false;
    },

    async updateCompletedOrders() {
      const accountModule = useAccount();
      this.isCompletedLoading = true;

      try {
        const { data } = await mainApi.getOrders({
          user_id: accountModule.userId,
          limit: this._completedOrders.length,
          ...ORDERS_STATUSES.completed,
        });

        this.completedOrdersCount = data.count;
        this.completedOrdersAmount = data.priceSum;
        this._completedOrders = data.data;
      } catch (err) {
        const { $doNoty: doNoty } = useNuxtApp();
        doNoty.error(err);
      }

      this.isCompletedLoading = false;
    },

    async updateBookedOrders() {
      const accountModule = useAccount();
      this.isBookedLoading = true;

      try {
        const { data } = await mainApi.getOrders({
          user_id: accountModule.userId,
          limit: this._bookedOrders.length,
          ...ORDERS_STATUSES.booked,
        });

        this.bookedOrdersCount = data.count;
        this.bookedOrdersAmount = data.priceSum;
        this._bookedOrders = data.data;
      } catch (err) {
        const { $doNoty: doNoty } = useNuxtApp();
        doNoty.error(err);
      }

      this.isBookedLoading = false;
    },

    async cancelOrder() {
      const { $doNoty: doNoty } = useNuxtApp();
      try {
        await mainApi.cancelOrder(this.selectedOrder.id);
        doNoty.success("Бронирование успешно отменено");
        this.loadOrders();
      } catch (err) {
        doNoty.error(err);
      } finally {
        this.selectedOrder = null;
      }
    },

    async complainOrder() {
      const { $doNoty: doNoty } = useNuxtApp();
      try {
        await mainApi.complainOrder(this.selectedOrder.id);
        doNoty.success("Жалоба на обман принята");
        this.loadOrders();
      } catch (err) {
        doNoty.error(err);
      } finally {
        this.selectedOrder = null;
      }
    },

    async postponeOrder({ startAt, finishAt }) {
      const { $doNoty: doNoty } = useNuxtApp();
      try {
        await mainApi.updateOrder(this.selectedOrder.id, {
          start_at: startAt,
          finish_at: finishAt,
          price: this.selectedOrder.price,
        });
        doNoty.success("Бронирование успешно перенесено");
        this.loadOrders();
      } catch (err) {
        doNoty.error(err);
      } finally {
        this.selectedOrder = null;
      }
    },

    async updateOrder({ order, data }) {
      try {
        await mainApi.updateOrder(order.id, {
          start_at: order.startAt.toISOString(),
          finish_at: order.finishAt.toISOString(),
          price: order.price,
          status: order.status,
          data,
        });
      } catch (err) {
        const { $doNoty: doNoty } = useNuxtApp();
        doNoty.error(err);
      }
    },

    async loadReview({ order }) {
      const accountModule = useAccount();
      try {
        if (this.getReview(order.reviewId)) return;
        const { data } = await mainApi.getReviews({
          user_id: accountModule.userId,
          salon_id: order.salonId,
        });
        this._reviews = this._reviews.concat(data.data);
      } catch (err) {
        const { $doNoty: doNoty } = useNuxtApp();
        doNoty.error(err);
      }
    },
  },
});
