<template>
  <div class="registration__group">
    <label for="email" class="auth__label">E-mail</label>
    <div class="send__message--field">
      <div class="reg__field--block" :class="{ error: v$.email.$error }">
        <input
          type="email"
          id="email"
          v-model="form.email"
          name="email"
          @blur="setEmail"
          @input="setEmail"
          class="reg__field"
          autocomplete="off" />
        <div v-if="v$.email.$error && !telephone" class="errors">
          <p class="error__text" v-if="!v$.email.required && !telephone">
            {{ $t("auth.oneOfThemFieldIsRequired") }}
          </p>
          <p class="error__text" v-else-if="!v$.email.email">
            {{ $t("auth.phoneEmail") }}
          </p>
        </div>
      </div>
      <div
        class="message__block"
        :class="{ 'message__block--active': showCodeWindow }">
        <div class="message__block-content">
          <div class="message__code--block">
            <h3 class="message__code--title">{{ $t("auth.acceptEmail") }}</h3>
            <div
              class="message__code--input"
              :class="{ error: v$.pinCode.$error }">
              <PincodeInput
                v-model="form.pinCode"
                placeholder="0"
                :digits="6"
                @input="setEmailPin()" />
            </div>
            <div v-if="v$.pinCode.$error" class="errors">
              <p class="error__text" v-if="!v$.pinCode.required">
                {{ $t("auth.requiredToFill") }}
              </p>
              <p class="error__text" v-if="!v$.pinCode.numeric">
                {{ $t("auth.onlyNumber") }}
              </p>
              <p class="error__text" v-if="!v$.pinCode.minLength">
                {{ $t("auth.pinLength") }}
              </p>
            </div>
          </div>

          <div class="message__send--block">
            <h5 class="message__send--title">
              <template v-if="!isFirstSend">Не пришло оповещение?</template>
            </h5>
            <button
              type="button"
              @click="sendRepeat"
              :disabled="rep_send.isRunning === true"
              class="message__send--btn">
              {{ isFirstSend ? $t("auth.sendCode") : $t("auth.sendCodeAgain") }}
            </button>
            <div class="message__send--timer">
              {{ prettyTime }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import PincodeInput from "vue3-pincode-input";

import { ref, reactive, computed, onMounted } from "vue";
import {
  required,
  email,
  numeric,
  minLength,
  requiredIf,
} from "@vuelidate/validators";

const props = defineProps({
  telephone: String,
});
const emit = defineEmits(["setEmail", "setEmailPin"]);
const authStore = useAuth();

const rules = computed(() => ({
  email: {
    required: requiredIf(function () {
      return telephone.value ? false : true;
    }),
    email,
  },
  pinCode: {
    required,
    numeric,
    minLength: minLength(6),
  },
}));

const isFirstSend = ref(true);
const showCodeWindow = ref(false);
const rep_send = reactive({
  isRunning: false,
  minute: 1,
  second: 0,
  time: 0,
  timer: null,
});
const form = reactive({
  email: "",
  pinCode: "",
});
import { useVuelidate } from "@vuelidate/core";
const v$ = useVuelidate(rules, form);

const prettyTime = computed(() => {
  let time = rep_send.time / 60;
  let minutes = parseInt(time);
  let secondes = Math.round((time - minutes) * 60);
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (secondes < 10) {
    secondes = `0${secondes}`;
  }
  return minutes + ":" + secondes;
});

function emailCheck() {
  v$.value.$touch();
}
function setEmail() {
  emit("setEmail", {
    val: form.email,
    hasError: v$.value.email.$error,
  });
  setEmailPin();
  v$.value.$touch();
  showCodeWindow.value = false;
  if (!v$.value.email.$error && form.email.length) {
    showCodeWindow.value = true;
  }
}
function sendCode() {
  if (!v$.value.email.$error) {
    authStore.sendConfirmCode({
      email: form.email,
    });
  }
}
function setEmailPin() {
  emit("setEmailPin", {
    val: form.pinCode,
    hasError: v$.value.pinCode.$error,
  });
}
const registrationClinicModule = useRegistrationClinicModule();
function sendRepeat() {
  if (rep_send.isRunning === false) {
    registrationClinicModule.sendEmail(form.email);
    startTimer();
    sendCode();
    form.pinCode = "";
  }
  isFirstSend.value = false;
}
function startTimer() {
  rep_send.isRunning = true;
  if (!rep_send.timer) {
    rep_send.timer = setInterval(() => {
      if (rep_send.time > 0) {
        rep_send.time--;
      } else {
        clearInterval(rep_send.timer);
        if (rep_send.time == 0) {
          rep_send.isRunning = false;
        }
        rep_send.isRunning = false;
        setTime();
      }
    }, 1000);
  }
}
function setTime() {
  rep_send.time = rep_send.minute * 60 + rep_send.second;
}

const { $eventHub } = useNuxtApp();
useAsyncData(() => {
  $eventHub.$on("emailCheck", emailCheck);
});
onMounted(() => {
  setTime();
});
</script>
