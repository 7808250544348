export const USER_STATUSES = {
  classic: "classic",
  silver: "silver",
  gold: "gold",
  platinum: "platinum",
};

import classicImg from "@/assets/img/status/steps-bg1.png";
import silverImg from "@/assets/img/status/steps-bg2.png";
import goldImg from "@/assets/img/status/steps-bg3.png";
import platinumImg from "@/assets/img/status/steps-bg4.png";
export const STATUS_IMAGES_MAP = {
  [USER_STATUSES.classic]: classicImg,
  [USER_STATUSES.silver]: silverImg,
  [USER_STATUSES.gold]: goldImg,
  [USER_STATUSES.platinum]: platinumImg,
};
