import Cookies from "js-cookie";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import flatten from "lodash/flatten";
import uniq from "lodash/uniq";
import { getDayStartDate } from "@/helpers/date";

import { useAccount } from "./account";
import { usePayment } from "./payment";
import { composeSalonLink } from "@/helpers/links";

import { Salon } from "@/stores/models/Salon";
import { SpecialistService } from "@/stores/models/SpecialistService";
import { Specialist } from "@/stores/models/Specialist";
import { Order } from "@/stores/models/Order";

import { mainApi } from "@/stores/api/main-api";
import { paymentsApi } from "@/stores/api/payments-api";
import { getDateString } from "@/helpers/date";

export const ORDER_TYPES = Object.freeze({
  base: "0",
  cancel: "1",
  custom: "2",
});

export const ORDER_PAYMENT_TYPES = Object.freeze({
  [ORDER_TYPES.base]: Order.states.payedWithoutCancel,
  [ORDER_TYPES.cancel]: Order.states.payedWithCancel,
  [ORDER_TYPES.custom]: Order.states.payedCustomerPrice,
});

export const ORDER_BOOK_TYPES = Object.freeze({
  [ORDER_TYPES.base]: Order.states.bookedWithoutCancel,
  [ORDER_TYPES.cancel]: Order.states.bookedWithCancel,
  [ORDER_TYPES.custom]: Order.states.bookedCustomerPrice,
});

export const PAYMENT_TYPES = Object.freeze({
  save: "save",
  noSave: "noSave",
});

const DEFAULT_ORDER = {
  id: 0,
  categoryId: 0,
  date: null,
  specialistId: 0,
  serviceId: 0,
  serviceTitle: "",
  price: 0,
  type: ORDER_TYPES.base,
  status: 0,
  paymentType: PAYMENT_TYPES.save,
};

export const useOrder = defineStore("order", {
  state: () => ({
    isLoading: false,
    isScheduleLoading: false,
    order: { ...DEFAULT_ORDER },

    salon: new Salon(),
    service: new SpecialistService(),
    specialists: [],
    services: [],
    schedule: [],
  }),

  getters: {
    selectedSpecialist() {
      return (
        this.specialists.find((item) => item.id === this.order.specialistId) || {}
      );
    },

    categorySpecialists() {
      return this.specialists.filter((item) =>
        item.services.find(
          (service) => service.serviceId === this.order.serviceId
        )
      );
    },

    selectedService() {
      const searchFrom = this.searchServices?.length
        ? this.searchServices
        : this.selectedSpecialist.services;

      return (
        (searchFrom || []).find(
          (item) => item.serviceId === this.order.serviceId
        ) || {}
      );
    },

    searchServices() {
      return this.services.map(
        (item) => new SpecialistService(item.specialist_service)
      );
    },

    processedOrder() {
      return {
        ...new Order(this.order),
        salon: new Salon(this.order.salon),
        specialist: new Specialist(this.order.specialist),
      };
    },

    timeSlots() {
      if (!this.schedule.length) return [];

      const slots = [];
      const schedule = flatten(this.schedule.map((item) => item.free_time));

      schedule.forEach((slot) => {
        const HALF_HOUR = 30 * 60 * 1000;
        let startTime = new Date(slot[0]).getTime();
        let endTime = new Date(slot[1]).getTime();

        startTime = Math.max(startTime, Date.now());
        endTime -= (this.selectedService?.duration || 30) * 60 * 1000;

        if (startTime % HALF_HOUR > 0) {
          startTime -= (startTime % HALF_HOUR) + HALF_HOUR;
        }

        while (startTime <= endTime) {
          slots.push(startTime);
          startTime += HALF_HOUR;
        }
      });

      return uniq(slots)
        .sort((item1, item2) => (item1 > item2 ? 1 : -1))
        .map((slot) => new Date(slot))
        .map((slot) => {
          const hoursStr = String(slot.getHours()).padStart(2, "0");
          const minutesStr = String(slot.getMinutes()).padStart(2, "0");
          return `${hoursStr}:${minutesStr}`;
        });
    },

    isSpecialistBusy() {
      return (id, userId) => {
        if (!this.timeSlots.length) return true;

        if (!this.order.date) return false;

        const freeTimes =
          this.schedule.find((item) => item.user_id === userId)?.free_time || [];

        const orderDate = new Date(this.order.date);

        for (const [startDate, endDate] of freeTimes) {
          if (new Date(startDate) <= orderDate && new Date(endDate) >= orderDate)
            return false;
        }
        return true;
      };
    },
  },

  actions: {
    updateOrder(order) {
      this.order = { ...this.order, ...order };
    },

    resetOrder() {
      this.order = { ...DEFAULT_ORDER };
      this.salon = new Salon();
      this.specialists = [];
      this.services = [];
    },

    setOrder({
      serviceId,
      serviceTitle,
      categoryId,
      salon,
      specialists,
      services,
      specialistId
    }) {
      const { $eventHub } = useNuxtApp();
      const accountModule = useAccount();
      if (!accountModule.userId) {
        const query = specialistId
          ? `specialist_id=${specialistId}&category_id=${categoryId}`
          : `service_id=${serviceId}`;
        $eventHub.$emit("show-auth-modal", {
          redirectUrl: `${composeSalonLink(salon)}?${query}`,
        });
        return;
      }

      this.salon = salon;
      this.order.serviceId = serviceId;
      this.order.serviceTitle = serviceTitle;
      this.order.categoryId = categoryId;

      if (specialistId) {
        this.order.specialistId = specialistId;
      }

      if (specialists) {
        this.specialists = specialists;
      }

      if (services) {
        this.services = services;
      }

      this.loadInfo();
    },

    async loadInfo() {
      const { $doNoty: doNoty } = useNuxtApp();
      this.isLoading = true;
      try {
        if (!this.specialists.length) {
          const { data: specialists } = await mainApi.getSpecialists({
            salon_id: this.salon.id,
            category_id: this.order.categoryId,
          });
          this.specialists = specialists.data.map((item) => new Specialist(item));
        }

        if (!this.order.serviceId && this.order.specialistId) {
          const { data: services } = await mainApi.searchServices({
            specialist_id: this.order.specialistId,
          });
          this.services = services.services.filter(
            (item) => item.salon_id === this.salon.id
          );
        }

        await this.loadSchedule(getDayStartDate());
      } catch (err) {
        doNoty.error(err);
      } finally {
        this.isLoading = false;
      }
    },

    async loadSchedule(orderDate) {
      const { $doNoty: doNoty } = useNuxtApp();
      this.isScheduleLoading = true;
      try {
        this.schedule = [];
        const specialist_id = this.categorySpecialists.map((item) => item.id);
        if (Array.isArray(specialist_id) && !specialist_id?.length) return;

        const date = getDateString(orderDate);
        const { data } = await mainApi.getFreeTime({
          specialist_id,
          date,
        });
        this.schedule = data.data.map((item) => ({
          user_id: item.user_id,
          free_time: item.free_time,
        }));
      } catch (err) {
        doNoty.error(err);
        console.error(err);
      } finally {
        this.isScheduleLoading = false;
      }
    },

    async createOrder() {
      const accountModule = useAccount();
      const finishTimestamp =
        this.order.date.getTime() + this.selectedService.duration * 60 * 1000;
      const startAt = this.order.date.toISOString();

      const { data } = await mainApi.createOrder({
        user_id: accountModule.userId,
        salon_id: this.salon.id,
        specialist_id: this.order.specialistId,
        specialist_service_id: this.selectedService.id,
        price: this.order.price,
        start_at: moment(startAt).format("YYYY-MM-DD H:mm:ss"),
        finish_at: moment(new Date(finishTimestamp).toISOString()).format(
          "YYYY-MM-DD H:mm:ss"
        ),
        type: 0,
        data: {},
      });
      this.updateOrder(data);
    },

    async payOrder(balance = 0) {
      const accountModule = useAccount();
      const paymentModule = usePayment();
      if (balance > 0) {
        const { data } = await paymentsApi.withdrawBalance({
          access_token: Cookies.get("accessToken"),
          amount: balance,
          currency: "RUB",
        });
        accountModule.loadUserInfo();

        if (!data.ok) throw new Error(data.message);
      }

      await paymentModule.init({
        amount: this.order.price - balance,
        metadata: {
          order_id: String(this.order.id),
          user_id: accountModule.userId,
          status: ORDER_PAYMENT_TYPES[this.order.type],
        },
        redirect: `${location.href}#success`,
        transferId: `$shopAccount:${this.salon.companyId}`,
        isSaved: this.order.paymentType === PAYMENT_TYPES.save,
        paymentId: [PAYMENT_TYPES.save, PAYMENT_TYPES.noSave].includes(
          this.order.paymentType
        )
          ? ""
          : this.order.paymentType,
      });
    },

    async bookOrder() {
      const { $doNoty: doNoty } = useNuxtApp();
      try {
        await mainApi.updateOrder(this.order.id, {
          price: this.order.price,
          status: ORDER_BOOK_TYPES[this.order.type],
          start_at: this.order.start_at,
          finish_at: this.order.finish_at,
        });
        await paymentsApi.setPrimaryCard(this.order.paymentType);
        doNoty.success("Бронирование прошло успешно!");
      } catch (err) {
        doNoty.error(err);
      }
    },

    async loadOrder(id) {
      const { $doNoty: doNoty } = useNuxtApp();
      try {
        const { data } = await mainApi.getOrder(id);

        this.updateOrder(data);
      } catch (err) {
        doNoty.error(err);
      }
    },

    async isOrderAllowed() {
      if (!this.order.date || isEmpty(this.selectedService)) return false;

      const finishTimestamp =
        this.order.date.getTime() + this.selectedService.duration * 60 * 1000;
      const startAt = this.order.date.toISOString();

      const { data } = await mainApi.allowOrder({
        specialist_id: this.order.specialistId,
        start_at: moment(startAt).format("YYYY-MM-DD H:mm:ss"),
        finish_at: moment(new Date(finishTimestamp).toISOString()).format(
          "YYYY-MM-DD H:mm:ss"
        ),
      });
      return data.allow_order;
    },
  },
});
