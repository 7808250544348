<template>
  <client-only>
    <skeleton v-bind="props"></skeleton>
  </client-only>
</template>

<script setup>
import { Skeleton } from "vue-loading-skeleton";

const props = defineProps({
  type: String,
  width: {
    required: false,
  },
  height: {
    required: false,
  },
  rounded: Boolean,
  radius: Number,
  animation: String,
});
</script>

<style lang="scss">
.pu-skeleton {
  display: inline-block;
}
</style>
