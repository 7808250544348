import { default as _91id_93cg8KmsgF7TMeta } from "/builds/wellfinder/site-frontend/client/pages/[id].vue?macro=true";
import { default as aboutscNcIgjcawMeta } from "/builds/wellfinder/site-frontend/client/pages/about.vue?macro=true";
import { default as _91id_93ng7H0NTkv7Meta } from "/builds/wellfinder/site-frontend/client/pages/blog/[id].vue?macro=true";
import { default as indexM2ZsJryvPFMeta } from "/builds/wellfinder/site-frontend/client/pages/blog/index.vue?macro=true";
import { default as businessUvvyYrtxmsMeta } from "/builds/wellfinder/site-frontend/client/pages/business.vue?macro=true";
import { default as conditionsWUTtkXGIa1Meta } from "/builds/wellfinder/site-frontend/client/pages/conditions.vue?macro=true";
import { default as contactsRPIPax4RDRMeta } from "/builds/wellfinder/site-frontend/client/pages/contacts.vue?macro=true";
import { default as erroryuiNdRvxt0Meta } from "/builds/wellfinder/site-frontend/client/pages/error.vue?macro=true";
import { default as indexIsdnS83qVdMeta } from "/builds/wellfinder/site-frontend/client/pages/index.vue?macro=true";
import { default as login6GZkltQKsnMeta } from "/builds/wellfinder/site-frontend/client/pages/login.vue?macro=true";
import { default as mediawm6CsNyftyMeta } from "/builds/wellfinder/site-frontend/client/pages/media.vue?macro=true";
import { default as not_45foundMUTPCzi7U0Meta } from "/builds/wellfinder/site-frontend/client/pages/not-found.vue?macro=true";
import { default as privacyriv607gS0WMeta } from "/builds/wellfinder/site-frontend/client/pages/privacy.vue?macro=true";
import { default as public_45offeryJ11rSvbGgMeta } from "/builds/wellfinder/site-frontend/client/pages/public-offer.vue?macro=true";
import { default as recovery_45passwordiiXc3bKPASMeta } from "/builds/wellfinder/site-frontend/client/pages/recovery-password.vue?macro=true";
import { default as searchGKmuUiZPKGMeta } from "/builds/wellfinder/site-frontend/client/pages/search.vue?macro=true";
import { default as services8D1Qu4ZbdgMeta } from "/builds/wellfinder/site-frontend/client/pages/services.vue?macro=true";
import { default as signup4v9wS9IymSMeta } from "/builds/wellfinder/site-frontend/client/pages/signup.vue?macro=true";
import { default as _91id_93B2jqdqRzTjMeta } from "/builds/wellfinder/site-frontend/client/pages/specialist/[id].vue?macro=true";
import { default as _91categoryId_93i1cKwKKrDnMeta } from "/builds/wellfinder/site-frontend/client/pages/specialists/[categoryId].vue?macro=true";
import { default as indexbgHHmqb69uMeta } from "/builds/wellfinder/site-frontend/client/pages/specialists/index.vue?macro=true";
import { default as tariffs1p2MrUj5GuMeta } from "/builds/wellfinder/site-frontend/client/pages/tariffs.vue?macro=true";
import { default as teasersXiVy9P6fIMMeta } from "/builds/wellfinder/site-frontend/client/pages/teasers.vue?macro=true";
import { default as _91id_93ShzIq6cD1oMeta } from "/builds/wellfinder/site-frontend/client/pages/user/chat/[id].vue?macro=true";
import { default as indexY5AmxyRVbzMeta } from "/builds/wellfinder/site-frontend/client/pages/user/chat/index.vue?macro=true";
import { default as favoritej33rDUkHQ6Meta } from "/builds/wellfinder/site-frontend/client/pages/user/favorite.vue?macro=true";
import { default as internal_45accountpgecinQxcXMeta } from "/builds/wellfinder/site-frontend/client/pages/user/internal-account.vue?macro=true";
import { default as _91tab_93sWJB4GimHFMeta } from "/builds/wellfinder/site-frontend/client/pages/user/notes/[tab].vue?macro=true";
import { default as orders2Og0kH5I9hMeta } from "/builds/wellfinder/site-frontend/client/pages/user/orders.vue?macro=true";
import { default as profile3YZTxTJQVYMeta } from "/builds/wellfinder/site-frontend/client/pages/user/profile.vue?macro=true";
import { default as referral_45systemq1ZVqG10M1Meta } from "/builds/wellfinder/site-frontend/client/pages/user/referral-system.vue?macro=true";
import { default as reviews8II3VJ6AEiMeta } from "/builds/wellfinder/site-frontend/client/pages/user/reviews.vue?macro=true";
import { default as statusn8qySpZZyXMeta } from "/builds/wellfinder/site-frontend/client/pages/user/status.vue?macro=true";
import { default as transactionu3PIvpWk7AMeta } from "/builds/wellfinder/site-frontend/client/pages/user/transaction.vue?macro=true";
export default [
  {
    name: _91id_93cg8KmsgF7TMeta?.name ?? "id",
    path: _91id_93cg8KmsgF7TMeta?.path ?? "/:id()",
    meta: _91id_93cg8KmsgF7TMeta || {},
    alias: _91id_93cg8KmsgF7TMeta?.alias || [],
    redirect: _91id_93cg8KmsgF7TMeta?.redirect || undefined,
    component: () => import("/builds/wellfinder/site-frontend/client/pages/[id].vue").then(m => m.default || m)
  },
  {
    name: aboutscNcIgjcawMeta?.name ?? "about",
    path: aboutscNcIgjcawMeta?.path ?? "/about",
    meta: aboutscNcIgjcawMeta || {},
    alias: aboutscNcIgjcawMeta?.alias || [],
    redirect: aboutscNcIgjcawMeta?.redirect || undefined,
    component: () => import("/builds/wellfinder/site-frontend/client/pages/about.vue").then(m => m.default || m)
  },
  {
    name: _91id_93ng7H0NTkv7Meta?.name ?? "blog-id",
    path: _91id_93ng7H0NTkv7Meta?.path ?? "/blog/:id()",
    meta: _91id_93ng7H0NTkv7Meta || {},
    alias: _91id_93ng7H0NTkv7Meta?.alias || [],
    redirect: _91id_93ng7H0NTkv7Meta?.redirect || undefined,
    component: () => import("/builds/wellfinder/site-frontend/client/pages/blog/[id].vue").then(m => m.default || m)
  },
  {
    name: indexM2ZsJryvPFMeta?.name ?? "blog",
    path: indexM2ZsJryvPFMeta?.path ?? "/blog",
    meta: indexM2ZsJryvPFMeta || {},
    alias: indexM2ZsJryvPFMeta?.alias || [],
    redirect: indexM2ZsJryvPFMeta?.redirect || undefined,
    component: () => import("/builds/wellfinder/site-frontend/client/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: businessUvvyYrtxmsMeta?.name ?? "business",
    path: businessUvvyYrtxmsMeta?.path ?? "/business",
    meta: businessUvvyYrtxmsMeta || {},
    alias: businessUvvyYrtxmsMeta?.alias || [],
    redirect: businessUvvyYrtxmsMeta?.redirect || undefined,
    component: () => import("/builds/wellfinder/site-frontend/client/pages/business.vue").then(m => m.default || m)
  },
  {
    name: conditionsWUTtkXGIa1Meta?.name ?? "conditions",
    path: conditionsWUTtkXGIa1Meta?.path ?? "/conditions",
    meta: conditionsWUTtkXGIa1Meta || {},
    alias: conditionsWUTtkXGIa1Meta?.alias || [],
    redirect: conditionsWUTtkXGIa1Meta?.redirect || undefined,
    component: () => import("/builds/wellfinder/site-frontend/client/pages/conditions.vue").then(m => m.default || m)
  },
  {
    name: contactsRPIPax4RDRMeta?.name ?? "contacts",
    path: contactsRPIPax4RDRMeta?.path ?? "/contacts",
    meta: contactsRPIPax4RDRMeta || {},
    alias: contactsRPIPax4RDRMeta?.alias || [],
    redirect: contactsRPIPax4RDRMeta?.redirect || undefined,
    component: () => import("/builds/wellfinder/site-frontend/client/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: erroryuiNdRvxt0Meta?.name ?? "error",
    path: erroryuiNdRvxt0Meta?.path ?? "/error",
    meta: erroryuiNdRvxt0Meta || {},
    alias: erroryuiNdRvxt0Meta?.alias || [],
    redirect: erroryuiNdRvxt0Meta?.redirect || undefined,
    component: () => import("/builds/wellfinder/site-frontend/client/pages/error.vue").then(m => m.default || m)
  },
  {
    name: indexIsdnS83qVdMeta?.name ?? "index",
    path: indexIsdnS83qVdMeta?.path ?? "/",
    meta: indexIsdnS83qVdMeta || {},
    alias: indexIsdnS83qVdMeta?.alias || [],
    redirect: indexIsdnS83qVdMeta?.redirect || undefined,
    component: () => import("/builds/wellfinder/site-frontend/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: login6GZkltQKsnMeta?.name ?? "login",
    path: login6GZkltQKsnMeta?.path ?? "/login",
    meta: login6GZkltQKsnMeta || {},
    alias: login6GZkltQKsnMeta?.alias || [],
    redirect: login6GZkltQKsnMeta?.redirect || undefined,
    component: () => import("/builds/wellfinder/site-frontend/client/pages/login.vue").then(m => m.default || m)
  },
  {
    name: mediawm6CsNyftyMeta?.name ?? "media",
    path: mediawm6CsNyftyMeta?.path ?? "/media",
    meta: mediawm6CsNyftyMeta || {},
    alias: mediawm6CsNyftyMeta?.alias || [],
    redirect: mediawm6CsNyftyMeta?.redirect || undefined,
    component: () => import("/builds/wellfinder/site-frontend/client/pages/media.vue").then(m => m.default || m)
  },
  {
    name: not_45foundMUTPCzi7U0Meta?.name ?? "not-found",
    path: not_45foundMUTPCzi7U0Meta?.path ?? "/not-found",
    meta: not_45foundMUTPCzi7U0Meta || {},
    alias: not_45foundMUTPCzi7U0Meta?.alias || [],
    redirect: not_45foundMUTPCzi7U0Meta?.redirect || undefined,
    component: () => import("/builds/wellfinder/site-frontend/client/pages/not-found.vue").then(m => m.default || m)
  },
  {
    name: privacyriv607gS0WMeta?.name ?? "privacy",
    path: privacyriv607gS0WMeta?.path ?? "/privacy",
    meta: privacyriv607gS0WMeta || {},
    alias: privacyriv607gS0WMeta?.alias || [],
    redirect: privacyriv607gS0WMeta?.redirect || undefined,
    component: () => import("/builds/wellfinder/site-frontend/client/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: public_45offeryJ11rSvbGgMeta?.name ?? "public-offer",
    path: public_45offeryJ11rSvbGgMeta?.path ?? "/public-offer",
    meta: public_45offeryJ11rSvbGgMeta || {},
    alias: public_45offeryJ11rSvbGgMeta?.alias || [],
    redirect: public_45offeryJ11rSvbGgMeta?.redirect || undefined,
    component: () => import("/builds/wellfinder/site-frontend/client/pages/public-offer.vue").then(m => m.default || m)
  },
  {
    name: recovery_45passwordiiXc3bKPASMeta?.name ?? "recovery-password",
    path: recovery_45passwordiiXc3bKPASMeta?.path ?? "/recovery-password",
    meta: recovery_45passwordiiXc3bKPASMeta || {},
    alias: recovery_45passwordiiXc3bKPASMeta?.alias || [],
    redirect: recovery_45passwordiiXc3bKPASMeta?.redirect || undefined,
    component: () => import("/builds/wellfinder/site-frontend/client/pages/recovery-password.vue").then(m => m.default || m)
  },
  {
    name: searchGKmuUiZPKGMeta?.name ?? "search",
    path: searchGKmuUiZPKGMeta?.path ?? "/search",
    meta: searchGKmuUiZPKGMeta || {},
    alias: searchGKmuUiZPKGMeta?.alias || [],
    redirect: searchGKmuUiZPKGMeta?.redirect || undefined,
    component: () => import("/builds/wellfinder/site-frontend/client/pages/search.vue").then(m => m.default || m)
  },
  {
    name: services8D1Qu4ZbdgMeta?.name ?? "services",
    path: services8D1Qu4ZbdgMeta?.path ?? "/services",
    meta: services8D1Qu4ZbdgMeta || {},
    alias: services8D1Qu4ZbdgMeta?.alias || [],
    redirect: services8D1Qu4ZbdgMeta?.redirect || undefined,
    component: () => import("/builds/wellfinder/site-frontend/client/pages/services.vue").then(m => m.default || m)
  },
  {
    name: signup4v9wS9IymSMeta?.name ?? "signup",
    path: signup4v9wS9IymSMeta?.path ?? "/signup",
    meta: signup4v9wS9IymSMeta || {},
    alias: signup4v9wS9IymSMeta?.alias || [],
    redirect: signup4v9wS9IymSMeta?.redirect || undefined,
    component: () => import("/builds/wellfinder/site-frontend/client/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: _91id_93B2jqdqRzTjMeta?.name ?? "specialist-id",
    path: _91id_93B2jqdqRzTjMeta?.path ?? "/specialist/:id()",
    meta: _91id_93B2jqdqRzTjMeta || {},
    alias: _91id_93B2jqdqRzTjMeta?.alias || [],
    redirect: _91id_93B2jqdqRzTjMeta?.redirect || undefined,
    component: () => import("/builds/wellfinder/site-frontend/client/pages/specialist/[id].vue").then(m => m.default || m)
  },
  {
    name: _91categoryId_93i1cKwKKrDnMeta?.name ?? "specialists-categoryId",
    path: _91categoryId_93i1cKwKKrDnMeta?.path ?? "/specialists/:categoryId()",
    meta: _91categoryId_93i1cKwKKrDnMeta || {},
    alias: _91categoryId_93i1cKwKKrDnMeta?.alias || [],
    redirect: _91categoryId_93i1cKwKKrDnMeta?.redirect || undefined,
    component: () => import("/builds/wellfinder/site-frontend/client/pages/specialists/[categoryId].vue").then(m => m.default || m)
  },
  {
    name: indexbgHHmqb69uMeta?.name ?? "specialists",
    path: indexbgHHmqb69uMeta?.path ?? "/specialists",
    meta: indexbgHHmqb69uMeta || {},
    alias: indexbgHHmqb69uMeta?.alias || [],
    redirect: indexbgHHmqb69uMeta?.redirect || undefined,
    component: () => import("/builds/wellfinder/site-frontend/client/pages/specialists/index.vue").then(m => m.default || m)
  },
  {
    name: tariffs1p2MrUj5GuMeta?.name ?? "tariffs",
    path: tariffs1p2MrUj5GuMeta?.path ?? "/tariffs",
    meta: tariffs1p2MrUj5GuMeta || {},
    alias: tariffs1p2MrUj5GuMeta?.alias || [],
    redirect: tariffs1p2MrUj5GuMeta?.redirect || undefined,
    component: () => import("/builds/wellfinder/site-frontend/client/pages/tariffs.vue").then(m => m.default || m)
  },
  {
    name: teasersXiVy9P6fIMMeta?.name ?? "teasers",
    path: teasersXiVy9P6fIMMeta?.path ?? "/teasers",
    meta: teasersXiVy9P6fIMMeta || {},
    alias: teasersXiVy9P6fIMMeta?.alias || [],
    redirect: teasersXiVy9P6fIMMeta?.redirect || undefined,
    component: () => import("/builds/wellfinder/site-frontend/client/pages/teasers.vue").then(m => m.default || m)
  },
  {
    name: _91id_93ShzIq6cD1oMeta?.name ?? "user-chat-id",
    path: _91id_93ShzIq6cD1oMeta?.path ?? "/user/chat/:id()",
    meta: _91id_93ShzIq6cD1oMeta || {},
    alias: _91id_93ShzIq6cD1oMeta?.alias || [],
    redirect: _91id_93ShzIq6cD1oMeta?.redirect || undefined,
    component: () => import("/builds/wellfinder/site-frontend/client/pages/user/chat/[id].vue").then(m => m.default || m)
  },
  {
    name: indexY5AmxyRVbzMeta?.name ?? "user-chat",
    path: indexY5AmxyRVbzMeta?.path ?? "/user/chat",
    meta: indexY5AmxyRVbzMeta || {},
    alias: indexY5AmxyRVbzMeta?.alias || [],
    redirect: indexY5AmxyRVbzMeta?.redirect || undefined,
    component: () => import("/builds/wellfinder/site-frontend/client/pages/user/chat/index.vue").then(m => m.default || m)
  },
  {
    name: favoritej33rDUkHQ6Meta?.name ?? "user-favorite",
    path: favoritej33rDUkHQ6Meta?.path ?? "/user/favorite",
    meta: favoritej33rDUkHQ6Meta || {},
    alias: favoritej33rDUkHQ6Meta?.alias || [],
    redirect: favoritej33rDUkHQ6Meta?.redirect || undefined,
    component: () => import("/builds/wellfinder/site-frontend/client/pages/user/favorite.vue").then(m => m.default || m)
  },
  {
    name: internal_45accountpgecinQxcXMeta?.name ?? "user-internal-account",
    path: internal_45accountpgecinQxcXMeta?.path ?? "/user/internal-account",
    meta: internal_45accountpgecinQxcXMeta || {},
    alias: internal_45accountpgecinQxcXMeta?.alias || [],
    redirect: internal_45accountpgecinQxcXMeta?.redirect || undefined,
    component: () => import("/builds/wellfinder/site-frontend/client/pages/user/internal-account.vue").then(m => m.default || m)
  },
  {
    name: _91tab_93sWJB4GimHFMeta?.name ?? "user-notes-tab",
    path: _91tab_93sWJB4GimHFMeta?.path ?? "/user/notes/:tab()",
    meta: _91tab_93sWJB4GimHFMeta || {},
    alias: _91tab_93sWJB4GimHFMeta?.alias || [],
    redirect: _91tab_93sWJB4GimHFMeta?.redirect || undefined,
    component: () => import("/builds/wellfinder/site-frontend/client/pages/user/notes/[tab].vue").then(m => m.default || m)
  },
  {
    name: orders2Og0kH5I9hMeta?.name ?? "user-orders",
    path: orders2Og0kH5I9hMeta?.path ?? "/user/orders",
    meta: orders2Og0kH5I9hMeta || {},
    alias: orders2Og0kH5I9hMeta?.alias || [],
    redirect: orders2Og0kH5I9hMeta?.redirect || undefined,
    component: () => import("/builds/wellfinder/site-frontend/client/pages/user/orders.vue").then(m => m.default || m)
  },
  {
    name: profile3YZTxTJQVYMeta?.name ?? "user-profile",
    path: profile3YZTxTJQVYMeta?.path ?? "/user/profile",
    meta: profile3YZTxTJQVYMeta || {},
    alias: profile3YZTxTJQVYMeta?.alias || [],
    redirect: profile3YZTxTJQVYMeta?.redirect || undefined,
    component: () => import("/builds/wellfinder/site-frontend/client/pages/user/profile.vue").then(m => m.default || m)
  },
  {
    name: referral_45systemq1ZVqG10M1Meta?.name ?? "user-referral-system",
    path: referral_45systemq1ZVqG10M1Meta?.path ?? "/user/referral-system",
    meta: referral_45systemq1ZVqG10M1Meta || {},
    alias: referral_45systemq1ZVqG10M1Meta?.alias || [],
    redirect: referral_45systemq1ZVqG10M1Meta?.redirect || undefined,
    component: () => import("/builds/wellfinder/site-frontend/client/pages/user/referral-system.vue").then(m => m.default || m)
  },
  {
    name: reviews8II3VJ6AEiMeta?.name ?? "user-reviews",
    path: reviews8II3VJ6AEiMeta?.path ?? "/user/reviews",
    meta: reviews8II3VJ6AEiMeta || {},
    alias: reviews8II3VJ6AEiMeta?.alias || [],
    redirect: reviews8II3VJ6AEiMeta?.redirect || undefined,
    component: () => import("/builds/wellfinder/site-frontend/client/pages/user/reviews.vue").then(m => m.default || m)
  },
  {
    name: statusn8qySpZZyXMeta?.name ?? "user-status",
    path: statusn8qySpZZyXMeta?.path ?? "/user/status",
    meta: statusn8qySpZZyXMeta || {},
    alias: statusn8qySpZZyXMeta?.alias || [],
    redirect: statusn8qySpZZyXMeta?.redirect || undefined,
    component: () => import("/builds/wellfinder/site-frontend/client/pages/user/status.vue").then(m => m.default || m)
  },
  {
    name: transactionu3PIvpWk7AMeta?.name ?? "user-transaction",
    path: transactionu3PIvpWk7AMeta?.path ?? "/user/transaction",
    meta: transactionu3PIvpWk7AMeta || {},
    alias: transactionu3PIvpWk7AMeta?.alias || [],
    redirect: transactionu3PIvpWk7AMeta?.redirect || undefined,
    component: () => import("/builds/wellfinder/site-frontend/client/pages/user/transaction.vue").then(m => m.default || m)
  }
]