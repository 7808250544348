<template>
  <div class="popup__region" :class="{ 'popup__region--active': activePopup }">
    <div class="popup__container">
      <div class="popup__region--inner">
        <ul class="region__items">
          <li
            class="region__item"
            @click="clickRegion(region)"
            v-for="(region, index) in regions"
            :key="index">
            <country-flag :country="region.region" rounded size="normal" />
            <span class="region__item--text">{{ region.name }}</span>
            <span class="region__item--number">({{ region.number }})</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import CountryFlag from "vue-country-flag-next";

export default {
  props: {
    activePopup: {
      type: Boolean,
      default: false,
    },
  },
  name: "AuthPopupRegion",
  components: {
    CountryFlag,
  },
  data() {
    return {};
  },
  computed: {
    regions() {
      const popupStore = usePopup();
      return popupStore.regions;
    },
  },
  methods: {
    clickRegion(region) {
      const popupStore = usePopup();
      popupStore.chooseRegion(region)
      this.$emit("change-country", region);
    },
  },
};
</script>

<style lang="scss" scoped>
.popup__region {
  box-shadow: 0 7px 21px rgba(83, 92, 105, 0.12),
    0 -1px 6px 0 rgba(83, 92, 105, 0.6);
  position: absolute;
  top: 55px;
  border-radius: 8px;
  right: 0;
  background: white;
  padding: 10px;

  visibility: hidden;
  z-index: 1000;
}

.popup__region--active {
  visibility: visible;
}

.popup__container {
  position: relative;

  &:before {
    background-color: white;
    content: "";
    height: 15px;
    position: absolute;
    right: 38px;
    top: -17px;
    transform: rotate(45deg);
    transform-origin: 50% 50%;
    width: 15px;
  }
}

.region__items {
  list-style: none;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.popup__region--inner {
  overflow: auto;
  max-height: 250px;
  min-width: 260px;

  z-index: 1000;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 25px;
    background: $blue;
  }

  &::-webkit-scrollbar-track {
    background: rgba(192, 137, 96, 0.2);
    width: 4px;
    border-radius: 25px;
  }
}

.region__item {
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;

  margin-bottom: 5px;

  &:last-child {
    margin-bottom: 0;
  }
}
</style>
