export const doNoty = {
  success(msg) {
    if (process.client) {
      console.log("DONTY success", msg);
    }
  },
  error(msg) {
    if (process.client) {
      console.log("DONTY error", msg);
    }
  },
  warning(msg) {
    if (process.client) {
      console.log("DONTY warning", msg);
    }
  },
  info(msg) {
    if (process.client) {
      console.log("DONTY info", msg);
    }
  },
};

export default defineNuxtPlugin((nuxtApp) => {});
