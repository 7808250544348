import { setupFetch, getAxiosResponse } from "@/helpers/fetch";
import { composeUriQuery } from "@/helpers/uri-query";
import Cookie from "js-cookie";

const apiUrl = import.meta.env.VITE_API_PROXY_URL && !process.client && !/^http/.test(import.meta.env.VITE_API_URL)
    ? `${import.meta.env.VITE_API_PROXY_URL}${import.meta.env.VITE_API_URL}`
    : import.meta.env.VITE_API_URL;
const _fetch = setupFetch(`${apiUrl}/main-back/api/v1`, {
  headers: { "Content-Type": "application/json" },
});

class MainApi {
  getSalonTypes() {
    const accessToken = Cookie.get("accessToken");
    return _fetch("/salon-types", {
      method: "GET",
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
    }).then((response) => getAxiosResponse(response));
  }

  getSalonServices(id, query = {}) {
    const accessToken = Cookie.get("accessToken");
    return _fetch(`/salon-services/${id}?${composeUriQuery(query)}`, {
      method: "GET",
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
    }).then((response) => getAxiosResponse(response));
  }

  getServiceCategories(query = {}) {
    const accessToken = Cookie.get("accessToken");
    return _fetch(`/categories?${composeUriQuery(query)}`, {
      method: "GET",
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
    }).then((response) => getAxiosResponse(response));
  }

  getSalons(query = {}) {
    const accessToken = Cookie.get("accessToken");
    return _fetch(`/salons?${composeUriQuery(query)}`, {
      method: "GET",
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
    }).then((response) => getAxiosResponse(response));
  }

  getServices(query = {}) {
    const accessToken = Cookie.get("accessToken");
    return _fetch(`/services?${composeUriQuery(query)}`, {
      method: "GET",
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
    }).then((response) => getAxiosResponse(response));
  }

  getGallery(id) {
    const accessToken = Cookie.get("accessToken");
    return _fetch(`/gallery/${id}`, {
      method: "GET",
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
    }).then((response) => getAxiosResponse(response));
  }

  getSpecialistServices(query = {}) {
    const accessToken = Cookie.get("accessToken");
    return _fetch(`/specialist-services?${composeUriQuery(query)}`, {
      method: "GET",
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
    }).then((response) => getAxiosResponse(response));
  }

  getAddressFromIp(query = {}) {
    const accessToken = Cookie.get("accessToken");
    return _fetch(`/get-address-from-ip?${composeUriQuery(query)}`, {
      method: "GET",
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
    }).then((response) => getAxiosResponse(response));
  }

  getBodyParts() {
    const accessToken = Cookie.get("accessToken");
    return _fetch("/body-parts", {
      method: "GET",
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
    }).then((response) => getAxiosResponse(response));
  }

  searchSalons(query) {
    const accessToken = Cookie.get("accessToken");
    return _fetch(`/search?${composeUriQuery(query)}`, {
      method: "GET",
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
    }).then((response) => getAxiosResponse(response));
  }

  searchMapSalons(query) {
    const accessToken = Cookie.get("accessToken");
    return _fetch(`/search-map?${composeUriQuery(query)}`, {
      method: "GET",
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
    }).then((response) => getAxiosResponse(response));
  }

  searchServices(query) {
    return _fetch(`/search-service?${composeUriQuery({ ...query, is_confirmed: 1 })}`, {
      method: "GET",
      headers: {
      },
    }).then((response) => getAxiosResponse(response));
  }

  getSalonById(id) {
    const accessToken = Cookie.get("accessToken");
    return _fetch(`/salons/${id}`, {
      method: "GET",
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
    }).then((response) => getAxiosResponse(response));
  }

  getCompanyById(id) {
    const accessToken = Cookie.get("accessToken");
    return _fetch(`/companies/${id}`, {
      method: "GET",
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
    }).then((response) => getAxiosResponse(response));
  }

  createOrder(order) {
    const accessToken = Cookie.get("accessToken");
    return _fetch("/orders", {
      method: "POST",
      body: JSON.stringify(order),
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
    });
  }

  registerCompany(params) {
    return _fetch("/companies", {
      method: "POST",
      body: JSON.stringify(params),
    }).then((response) => getAxiosResponse(response));
  }

  getRates() {
    const accessToken = Cookie.get("accessToken");
    return _fetch("/rates", {
      method: "GET",
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
    }).then((response) => getAxiosResponse(response));
  }

  getSpecialists(query) {
    const accessToken = Cookie.get("accessToken");
    return _fetch(`/specialists?${composeUriQuery(query)}`, {
      method: "GET",
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
    }).then((response) => getAxiosResponse(response));
  }

  getSpecialist(specialistId) {
    const accessToken = Cookie.get("accessToken");
    return _fetch(`/specialists/${specialistId}`, {
      method: "GET",
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
    }).then((response) => getAxiosResponse(response));
  }

  getFreeTime(query) {
    const accessToken = Cookie.get("accessToken");
    return _fetch(`/specialists/free-time?${composeUriQuery(query)}`, {
      method: "GET",
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
    }).then((response) => getAxiosResponse(response));
  }

  allowOrder(query) {
    const accessToken = Cookie.get("accessToken");
    return _fetch(`/specialists/allow-order?${composeUriQuery(query)}`, {
        method: "GET",
        headers: {
          Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
        },
      }
    );
  }

  createImage(file) {
    const accessToken = Cookie.get("accessToken");
    const form = new FormData();
    form.append("file", file);

    return _fetch("/images", {
      method: "POST",
      body: JSON.stringify(form),
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
    });
  }

  getReviews(query) {
    const accessToken = Cookie.get("accessToken");
    return _fetch(`/reviews?${composeUriQuery(query)}`, {
      method: "GET",
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
    }).then((response) => getAxiosResponse(response));
  }

  createReview(body) {
    const accessToken = Cookie.get("accessToken");
    return _fetch("/reviews", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
    });
  }

  getReviewsStatistics(params) {
    const accessToken = Cookie.get("accessToken");
    return _fetch(`/reviews/statistic?${composeUriQuery(params)}`, {
      method: "GET",
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
    }).then((response) => getAxiosResponse(response));
  }

  getOrders(query) {
    const accessToken = Cookie.get("accessToken");
    return _fetch(`/orders?${composeUriQuery(query)}`, {
      method: "GET",
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
    }).then((response) => getAxiosResponse(response));;
  }

  getOrder(id) {
    const accessToken = Cookie.get("accessToken");
    return _fetch(`/orders/${id}`, {
      method: "GET",
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
    }).then((response) => getAxiosResponse(response));;
  }

  updateOrder(id, data) {
    const accessToken = Cookie.get("accessToken");
    return _fetch(`/orders/${id}`, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
    });
  }

  cancelOrder(id) {
    const accessToken = Cookie.get("accessToken");
    return _fetch(`/orders/user-cancel/${id}`, {
        method: "POST",
        body: "{}",
        headers: {
          Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
        },
      }
    );
  }

  complainOrder(id) {
    const accessToken = Cookie.get("accessToken");
    return _fetch(`/orders/complain/${id}`, {
        method: "POST",
        body: "{}",
        headers: {
          Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
        },
      }
    );
  }

  getBlogs(query = {}) {
    const accessToken = Cookie.get("accessToken");
    return _fetch(`/blogs?${composeUriQuery(query)}`, {
      method: "GET",
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
    }).then((response) => getAxiosResponse(response));
  }

  getBlog(id) {
    const accessToken = Cookie.get("accessToken");
    return _fetch(`/blogs/${id}`, {
      method: "GET",
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
    }).then((response) => getAxiosResponse(response));
  }

  getLanding(param) {
    return _fetch(`/landings/${param}`, {
      method: "GET",
    }).then((response) => getAxiosResponse(response));
  }
}

export const mainApi = new MainApi();
