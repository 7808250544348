// import cloneDeep from "lodash/cloneDeep";

export class Model {
  toJSON() {
    return {...this};
  }
  toJSON() {
    const result = Object.getOwnPropertyNames(this).reduce((a, b) => {
      if (this[b]?.toJSON) {
        a[b] = this[b].toJSON();
      } else if (Array.isArray(this[b])) {
        a[b] = this[b].map(
          (item) => {
            if (item?.toJSON) {
              return item.toJSON();
            } else {
              return item;
            }
          }
        );
      } else {
        a[b] = this[b];
      }
      return a;
    }, {});
    return result;
  }
  toString() {
    return JSON.stringify(this.toJSON());
  }
}
