<template>
  <b-modal id="review-modal" hide-header hide-footer @show="clearForm">
    <div class="modal" id="modal-reviews">
      <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="modal-reviews__header">
              <div class="modal-reviews__header-title">
                Ваш отзыв на услугу “{{
                  order.specialistService.service.title
                }}”
              </div>

              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                @click="$bvModal.hide('review-modal')"
              ></button>
            </div>

            <form class="modal-reviews__content" @submit.prevent="submit">
              <div class="modal-reviews__blocks">
                <div class="modal-reviews__block-rating">
                  <div class="modal-reviews__block-title">Ваша оценка</div>

                  <div class="modal-reviews__stars">
                    <client-only>
                      <VueStarRating
                        v-model="review.rating"
                        class="order-block__rating"
                        :increment="0.5"
                        :show-rating="false"
                        :star-size="40"
                        :max-rating="10"
                        inactive-color="#e8e9ed"
                        active-color="#f49378"
                        :padding="5"
                        :star-points="[
                          23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36,
                          34, 46, 19, 31, 17,
                        ]"
                        @rating-selected="errors.rating = ''"
                      />
                    </client-only>
                  </div>
                </div>

                <p v-if="errors.rating" class="order-block__error rating">
                  {{ errors.rating }}
                </p>

                <div class="modal-reviews__block-comment">
                  <div class="modal-reviews__block-title">Ваш комментарий</div>

                  <div class="modal-reviews__block-textarea">
                    <textarea
                      v-model="review.message"
                      name="raview-comment"
                      @input="errors.message = ''"
                    ></textarea>

                    <p v-if="errors.message" class="order-block__error">
                      {{ errors.message }}
                    </p>
                  </div>
                </div>

                <div class="modal-reviews__content-footer">
                  <button
                    type="submit"
                    class="modal-reviews__send-btn btn btn-black"
                    :disabled="isProcessing"
                  >
                    <template v-if="isProcessing"> Отправка отзыва… </template>
                    <template v-else> Оставить отзыв </template>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script setup>
import BModal from "@/components/Modals/BModal/BModal.vue";
// import VueStarRating from "vue-star-rating";
</script>
<script>
export default {
  name: "ReviewModal",

  data() {
    return {
      isProcessing: false,
      review: {
        rating: 0,
        message: "",
      },
      errors: {
        rating: "",
        message: "",
      },
    };
  },

  computed: {
    order() {
      const reviewModule = useReviewModule();
      return reviewModule.order;
    },
  },

  methods: {
    async submit() {
      if (!this.validateForm()) return;
      const { $doNoty: doNoty } = useNuxtApp();

      try {
        const reviewModule = useReviewModule();
        this.isProcessing = true;
        await reviewModule.createReview(this.review);
        doNoty.success("Отзыв успешно отправлен");
        this.$bvModal.hide("review-modal");
        this.$eventHub.$emit("add-review");
      } catch (err) {
        doNoty.error(err);
      } finally {
        this.isProcessing = false;
      }
    },

    clearForm() {
      this.isProcessing = false;
      this.review = {
        rating: 0,
        message: "",
      };
      this.errors = {
        rating: "",
        message: "",
      };
    },

    validateForm() {
      this.errors.rating = this.review.rating > 0 ? "" : "Поставьте оценку";
      this.errors.message = this.review.message === "" ? "Заполните поле" : "";

      return !this.errors.rating && !this.errors.message;
    },
  },
};
</script>

<style lang="scss">
#review-modal {
  .modal-body {
    padding: 0;
  }

  .modal {
    display: initial;
  }
}
</style>

<style lang="scss">
#modal-reviews {
  .vue-star-rating-star {
    @media (max-width: $tablet) {
      width: 28px;
      height: 28px;
    }
    stop {
      &:first-child {
        stop-color: $black;
      }
      &:last-child {
        stop-color: rgba($black, 0.3);
      }
    }
  }
}
</style>
<style lang="scss" scoped>
@import "review-modal";
</style>
