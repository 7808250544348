<template>
  <svg style="display: none">
    <symbol id="arrow_down" viewBox="0 0 7 7">
      <g>
        <g clip-path="url(#clip0)">
          <path
            d="M3.21241 6.81144C3.24452 6.86973 3.28748 6.91739 3.3376 6.95032C3.38772 6.98325 3.44349 7.00045 3.50011 7.00045C3.55673 7.00045 3.6125 6.98325 3.66261 6.95032C3.71273 6.91739 3.7557 6.86973 3.78781 6.81144L6.93781 1.12394C6.97427 1.05834 6.99565 0.981501 6.99963 0.901778C7.00361 0.822054 6.99003 0.742491 6.96038 0.671735C6.93072 0.600978 6.88612 0.541734 6.83142 0.500438C6.77672 0.459142 6.71401 0.437375 6.65011 0.437501H0.350106C0.286351 0.43783 0.223874 0.459877 0.169394 0.501272C0.114914 0.542666 0.070492 0.601842 0.0409051 0.672435C0.0113182 0.743028 -0.00231412 0.822367 0.00147427 0.90192C0.00526266 0.981474 0.0263284 1.05823 0.062406 1.12394L3.21241 6.81144Z"
            fill="#1B1918"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="7" height="7" fill="white" />
          </clipPath>
        </defs>
      </g>
    </symbol>

    <symbol id="check" viewBox="0 0 10 8">
      <g>
        <path
          d="M8.79082 0.230557L3.67208 5.98154L1.21545 2.97525C0.943112 2.64173 0.494663 2.63414 0.214556 2.95809C-0.065828 3.28237 -0.0722068 3.81548 0.200135 4.149L3.14653 7.74678C3.42026 8.07436 3.8626 8.08558 4.1477 7.77185L9.77785 1.45511C9.91458 1.30171 9.99445 1.08992 9.99972 0.866586C10.005 0.64325 9.93538 0.426512 9.80614 0.264206C9.53214 -0.0745918 9.08036 -0.0894368 8.79082 0.230557Z"
          fill="white"
        />
      </g>
    </symbol>

    <symbol id="facebook" width="39" height="39" viewBox="0 0 39 39">
      <g>
        <path
          d="M33.7443 0H5.25533C2.35295 0 0 2.35285 0 5.25533V33.7445C0 36.647 2.35285 38.9998 5.25533 38.9998H19.3059L19.3299 25.0634H15.7092C15.2387 25.0634 14.8568 24.6829 14.855 24.2124L14.8376 19.7201C14.8358 19.247 15.2188 18.8625 15.6919 18.8625H19.306V14.5218C19.306 9.48449 22.3825 6.74163 26.8762 6.74163H30.5635C31.0353 6.74163 31.4179 7.1241 31.4179 7.59597V11.3839C31.4179 11.8556 31.0356 12.2379 30.564 12.2382L28.3011 12.2393C25.8573 12.2393 25.3841 13.4005 25.3841 15.1047V18.8626H30.7539C31.2656 18.8626 31.6626 19.3094 31.6023 19.8175L31.0698 24.3098C31.0189 24.7397 30.6543 25.0636 30.2215 25.0636H25.4081L25.3841 39H33.7445C36.6469 39 38.9997 36.6471 38.9997 33.7448V5.25533C38.9996 2.35285 36.6468 0 33.7443 0Z"
          fill="#475993"
        />
      </g>
    </symbol>
    <symbol id="vk" width="40" height="40" viewBox="0 0 40 40">
      <g>
        <path
          d="M20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40Z"
          fill="#4D76A1"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19.2433 28.7746H20.813C20.813 28.7746 21.2872 28.7225 21.5293 28.4615C21.7521 28.2219 21.745 27.772 21.745 27.772C21.745 27.772 21.7143 25.6657 22.6919 25.3555C23.6556 25.05 24.893 27.3913 26.2047 28.2915C27.1965 28.9728 27.9502 28.8234 27.9502 28.8234L31.4573 28.7746C31.4573 28.7746 33.2919 28.6615 32.422 27.2191C32.3507 27.1011 31.9154 26.152 29.8144 24.2018C27.6154 22.1608 27.9099 22.4909 30.5588 18.9603C32.1721 16.8101 32.817 15.4974 32.6156 14.9352C32.4234 14.3997 31.2369 14.5413 31.2369 14.5413L27.2881 14.5659C27.2881 14.5659 26.9954 14.5259 26.7783 14.6557C26.5662 14.783 26.4296 15.0796 26.4296 15.0796C26.4296 15.0796 25.8047 16.7435 24.9711 18.1585C23.2128 21.1443 22.5097 21.3019 22.2224 21.1165C21.5539 20.6844 21.7207 19.3806 21.7207 18.4544C21.7207 15.5609 22.1596 14.3544 20.8662 14.0421C20.4369 13.9384 20.121 13.8699 19.0233 13.8589C17.6144 13.8443 16.4218 13.8632 15.7465 14.194C15.2973 14.414 14.9508 14.9042 15.1619 14.9324C15.4228 14.9673 16.0139 15.0917 16.3273 15.5185C16.732 16.0689 16.7177 17.3053 16.7177 17.3053C16.7177 17.3053 16.9502 20.7115 16.1747 21.1347C15.6424 21.4249 14.9123 20.8324 13.3447 18.1236C12.5414 16.736 11.935 15.2022 11.935 15.2022C11.935 15.2022 11.8181 14.9156 11.6095 14.7623C11.3564 14.5765 11.0027 14.5174 11.0027 14.5174L7.25034 14.542C7.25034 14.542 6.68704 14.5577 6.48026 14.8026C6.2963 15.0204 6.46565 15.4711 6.46565 15.4711C6.46565 15.4711 9.40336 22.344 12.7297 25.8076C15.7797 28.9831 19.2433 28.7746 19.2433 28.7746Z"
          fill="white"
        />
      </g>
    </symbol>

    <symbol id="google" width="40" height="40" viewBox="0 0 40 40">
      <g>
        <circle cx="20" cy="20" r="20" fill="#353C48" />
        <g clip-path="url(#clip0)">
          <path
            d="M13.8757 22.2945L13.1099 25.1533L10.3109 25.2125C9.47446 23.661 9 21.886 9 19.9996C9 18.1756 9.44361 16.4554 10.2299 14.9408H10.2305L12.7224 15.3977L13.814 17.8746C13.5855 18.5406 13.461 19.2556 13.461 19.9996C13.4611 20.8071 13.6073 21.5807 13.8757 22.2945Z"
            fill="#FBBB00"
          />
          <path
            d="M30.8077 17.9454C30.934 18.6108 30.9999 19.298 30.9999 20.0003C30.9999 20.7878 30.9171 21.556 30.7593 22.297C30.2239 24.8186 28.8247 27.0204 26.8864 28.5785L26.8858 28.5779L23.7472 28.4178L23.303 25.6448C24.5891 24.8905 25.5942 23.7101 26.1237 22.297H20.2417V17.9454H26.2095H30.8077Z"
            fill="#518EF8"
          />
          <path
            d="M26.8874 28.5756L26.888 28.5762C25.003 30.0915 22.6083 30.9981 20.0016 30.9981C15.8125 30.9981 12.1704 28.6566 10.3125 25.211L13.8772 22.293C14.8062 24.7722 17.1978 26.537 20.0016 26.537C21.2067 26.537 22.3358 26.2112 23.3046 25.6425L26.8874 28.5756Z"
            fill="#28B446"
          />
          <path
            d="M27.0218 11.5324L23.4583 14.4498C22.4556 13.8231 21.2703 13.461 20.0005 13.461C17.1333 13.461 14.6969 15.3068 13.8145 17.8749L10.2311 14.9412H10.2305C12.0612 11.4115 15.7492 9 20.0005 9C22.6695 9 25.1167 9.95073 27.0218 11.5324Z"
            fill="#F14336"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect
              width="22"
              height="22"
              fill="white"
              transform="translate(9 9)"
            />
          </clipPath>
        </defs>
      </g>
    </symbol>
  </svg>
</template>

<script>
export default {
  name: "SpriteAuth",
};
</script>

<style scoped></style>
