import { Model } from "@/stores/models/Model";
import { getRatingTitle } from "@/helpers/rating";
import { Category } from "./Category";
import noImage from "@/assets/img/no-image.png";

export class Service extends Model {
  constructor(raw = {}) {
    super();
    this.id = Number(raw.id);
    this.title = String(raw.title || "");

    this.category = new Category(raw.category);
    this.categoryId = Number(this.category.id || raw.category_id);
    this.categoryTitle = this.category.title;

    this.reviewsCount = Number(raw.review_count || 0);
    this.rating = Number(raw.rating || 0);
    this.ratingTitle = getRatingTitle(this.rating);
    this.imageSrc = noImage;

    const categoryData = raw.category?.data || {};
    this.categoryIcon = categoryData.img || "";
  }
}
