<template>
  <div v-if="isModalShown" class="push-modal">
    <div class="push-modal__inner">
      <div class="push-modal__header">
        <div class="push-modal__header-logo">
          <img src="@/assets/img/push-logo.png" alt="" />
        </div>
        <div class="push-modal__header-text">
          Хотите быть в курсе последних новостей и обновления?
        </div>
      </div>
      <div class="push-modal__footer">
        <button
          type="button"
          class="btn btn-transporent push-modal__footer-btn close"
          @click="updateCookie(false)">
          Нет, спасибо
        </button>
        <button
          type="button"
          class="btn btn-black push-modal__footer-btn"
          @click="requestNotifications">
          Разрешить
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import BModal from "@/components/Modals/BModal/BModal.vue";
import Cookie from "js-cookie";

const PUSH_COOKIE_KEY = "pushNotifications";

export default {
  name: "PushModal",

  components: {
    BModal,
  },

  data() {
    return {
      isModalShown: false,
    };
  },

  mounted() {
    const accountModule = useAccount();
    const cookie = Cookie.get(PUSH_COOKIE_KEY);

    setTimeout(() => {
      if (cookie || !accountModule.userId) return;
      this.isModalShown = true;
    }, 1000);
  },

  methods: {
    async requestNotifications() {
      const permission = await Notification.requestPermission();
      this.updateCookie(permission === "granted");
    },

    updateCookie(val) {
      Cookie.set(PUSH_COOKIE_KEY, String(val), {
        expires: 7,
      });
      this.isModalShown = false;
    },
  },
};
</script>

<style lang="scss">
#push-modal {
  .modal-body {
    padding: 0;
  }

  .modal {
    display: initial;
  }
}
</style>

<style lang="scss" scoped>
@import "push-modal";
</style>
