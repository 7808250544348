import { Model } from "@/stores/models/Model";
import { Salon } from "./Salon";
import { Specialist } from "./Specialist";
import { SpecialistService } from "./SpecialistService";
import { getImageFromSrc } from "@/helpers/images";

export class Order extends Model {
  constructor(raw = {}) {
    super();
    this.id = Number(raw.id);

    this.specialistId = Number(raw.specialist_id) || 0;
    this.specialistName = String(raw.specialist_name || "");

    this.serviceId = Number(raw.specialist_service_id || 0);
    this.serviceName = String(raw.specialist_service_name || "");
    this.serviceImageUrl = getImageFromSrc(raw.specialist_service_image);

    this.startAt = new Date(raw.start_at);
    this.finishAt = new Date(raw.finish_at);
    this.price = Number(raw.price || 0);
    this.status = String(raw.status || "");

    this.salonId = Number(raw.salon_id || 0);
    this.salon = new Salon(raw.salon);
    this.specialist = new Specialist(raw.specialist || {});

    const data = raw.data || {};
    this.reviewId = Number(data.review_id) || 0;

    this.specialistService = new SpecialistService(
      raw.specialist_service || {}
    );
  }

  get isCancelable() {
    return [
      Order.states.payedWithCancel,
      Order.states.bookedWithCancel,
    ].includes(this.status);
  }

  get isUnpaid() {
    return [Order.states.new].includes(this.status);
  }

  static get states() {
    return {
      new: "new",
      bookedWithCancel: "booked_with_cancel",
      bookedWithoutCancel: "booked_without_cancel",
      bookedCustomerPrice: "booked_customer_price",
      payedCustomerPrice: "payed_customer_price",
      payedWithoutCancel: "payed_without_cancel",
      payedWithCancel: "payed_with_cancel",
      complete: "complete",
      user: "user",
      salon: "salon",
      cancel: "cancel",
      complain: "complain",
    };
  }
}
