import { setupFetch, getAxiosResponse } from "@/helpers/fetch";
import Cookie from "js-cookie";

const apiUrl = import.meta.env.VITE_API_PROXY_URL && !process.client && !/^http/.test(import.meta.env.VITE_API_URL)
    ? `${import.meta.env.VITE_API_PROXY_URL}${import.meta.env.VITE_AUTH_API_URL}`
    : import.meta.env.VITE_AUTH_API_URL;
const _fetch = setupFetch(`${apiUrl}/api/v2`, {
  headers: { "Content-Type": "application/json" },
});

class AuthApi {
  getPublicKey() {
    const accessToken = Cookie.get("accessToken");
    return _fetch("/getPublicKey", {
      method: "POST",
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
    }).then((response) => getAxiosResponse(response));
  }

  getRecaptchaSiteKey() {
    const accessToken = Cookie.get("accessToken");
    return _fetch("/getRecaptchaSiteKey", {
      method: "POST",
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
    }).then((response) => getAxiosResponse(response));
  }

  login(params) {
    const accessToken = Cookie.get("accessToken");
    return _fetch("/login", {
      method: "POST",
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
      body: JSON.stringify(params),
    }).then((response) => getAxiosResponse(response));
  }

  logout(params) {
    const accessToken = Cookie.get("accessToken");
    return _fetch("/logout", {
      method: "POST",
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
      body: JSON.stringify(params),
    }).then((response) => getAxiosResponse(response));
  }

  regenerateTokens(params) {
    const accessToken = Cookie.get("accessToken");
    return _fetch("/regenerateTokens", {
      method: "POST",
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
      body: JSON.stringify(params),
    }).then((response) => getAxiosResponse(response));
  }

  getInfoFromInn(params) {
    const accessToken = Cookie.get("accessToken");
    return _fetch("/getLegalEntityInfo", {
      method: "POST",
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
      body: JSON.stringify(params),
    }).then((response) => getAxiosResponse(response));
  }

  getOAuthLink(params) {
    const accessToken = Cookie.get("accessToken");
    return _fetch("/getOAuthLink", {
      method: "POST",
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
      body: JSON.stringify(params),
    }).then((response) => getAxiosResponse(response));
  }

  oauthLogin(params) {
    const accessToken = Cookie.get("accessToken");
    return _fetch("/oauthLogin", {
      method: "POST",
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
      body: JSON.stringify(params),
    }).then((response) => getAxiosResponse(response));
  }

  registerClient(params) {
    const accessToken = Cookie.get("accessToken");
    return _fetch("/register", {
      method: "POST",
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
      body: JSON.stringify(params),
    }).then((response) => getAxiosResponse(response));
  }

  registerBusiness(params) {
    const accessToken = Cookie.get("accessToken");
    return _fetch("/register", {
      method: "POST",
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
      body: JSON.stringify(params),
    }).then((response) => getAxiosResponse(response));
  }

  sendConfirmCode(params) {
    const accessToken = Cookie.get("accessToken");
    return _fetch("/sendConfirmCode", {
      method: "POST",
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
      body: JSON.stringify(params),
    }).then((response) => getAxiosResponse(response));
  }

  resetPassword(params) {
    const accessToken = Cookie.get("accessToken");
    return _fetch("/resetPassword", {
      method: "POST",
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
      body: JSON.stringify(params),
    }).then((response) => getAxiosResponse(response));
  }

  updateUserInfo(params = {}) {
    return _fetch("/updateUserInfo", {
      method: "POST",
      body: JSON.stringify({
        ...params,
        accessToken: Cookie.get("accessToken"),
      }),
    });
  }

  changeLogin(params = {}) {
    return _fetch("/changeLogin", {
      method: "POST",
      body: JSON.stringify({
        ...params,
        accessToken: Cookie.get("accessToken"),
      }),
    });
  }
}

export const authApi = new AuthApi();
