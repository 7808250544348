<template>
  <div class="order-modal__step1">
    <div v-if="isOrderLoading" class="order-modal__spinner">
      <SemipolarSpinner :animation-duration="2000" :size="50" />
    </div>

    <template v-else>
      <div class="order-modal__step1-header">
        <div class="order-modal__title">Бронирование</div>
        <div v-if="order.serviceTitle" class="modal-header__subtitle">
          «{{ order.serviceTitle }}»
        </div>
      </div>

      <div class="order-modal__data-block order-modal__step1-block active">
        <DateTimePicker
          v-model="selectedDate"
          :slots="timeSlots"
          :loading="isLoading"
          @input="selectDate"
        >
          <template #title>
            <div class="order-modal__subtitle">Выберите дату и время</div>
          </template>
        </DateTimePicker>

        <p v-if="errors.date || !isDateValid" class="order-modal__error">
          <template v-if="!isDateValid">
            Пожалуйста, выберите другое время
          </template>
          <template v-else>Пожалуйста, выберите время</template>
        </p>
      </div>

      <div
        v-if="!specialistSelected"
        class="order-modal__specialist-block order-modal__step1-block"
      >
        <div class="order-modal__subtitle">Выберите специалиста</div>
        <div class="order-modal__specialist-blockInner custom-scroll">
          <div class="row">
            <client-only>
              <swiper
                v-if="categorySpecialists.length"
                v-bind="swiperOption"
                :modules="swiperModules"
                ref="specialistSlider"
                class="order-modal__specialist-swiper"
                :class="{
                  'order-modal__specialist-swiper--no-arrows':
                    showSpecialistSliderArrows,
                }">
                  <swiper-slide
                    v-for="specialist in categorySpecialists"
                    :key="specialist.id">
                    <SpecialistCard
                      class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4"
                      :specialist="specialist"
                      :category-id="order.categoryId"
                      :service-id="order.serviceId"
                      :selected="order.specialistId === specialist.id"
                      hide-booling
                      is-small
                      @click="selectSpecialist(specialist.id)" />
                  </swiper-slide>
                <button class="order-modal__specialist-button order-modal__specialist-button-next"></button>
                <button class="order-modal__specialist-button order-modal__specialist-button-prev"></button>
              </swiper>
            </client-only>
            <p v-if="!categorySpecialists.length" class="order-modal__stub">Нет специалистов</p>
          </div>
        </div>

        <p v-if="errors.specialistId" class="order-modal__error">
          Пожалуйста, выберите специалиста
        </p>
      </div>

      <SelectService v-if="!serviceSelected" />

      <button
        type="button"
        class="order-modal__step1-btnNext"
        :disabled="isDateChecking"
        @click="onNextClick"
      >
        <template v-if="isDateChecking"> Проверка расписания… </template>
        <template v-else> Далее </template>
      </button>
    </template>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { SemipolarSpinner } from "epic-spinners";
import DateTimePicker from "@/components/Common/DateTimePicker.vue";
import SpecialistCard from "./components/SpecialistCard.vue";
import SelectService from "./components/SelectService";

import get from "lodash/get";
import { getDayStartDate } from "@/helpers/date";

export default {
  name: "SelectStep",
  components: {
    Swiper,
    SwiperSlide,
    SemipolarSpinner,
    DateTimePicker,
    SpecialistCard,
    SelectService,
  },

  props: {
    specialistSelected: { type: Boolean, default: false },
    serviceSelected: { type: Boolean, default: false },
  },

  data() {
    return {
      isLoading: false,
      isDateChecking: false,
      selectedDate: getDayStartDate(),
      disabledDates: [],
      isDateValid: true,
      errors: {
        date: false,
        specialistId: false,
      },
      swiperOption: {
        slidesPerView: 4,
        spaceBetween: 15,
        loop: false,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: ".order-modal__specialist-button-next",
          prevEl: ".order-modal__specialist-button-prev",
        },
        breakpoints: {
          0: {
            slidesPerView: 2,
          },
          375: {
            slidesPerView: 3,
          },
          500: {
            slidesPerView: 4,
          },
        },
      },
      swiperModules: [Navigation],
    };
  },

  computed: {
    orderModule() {
      return useOrder();
    },
    order() {
      return this.orderModule.order;
    },

    isOrderLoading() {
      return this.orderModule.isLoading;
    },

    service() {
      return this.orderModule.selectedService || null;
    },

    timeSlots() {
      return this.orderModule.timeSlots;
    },

    categorySpecialists() {
      return this.orderModule.categorySpecialists;
    },

    categorySpecialistsCount() {
      return this.categorySpecialists.length;
    },

    showSpecialistSliderArrows() {
      if (!this.$refs.specialistSlider) return true;
      const breakpoint = this.$refs.specialistSlider.swiper.currentBreakpoint;
      return (
        this.categorySpecialists.length >
        this.swiperOption.breakpoints[breakpoint].slidesPerView
      );
    },
  },

  watch: {
    categorySpecialistsCount: {
      handler(value) {
        if (
          value === 1 &&
          this.order.specialistId !== this.categorySpecialists[0].id
        ) {
          this.selectSpecialist(this.categorySpecialists[0].id);
        }
      },
      immediate: true,
    },

    "order.specialistId"() {
      this.loadSchedule();
    },
  },

  methods: {
    selectDate(val) {
      const date = new Date(val);
      this.selectedDate = date;

      if (date.getHours() === 0 && date.getMinutes() === 0) {
        this.orderModule.updateOrder({ date: null });
        this.loadSchedule();
        return;
      }

      this.orderModule.updateOrder({ date: val });
      this.errors.date = "";
      this.isDateValid = true;
    },

    selectSpecialist(id) {
      if (this.orderModule.selectedSpecialist?.id === id) {
        this.orderModule.updateOrder({
          specialistId: 0,
        });
        return;
      }
      this.orderModule.updateOrder({
        specialistId: id,
      });
      this.errors.specialistId = false;
    },

    async loadSchedule() {
      this.isLoading = true;
      await this.orderModule.loadSchedule(this.selectedDate);
      this.isLoading = false;
    },

    async onNextClick() {
      if (!this.validateForm()) return;

      this.isDateChecking = true;
      const startDate = new Date(this.order.date);
      const endDate = new Date(startDate);
      endDate.setTime(endDate.getTime() + this.service.duration * 60 * 1000);

      try {
        if (
          this.orderModule.isSpecialistBusy(
            this.orderModule.selectedSpecialist.id,
            this.orderModule.selectedSpecialist.userId
          )
        ) {
          throw "Специалист занят";
        }
        if (!(await this.orderModule.isOrderAllowed())) {
          throw "Выбранное время заказа недоступно";
        }

        await this.orderModule.createOrder();
        this.$emit("next-click");
      } catch (err) {
        const { $doNoty: doNoty } = useNuxtApp();
        const errors = get(err, "response.data.errors", {});
        const error = get(Object.values(errors), "[0][0]");
        doNoty.error(error || err);
        this.isDateValid = false;
      } finally {
        this.isDateChecking = false;
      }
    },

    validateForm() {
      this.errors.date = this.order.date === null;
      this.errors.specialistId = this.order.specialistId === 0;

      return !this.errors.date && !this.errors.specialistId && this.isDateValid;
    },

    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");

      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
  },
};
</script>

<style lang="scss">
.order-modal {
  &__error {
    font-weight: 500;
    font-size: 16px;
    margin-top: 15px;
    color: $red;
  }

  &__stub {
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }

  &__data-block {
    opacity: 0.4;
    pointer-events: none;

    &.active {
      opacity: 1;
      pointer-events: initial;
    }
  }

  &__spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding: 100px 0;
  }

  &__specialist-swiper--no-arrows .order-modal__specialist-button {
    display: none;
  }
}
</style>
