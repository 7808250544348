import { getPercentFromNumber } from "@/helpers/percents.js";

import { paymentsApi } from "@/stores/api/payments-api";

import { useAccount } from "./account";

import filter from "lodash/filter";
import Cookies from "js-cookie";

export const usePayment = defineStore("payment", {
  state: () => ({
    isLoading: false,
    amount: 0,
    capture: false,
    metadata: {},
    description: "",
    redirect: "",
    transferId: "",
    onSuccess: () => {},
    isSaved: false,
    paymentId: "",

    savedCards: [],

    confirmationToken: "",

    card: {
      number: "",
      expiryYear: "",
      expiryMonth: "",
      csc: "",
      cardholder: "",
    },
  }),

  actions: {
    updateCard(card) {
      this.card = card;
    },

    async init({
      amount,
      metadata,
      description,
      redirect,
      transferId,
      isSaved = false,
      paymentId = "",
      topUpBalance = false,
      capture = false,
      linkCard = false,
    }) {
      this.amount = amount;
      this.description = description;
      this.metadata = metadata;
      this.redirect = redirect;
      this.transferId = transferId;
      this.isSaved = isSaved;
      this.paymentId = paymentId;
      this.topUpBalance = topUpBalance;
      this.capture = capture;
      this.linkCard = linkCard;

      await this.createPayment();
    },

    async createPayment() {
      const amount = {
        value: this.amount.toFixed(2),
        currency: "RUB",
      };

      const options = {
        amount,
        metadata: this.metadata,
        capture: this.capture,
      };

      const comission = getPercentFromNumber(this.amount, import.meta.env.VITE_SERVICE_COMMISSION);

      if (this.transferId) {
        options.transfers = [
          {
            account_id: this.transferId,
            amount,
            platform_fee_amount: {
              value: comission.toFixed(2),
              currency: amount.currency,
            },
          },
        ];
      }

      if (this.paymentId) {
        options.payment_method_id = this.paymentId;
      } else {
        options.confirmation = { type: "embedded" };
      }

      options.save_payment_method = this.isSaved;

      const { data } = !this.topUpBalance
        ? await paymentsApi.createPayment(options)
        : await paymentsApi.topUpBalance({
            access_token: Cookies.get("accessToken"),
            amount: this.amount,
            currency: amount.currency,
            return_url: this.redirect,
          });

      if (!data.ok) throw new Error(data.message || data.response.description);

      if (data.confirmation_url) {
        location.href = data.confirmation_url;
        return;
      }
      this.confirmationToken = data.response.confirmation?.confirmation_token;
    },

    async loadSavedCards() {
      try {
        const { data } = await paymentsApi.getSavedCards();
        this.savedCards = filter(data.cards, { confirmed: true });
      } catch (err) {
        const { $doNoty: doNoty } = useNuxtApp();
        doNoty.error(err);
      }
    },

    async linkCard() {
      const accountModule = useAccount();
      await this.init({
        amount: 1,
        description: "Save card payment",
        metadata: {
          user_id: accountModule.userId,
        },
        isSaved: true,
        capture: false,
        linkCard: true,
      });
    },

    reset() {
      this.isLoading = false;
      this.amount = 0;
      this.metadata = {};
      this.redirect = "";
      this.transferId = "";
      this.description = "";
      this.confirmationToken = "";
      this.topUpBalance = false;
      this.capture = false;
      this.linkCard = false;
    },
  },
});
