import { watch, ref, reactive } from "vue";

const inited = ref(false);
const mqConfig = reactive({
  mobile: 0,
  mobileLandscape: 426,
  tablet: 1025,
});

const mq = reactive({
  mobile: false,
  mobileLandscape: false,
  tablet: false,
});

function onResize() {
  const keys = Object.keys(mqConfig).reverse();
  for (const key of keys) {
    mq[key] = window.innerWidth >= mqConfig[key];
  }
}

export function init() {
  if (process.client && !inited.value) {
    window.addEventListener("resize", onResize);
    onResize();
    inited.value = true;
  }
}

export function useMediaQuery() {
  const isMobile = ref(true);
  const isTabletPlus = ref(false);

  watch(mq, () => {
    isMobile.value = mq.mobile && !mq.mobileLandscape;
    isTabletPlus.value = mq.tablet;
  }, { immediate: true, deep: true });

  return {
    isMobile,
    isTabletPlus,
  };
}


export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook("vue:setup", () => {
    init();
  });
});
