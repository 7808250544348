<template>
  <b-modal
    id="recovery-password-modal"
    hide-footer>
    <template #modal-header>
      <div class="recovery-password-modal__title">
        <span>Восстановление пароля</span>
        <img src="@/assets/img/logo-black.svg" />
      </div>
      <button
        @click="$bvModal.hide('recovery-password-modal')"
        class="recovery-password-modal__close"></button>
    </template>
    <div class="recovery-password-modal">
      <SpriteAuth />
      <div v-if="isLoading" class="recovery-password-modal__loader">
        <semipolar-spinner
          :animation-duration="2000"
          :size="50"
          style="
            position: absolute;
            left: calc(50% - 25px);
            top: calc(50% - 25px);
          " />
      </div>
      <RecoveryPasswordForm
        @onRecovery="onRecovery"
        @changeLoading="isLoading=$event" />
    </div>
  </b-modal>
</template>

<script>
import BModal from "@/components/Modals/BModal/BModal.vue";
import { SemipolarSpinner } from "epic-spinners";
import RecoveryPasswordForm from "@/components/RecoveryPassword/RecoveryPasswordForm/RecoveryPasswordForm";
import SpriteAuth from "@/components/Auth/SpriteAuth/SpriteAuth";

export default {
  components: {
    BModal,
    RecoveryPasswordForm,
    SpriteAuth,
    SemipolarSpinner,
  },

  data: () => ({
    isLoading: false,
  }),

  methods: {
    onRecovery() {
      this.$bvModal.hide("recovery-password-modal");
      this.$bvModal.show("auth-modal");
    },
  },
};
</script>

<style lang="scss">
@import "recovery-password-modal.scss";
</style>
