export const ru = {
  "and": "и",
  "titles": {
    "home": "Главная страница",
    "profile": "Личный кабинет",
    "login": "Авторизация",
    "clientRegister": "Регистрация",
    "bussinesRegister": "Регистрация новой клиники",
    "recoveryPassword": "Изменить пароль",
    "search": "Поиск объекта",
    "salon": "Страница салона",
    "notes": "Уведомления",
    "media": "Медиа",
    "profileСhat": "Чат",
    "orders": "Заказы",
    "about": "О нас",
    "favorite": "Избранное",
    "referral": "Реферальная система",
    "cashback": "Кэшбэк",
    "services": "Услуги",
    "transaction": "Транзакции",
    "reviews": "Отзывы",
    "internal": "Бонусы",
    "status": "Ваш статус",
    "tariff": "Тарифы",
    "teasers": "Тизеры",
    "specialists": "Специалисты",
    "404": "404 страница",
    "terms": "Условия пользования",
    "privacy": "Политика конфиденциалиьности",
    "tariffs": "Тарифы",
    "contacts": "Контакты",
    "publicOffer": "Публичная оферта",
    "blog": "Блог"
  },
  "helpers": {
    "notAuth": "Вы не авторизованы"
  },
  "auth": {
    "password": "Пароль",
    "sendCode": "Отправить",
    "sendCodeAgain": "Отправить ещё",
    "clientRegister": "Регистрация клиента",
    "bussinesRegister": "Регистрация клиники",
    "recoverPassword": "Востановление пароля",
    "inn": "ИНН",
    "work_from_home": "Работаю на дому",
    "registrationLink": "Еще нет аккаунта?",
    "authLink": "Уже есть аккаунт?",
    "succees_register": "Регистрация прошла успешно. Пожалуйста авторизуйтесь под своими данными.",
    "success_register_company": "Регистрация прошла успешно. Ждите подтверждения",
    "success_auth": "Авторизация прошла успешно.",
    "repeatPassword": "Повторить пароль",
    "loginOn": "Авторизация на",
    "nextButton": "Далее",
    "fogotPassword": "Забыли пароль",
    "enterMailOrPhone": "Введите номер телефона или e-mail",
    "enterPhone": "Введите номер телефона",
    "yourName": "Ваше имя",
    "name": "Имя",
    "yourSurname": "Ваша фамилия",
    "rememberMe": "Запомнить меня",
    "enterBy": "Или войдите через",
    "phone": "Телефон",
    "wrongInn": "Неверно указан ИНН",
    "validInn": "ИНН действителен",
    "youDataAgreed": "Даю согласие на обработку данных третьими лицами",
    "useRules": "Правилами пользования",
    "confidentPolicy": "Политикой конфиденциальности",
    "agreeWith": "Согласен с",
    "createAccount": "Зарегистрироваться",
    "createBusiness": "Добавить обьект",
    "registerOn": "Регистрация на",
    "requiredToFill": "Обязательно к заполнению",
    "passwordsNotSame": "Пароли не совпадают",
    "getRecapcha": "Пройдите captcha",
    "agreedConditions": "Примите условие",
    "clinicRegister": "Регистрация объекта на",
    "addObject": "Добавить объект",
    "emailRequired": "Обязательно к заполнению. Подтвердите почту",
    "companyName": "Название компании",
    "acceptPhone": "Подтвердите ваш номер телефона",
    "acceptEmail": "Подтвердите ваш email",
    "phoneWrong": "Неправильный формат телефона",
    "phoneEmail": "Неправильный формат почты",
    "pinLength": "Пин код должен быть 6 символов",
    "onlyNumber": "Только цифры",
    "recoveryPass": "Востановление пароля",
    "recoveryBtn": "Востановить",
    "requiredField": "Поле обязательно к заполнению",
    "oneOfThemFieldIsRequired": "Одно из полей телефон или e-mail обязательно к заполнения",
    "confirmPhone": "Подтвердите телефон",
    "confirmEmail": "Подтвердите почту",
    "notAuth": "Вы не авторизованы",
    "passChanged": "Пароль успешно изменён",
    "dataChanged": "Данные успешно изменены"
  },
  "search": {
    "finded_1": "найден",
    "finded_2": "найдено",
    "variants_1": "вариант",
    "variants_2": "варианта",
    "variants_3": "вариантов"
  },
  "countries": {
    "russia": "Россия",
    "usa": "Сша",
    "germany": "Германия",
    "ukraine": "Украина",
    "mexico": "Мексика",
    "brazil": "Бразилия",
    "india": "Индия",
    "china": "Китай",
    "grateBritan": "Великобритания",
    "kazahstan": "Казахстан",
    "belarus": "Беларусь",
    "france": "Франция",
    "poland": "Польша",
    "turkey": "Турция",
    "australia": "Австралия",
    "austria": "Австрия",
    "azeibardzan": "Азейбарджан",
    "albania": "Албания",
    "usaIsland": "Американские Вергинские острова",
    "angilia": "Ангилья",
    "angola": "Ангола",
    "andora": "Андора",
    "antigua": "Антигуа и Барбуда",
    "aoe": "Арабские Эмираты",
    "argentina": "Аргентина",
    "armenia": "Армения",
    "aruba": "Аруба",
    "afganistan": "Афганистан",
    "bagams": "Багамские острова",
    "bangladesh": "Бангладеш",
    "barbados": "Барбадос",
    "bahrein": "Бахрейн",
    "beliz": "Белиз",
    "belgia": "Бельгия",
    "benin": "Бенин",
    "bermuds": "Бермудские острова",
    "bolgaria": "Болгария",
    "bolivia": "Боливия",
    "bosnia": "Босния и Герцеговина",
    "botsvana": "Ботсвана",
    "britishIsland": "Британские Вергинские сотрова",
    "brunei": "Бруней",
    "burkinaFaso": "Буркина Фасо",
    "burundi": "Бурунди",
    "butan": "Бутан",
    "vanuatu": "Вануату",
    "british": "Великобритания",
    "vengria": "Венгрия",
    "venesuela": "Венесуэлла",
    "timor": "Восточный Тимор",
    "vietnam": "Вьетнам",
    "gabon": "Габон",
    "gaiti": "Гаити",
    "gambia": "Гамбия",
    "gana": "Гана",
    "gvadelupa": "Гваделупа",
    "gvatemala": "Гватемала",
    "gvinea": "Гвинея",
    "gvineaBissau": "Гвинея-Биссау",
    "gibraltar": "Гибралтар",
    "grenada": "Гренада",
    "grenlandia": "Гренландия",
    "greece": "Греция",
    "gruzia": "Грузия",
    "guana": "Гуана",
    "dania": "Дания",
    "djibury": "Джибути",
    "dominikana": "Доминикана",
    "egypt": "Египет",
    "zambia": "Замбия",
    "eastSahara": "Западная Сахара",
    "zimbabve": "Зимбабве",
    "izrail": "Израиль",
    "indonesia": "Индонезия",
    "iordania": "Иордания",
    "irak": "Ирак",
    "iran": "Иран",
    "irlandia": "Ирландия",
    "islandia": "Исландия",
    "spain": "Испания",
    "italy": "Италия",
    "iemen": "Йемен",
    "kaimansieIsland": "Каймановы остарова",
    "kamerun": "Камерун",
    "canada": "Канада",
    "kaboVerde": "Кабо-Верде",
    "kamboja": "Камбоджа",
    "katar": "Катар",
    "kenia": "Кения",
    "kipr": "Кипр",
    "kirgizia": "Киргизия",
    "kiribati": "Кирибати",
    "kokoIsland": "Кокосовые острова",
    "kolumbia": "Колумбия",
    "komoros": "Коморос",
    "kongo": "Конго",
    "eastKorea": "Корея (Северная)",
    "westKorea": "Корея (Южная)",
    "kostaRika": "Коста Рика",
    "tokDivuar": "Кот-Д`ивуар",
    "kuba": "Куба",
    "kyveit": "Кувейт",
    "laos": "Лаос",
    "latvia": "Латвия",
    "lesoto": "Лесото",
    "liberia": "Либерия",
    "livan": "Ливан",
    "livia": "Ливия",
    "litva": "Литва",
    "lihtenstain": "Лихтенштейн",
    "luxemburg": "Люксембург",
    "mavrikiy": "Маврикий",
    "mavritania": "Мавритания",
    "madagaskar": "Мадагаскар",
    "maiota": "Майотта",
    "makao": "Макао",
    "makedonia": "Македония",
    "malavi": "Малави",
    "malazia": "Малайзия",
    "mali": "Мали",
    "maldivu": "Мальдивы",
    "malta": "Мальта",
    "martinika": "Мартиника",
    "marshalIsland": "Маршалские острова",
    "mikronezia": "Микронезия",
    "mozambik": "Мозамбик",
    "moldavia": "Молдавия",
    "monako": "Монако",
    "mongolia": "Монголия",
    "moroko": "Мороко"
  },
  "statuses": {
    "classic": "Классик",
    "silver": "Серебрянный",
    "gold": "Золотой",
    "platinum": "Платиновый"
  },
  "footer": {
    "copyright": "Webberu.ru. Все права защищены"
  },
  "review": {
    "count": "{n} отзывов | {n} отзыв | {n} отзыва | {count} отзывов"
  },
  "years": {
    "count": "{n} лет | {n} год | {n} года | {count} лет"
  },
  "notes": {
    "new": "Новый",
    "booked_with_cancel": "Забронирован с возможностью отмены",
    "booked_without_cancel": "Забронирован",
    "booked_customer_price": "Забронирован",
    "payed_customer_price": "Оплачен",
    "payed_without_cancel": "Оплачен",
    "payed_with_cancel": "Оплачен с возможностью отмены",
    "complete": "Завершен",
    "user-cancel": "Отменен пользователем",
    "salon-cancel": "Отменен салоном",
    "cancel": "Отменен",
    "complain": "Жалоба"
  },
  "chat": {
    "chat_accepted": "{user} присоединился к чату",
    "user_left_chat": "{user} покинул чат",
    "new_chat": "{user} начал с вами новый чат",
    "new_message": "{user} написал новое сообщение",
    "offline": "Вы сейчас оффлайн. Идёт подключение...",
    "start_chat": "Написать специалисту"
  }
};
