import noImage from "@/assets/img/no-image.png";

export function getImageFromSrc(src, returnEmpty = false, addStorageToUrl = false) {
  if (src) {
    if (!src.match(/main-back\//)) {
      if (addStorageToUrl && !src.match(/storage\//)) {
        src = `/storage/${src}`;
      }
      src = `/main-back/${src}`;
      src = src.replace(/[a-zа-я0-9]\/\//gi, (match) => match.replace(/\/\//g, "/")).replace(/\/\//g, "/");
    }
    return src.match(/^http/)
      ? src
      : `${import.meta.env.VITE_API_URL || ""}/${src}`;
  } else {
    return returnEmpty
      ? ""
      : noImage;
  }
}
