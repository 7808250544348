import { COUNTRY_FLAGS } from "@/helpers/country-flags";

export const usePopup = defineStore("popup", {
  state() {
    return {
      activeRegionPopup: false,
      regions: COUNTRY_FLAGS,
      activeRegion: {
        region: "ru",
        name: "Россия",
        number: "",
      },
    };
  },

  actions: {
    openPopup() {
      this.activeRegionPopup = this.activeRegionPopup !== true;
    },
    chooseRegion(region) {
      this.activeRegion = region;
      this.activeRegionPopup = false;
    },
  },
});
