<template>
  <div
    class="specialists-inner__block"
    :class="{
      'specialists-inner__block--selected': selected,
      'specialists-inner__block--small': isSmall,
    }"
  >
    <div class="specialists-inner__block-inner">
      <div v-if="isScheduleLoading" class="order-modal__specialist-busy">
        <div class="specialist-busy__text">
          <semipolar-spinner
            :animation-duration="2000"
            :size="30"
            color="#B2B8D4"
          />
        </div>
      </div>
      <div v-else-if="isBusy" class="order-modal__specialist-busy">
        <div class="specialist-busy__text">
          На данное время специалист занят
        </div>
      </div>
      <a class="specialists-inner__block-link d-block" @click="$emit('click')">
        <div class="specialists-inner__block-img">
          <img :src="specialist.avatarSrc" alt="" />
        </div>

        <div class="specialists-inner__block-content">
          <div class="specialists-inner__block-name">
            {{ specialist.name }}
          </div>

          <div class="specialists-inner__block-rating d-flex">
            <div class="specialists-inner__block-ball">
              {{ specialist.rating }}
            </div>

            <div class="specialists-inner__block-reviews">
              {{ $tc("review.count", specialist.reviewsCount) }}
            </div>
          </div>

          <div class="specialists-inner__block-experience d-flex">
            <div class="specialists-inner__block-ball">
              {{ $tc("years.count", specialist.workExp) }}
            </div>

            <div class="specialists-inner__block-reviews">Стажа</div>
          </div>

          <div class="specialists-inner__block-footer">
            <template v-if="service.priceWithDiscount">
              <span class="specialists-inner__block-price old">
                от {{ formatMoney(service.price) }}
              </span>

              <span class="specialists-inner__block-price new">
                От {{ formatMoney(service.priceWithDiscount) }}
              </span>
            </template>
            <span v-else class="specialists-inner__block-price new">
              От {{ formatMoney(service.price) }}
            </span>

            <span class="specialists-inner__block-note text-grey">
              ({{ specialist.jobTitle }})
            </span>
          </div>
        </div>
      </a>

      <button
        v-if="!hideBooling"
        type="button"
        class="btn specialists-inner__block-btn btn-border"
        @click="$emit('book-click')"
      >
        Забронировать
      </button>
    </div>
  </div>
</template>

<script setup>
import { formatMoney } from "@/helpers/formatters";
</script>
<script>
import { Specialist } from "@/stores/models/Specialist";
import { SemipolarSpinner } from "epic-spinners";

export default {
  name: "SpecialistCard",

  props: {
    specialist: { type: Specialist, required: true },
    categoryId: { type: Number, required: true },
    serviceId: { type: Number, default: 0 },
    hideBooling: { type: Boolean, default: false },
    selected: { type: Boolean, default: false },
    isSmall: { type: Boolean, default: false },
  },

  components: {
    SemipolarSpinner,
  },

  computed: {
    service() {
      return (
        this.specialist.services.find((item) =>
          this.serviceId
            ? item.serviceId === this.serviceId
            : item.categoryId === this.categoryId
        ) || {}
      );
    },

    isBusy() {
      const orderModule = useOrder();
      return orderModule.isSpecialistBusy(
        this.specialist.id,
        this.specialist.userId
      );
    },
    isScheduleLoading() {
      const orderModule = useOrder();
      return orderModule.isScheduleLoading;
    },
  },
};
</script>

<style lang="scss" scoped>
.swiper-slide .specialists-inner__block {
  width: 100%;
}
.specialists-inner__block {
  padding: 15px;
  margin: 0;
  height: 100%;
  border-radius: 12px;

  &:hover {
    background: rgba($lightgrey3, 0.2);
    transition: 0.5s;

    .specialists-inner__block-btn {
      background: $white;
      color: $black;
      border: 1px solid transparent;
    }
  }

  &--selected,
  &--selected:hover {
    background: rgba($black, 0.1);
  }

  &-link {
    text-decoration: none;
    color: $black;
    cursor: pointer;
  }

  &-img {
    margin-bottom: 15px;

    @media (max-width: 767px) {
      margin-bottom: 10px;
    }

    img {
      border-radius: 3px;
    }
  }

  &-name {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 15px;

    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 1;
      margin-bottom: 5px;
    }
  }

  &-ball {
    color: $orange;
    letter-spacing: -0.025em;
    font-weight: 600;
    font-size: 18px;
    margin-right: 4px;

    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 17px;
    }
  }

  &-reviews {
    letter-spacing: 0.01em;
    opacity: 0.5;

    @media (max-width: 767px) {
      font-size: 10px;
      line-height: 15px;
    }
  }

  &-rating {
    margin-bottom: 10px;

    @media (max-width: 767px) {
      margin-bottom: 5px;
    }
  }

  &-price {
    font-size: 16px;
    font-weight: 500;

    &.old {
      text-decoration: line-through;
      opacity: 0.5;
      font-size: 14px;
    }

    @media (max-width: 767px) {
      font-size: 11px;
      line-height: 11px;
    }
  }

  &-note {
    font-size: 16px;
    opacity: 0.5;
    margin-left: 4px;

    @media (max-width: 767px) {
      font-size: 11px;
      line-height: 11px;
    }
  }

  &-btn {
    margin-top: 25px;
    border-radius: 47px;
    font-size: 18px;
    font-weight: 500;
    width: 100%;

    @media (max-width: 767px) {
      font-size: 13px;
      margin-top: 15px;
    }
  }
}
.specialists-inner__block--small {
  .specialists-inner__block-inner {
    position: relative;
  }
  .specialists-inner__block-img {
    max-width: 75px;
    max-height: 75px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto 8px;
    img {
      object-fit: cover;
    }
  }
  .specialists-inner__block-content {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .specialists-inner__block-name {
    order: 2;
    margin-bottom: 5px;
    text-align: left;
  }
  .specialists-inner__block-footer {
    order: 1;
    margin-bottom: 5px;
    display: flex;
    white-space: nowrap;
  }
  .specialists-inner__block-rating {
    width: fit-content;
    margin-bottom: 5px;
    align-items: center;
    order: 3;
  }
  .specialists-inner__block-experience {
    align-items: center;
    order: 4;
    .specialists-inner__block-ball {
      color: $lightgrey3;
    }
  }
  .specialists-inner__block-note {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    flex-shrink: 1;
  }
  .order-modal__specialist-busy {
    position: absolute;
    cursor: default;
    z-index: 2;
    height: 100%;
    width: 100%;
  }
  .specialist-busy__text {
    min-height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba($color: $white, $alpha: 0.85);
    border-radius: 12px;
    color: #1d274a;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    padding: 0 5px;
  }
}

@media (max-width: 767px) {
  .specialists-inner__block {
    &-footer {
      line-height: 1;
    }
  }
}
</style>
