import { Order } from "@/stores/models/Order";
import { mainApi } from "@/stores/api/main-api";
import { useAccount } from "./account";

export const useReviewModule = defineStore("reviewModule", {
  state: () => ({
    order: new Order(),
  }),

  actions: {
    setOrder(order) {
      this.order = order;
    },
    async createReview({ rating, message }) {
      try {
        const accountModule = useAccount();
        const { data } = await mainApi.createReview({
          user_id: accountModule.userId,
          salon_id: this.order.salon.id,
          rating,
          body: message,
          data: {
            name: accountModule.fullName,
            avatar_src: accountModule.userInfo.avatar || "",
          },
        });
        return data;
      } catch (err) {
        const { $doNoty: doNoty } = useNuxtApp();
        doNoty.error(err);
      }
    },
  },
});
