import merge from "lodash/merge";

const isProd = import.meta.env.NODE_ENV === "production";
const isServer = typeof window === "undefined";
export const setupFetch = (baseUrl, globalOptions) => {
  return (url, options) => {
    if (!isProd && !isServer) {
      baseUrl = baseUrl.replace(import.meta.env.VITE_API_PROXY_URL, "");
    }
    return fetch(`${baseUrl}${url}`, merge({}, globalOptions, options))
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
        return response;
      });
  };
};

export async function getAxiosResponse(response) {
  try {
    const data = await response.json();
    return {
    ...response,
    data,
  };
  } catch (err) {
    return {
      ...response,
      data: null,
      err,
    };
  }
}
