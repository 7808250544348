<template>
  <div class="date-time-picker">
    <div class="date-time-picker__title text-center">
      <slot name="title"> Выберите дату и время </slot>
    </div>

    <div class="date-time-picker__picker">
      <vue-date-picker
        v-model="selectedDate"
        ref="datepicker"
        class="date-time-picker__datepicker"
        format="dd.MM.yyyy HH:mm"
        inline
        auto-apply
        :enable-time-picker="false"
        locale="ru"
        month-name-format="long"
        :month-change-on-scroll="false"
        :disabled-dates="disableDate"
        open></vue-date-picker>
      <div class="date-time-picker__timepicker">
        <div class="date-time-picker__timepicker-title">Ближайшее время</div>

        <div class="date-time-picker__time-slots">
          <template v-if="loading">
            <div
              v-for="i in 16"
              :key="i"
              class="date-time-picker__time-slot loading">
              &nbsp;
            </div>
          </template>

          <template v-else-if="(slots || timeSlots).length > 0">
            <div
              v-for="slot in slots || timeSlots"
              :key="slot"
              class="date-time-picker__time-slot"
              :class="{
                'date-time-picker__time-slot_active': slot === timeSlot,
              }"
              @click="setTimeSlot(slot)">
              {{ slot }}
            </div>
          </template>

          <p v-else>Нет доступного времени на эту дату</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import { getDayStartDate } from "@/helpers/date";

export default {
  name: "DateTimePicker",
  components: {
    VueDatePicker,
  },

  props: {
    modelValue: {
      type: [Date, String],
      required: true,
    },
    slots: { type: Array, default: null },
    loading: { type: Boolean, default: false },
    interval: { type: Number, default: 30 },
  },

  computed: {
    selectedDate: {
      get() {
        return this.modelValue || getDayStartDate();
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },

    timeSlot() {
      return this.formatTimeSlot(
        this.selectedDate.getHours(),
        this.selectedDate.getMinutes()
      );
    },

    timeSlots() {
      const today = new Date().getDate();
      const date = new Date();
      const slots = [];
      date.setHours(0, 0, 0);

      while (date.getDate() === today) {
        const hours = date.getHours();
        const minutes = date.getMinutes();
        slots.push(this.formatTimeSlot(hours, minutes));

        date.setMinutes(minutes + this.interval);
      }
      return slots;
    },
  },

  methods: {
    close() {
      this.$emit("close");
    },

    setTimeSlot(slot) {
      const [hours, minutes] = slot.split(":");
      const newDate = new Date(this.selectedDate);

      newDate.setHours(hours);
      newDate.setMinutes(minutes);

      this.$emit("update:modelValue", newDate);
      this.close();
    },

    formatTimeSlot(hours, minutes) {
      const hoursStr = String(hours).padStart(2, "0");
      const minutesStr = String(minutes).padStart(2, "0");
      return `${hoursStr}:${minutesStr}`;
    },

    disableDate(date) {
      return new Date(date).getTime() < getDayStartDate().getTime();
    },
  },
};
</script>

<style lang="scss">
.date-time-picker {
  &__title {
    display: block;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 35px;
    text-align: center;
  }

  &__picker {
    display: grid;
    grid-template-columns: 224px auto;
    gap: 70px;
  }

  &__datepicker {
    margin: 0;
    width: 224px !important;


    .dp__month_year_wrap {
      display: flex;
      width: 100%;
      justify-content: center;
    }
    .dp__month_year_select {
      color: #2A284D;
      font-size: 14px;
      font-weight: 500;
      &:last-child {
        display: none;
      }
    }
    .dp__main {
      width: 210px;
    }
    .dp__calendar_header_separator {
      display: none;
    }
    .dp__menu {
      border: 0;
    }
    .dp__action_row {
      display: none;
    }
    .dp__calendar_header_item {
      text-transform: uppercase;
      color: #2A284D;
      opacity: 0.5;
      height: 30px;
    }
    .dp__calendar_row {
      height: 30px;
      &:first-child {
        margin-top: 0;
      }
    }
    .dp__calendar_item {
      width: 21px;
      height: 21px;
    }
    .dp__cell_inner {
      font-size: 14px;
      border-radius: 0;
      &:not(.dp__cell_disabled) {
        font-weight: 600;
      }
      &.dp__active_date {
        border-radius: 6px;
        background-color: #2A284D;
      }
      &.dp__cell_disabled:not(.dp__cell_offset) {
        background-color: #f3f3f3;
      }
    }
    .dp__today {
      border: 0;
    }
    .dp__overlay_cell_active {
      background-color: #2A284D;
    }
  }

  &__timepicker {
    margin: 0;
    width: 390px;
    text-align: center;

    &-title {
      font-weight: 600;
      font-size: 16px;
      height: 30px;
      line-height: 30px;
      margin-bottom: 5px;
      @media (max-width: $tablet) {
        display: none;
      }
    }
  }

  &__time-slots {
    height: 220px;
    overflow-y: scroll;
    @media (max-width: $tablet) {
      height: auto;
    }
    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: rgba($black, 0.2);
      border-radius: 15px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $black;
      outline: none;
      border-radius: 7px;
    }
  }

  &__time-slot {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 88px;
    height: 45px;
    background: #f4f4f6;
    border-radius: 2px;
    margin: 3px;
    border: 0;
    color: $black;
    font-size: 14px;
    font-family: $baseFont;
    font-weight: 400;
    cursor: pointer;

    @media (max-width: $tablet) {
      width: 68px;
      height: 29px;
      border-radius: 2px;
      font-size: 12px;
    }

    &:hover:not(.loading) {
      background: $lightgrey3;
      color: $black;
      border: 1px solid $black;
    }

    &.loading {
      cursor: default;
    }

    &_active {
      background: $black !important;
      color: $white !important;
      font-weight: 500;
    }
  }

  @media (max-width: 991px) {
    &__picker {
      gap: 25px;
    }

    &__timepicker {
      width: 385px;
    }
  }

  @media (max-width: 767px) {
    .mx-datepicker-main {
      float: none;
      margin: 0 auto 15px;
    }

    .mx-calendar {
      margin: 0 auto;
    }

    &__datepicker {
      width: 100% !important;
    }

    &__picker {
      grid-template-columns: 1fr;
      gap: 15px;
    }

    &__timepicker {
      margin: 0 auto;
      padding-bottom: 15px;
      border-bottom: 1px solid rgba(29, 39, 74, 0.1);
      overflow-y: auto;
    }

    &__time-slots {
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  @media (max-width: 575px) {
    &__timepicker {
      width: 100%;
    }

    &__time-slots {
      width: 100%;
    }
  }
}
</style>
