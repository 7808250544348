<template>
  <VueFinalModal
    v-model="isVisible"
    @beforeClose="emit('hide')"
    @opened="emit('show')"
    :id="id"
    :content-class="`modal-content ${contentClass}`">
    <div v-if="!hideHeader" class="modal-header vfm__header">
      <slot name="modal-header"></slot>
    </div>
    <div class="modal-body vfm__body">
      <slot></slot>
    </div>
    <div v-if="!hideFooter" class="modal-footer vfm__footer">
      <slot name="modal-footer"></slot>
    </div>
  </VueFinalModal>
</template>

<script setup>
import { VueFinalModal } from "vue-final-modal";

import { watch } from "vue";
import { visibleModalCodes } from "@/plugins/bvModal.client";

const nuxtApp = useNuxtApp();

const isVisible = ref(false);

const emit = defineEmits(["show", "hide"]);
const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  size: String,
  hideHeader: {
    type: Boolean,
    default: false,
  },
  hideFooter: {
    type: Boolean,
    default: false
  },
  contentClass: {
    type: String,
    default: "",
  },
});

watch(() => visibleModalCodes.value, () => {
  if (visibleModalCodes.value.includes(props.id)) {
    isVisible.value = true;
  } else {
    isVisible.value = false;
  }
}, { deep: true });

watch(() => isVisible.value, () => {
  if (!isVisible.value) {
    nuxtApp.$bvModal.hide(props.id);
  }
});
</script>

<style>
@import "./_b-modal.scss"
</style>
