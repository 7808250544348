<template>
  <div class="order-modal-success">
    <div class="order-modal-success__header">
      <div class="order-modal-success__title">
        <template v-if="isBooking">Вы успешно забронировали</template>
        <template v-else>Вы успешно оплатили</template>
      </div>
    </div>

    <div v-if="order.id" class="order-modal-success__content">
      <div class="order-modal-success__text">
        <p>Ваш номер заказа: {{ order.id }}</p>
        <p>Салон: {{ salonTitle }}</p>
        <p>Услуга: {{ serviceTitle }}</p>
        <p>Дата и время: {{ date }}</p>
        <p>Специалист: {{ specialistName }}</p>
        <p>На сумму: {{ price }} Р</p>
      </div>
      <div class="order-modal-success__footer">
        <button
          type="button"
          class="btn btn-black order-modal-success__btn"
          @click="showMap"
        >
          Схема проезда
        </button>
        <button
          type="button"
          class="btn btn-transporent order-modal-success__btn"
          @click="$bvModal.hide('order-modal')"
        >
          Закрыть
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "BookingSuccessModal",

  computed: {
    order() {
      const orderModule = useOrder();
      return orderModule.processedOrder;
    },
    serviceTitle() {
      return (
        this.order.serviceTitle ||
        this.order.specialistService?.serviceTitle ||
        ""
      );
    },
    salonTitle() {
      return this.order.salon?.name || "";
    },
    specialistName() {
      return this.order.specialistName || this.order.specialist?.name || "";
    },
    price() {
      return this.order.price || this.order.specialistService?.price || 0;
    },
    date() {
      return moment(this.order.date).format("DD.MM.YYYY H:mm");
    },
    isBooking() {
      return /book/i.test(this.order.status);
    },
  },

  methods: {
    showMap() {
      this.$bvModal.show("map-order-modal");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-content {
  border: 0;
  background: $white;
  border-radius: 5px;
}
.btn-close {
  background: $lightgrey2 url(@/assets/img/icon/banner-filter-remove.svg)
    no-repeat;
  width: 38px;
  height: 38px;
  background-size: 14px;
  background-position: center center;
  opacity: 0.5;
  border-radius: 50%;
  right: 0;
  margin-top: -10px;
  top: 50%;
  transform: translateY(-50%);
  &:hover {
    opacity: 1;
  }
  @media (max-width: 575px) {
    width: 25px;
    height: 25px;
    right: 15px;
    margin: 0;
  }
}
.modal-body {
  padding: 40px 45px 30px;
  padding: 0;
  @media (max-width: 575px) {
    padding: 0;
  }
}

.order-modal-success {
  &__header {
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 15px;
    border-bottom: 1px solid rgba($color: $black, $alpha: 0.1);
    @media (max-width: 575px) {
      padding: 25px;
      margin-bottom: 10px;
    }
  }
  &__content {
    @media (max-width: 575px) {
      padding: 0 25px 25px;
    }
  }
  &__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
  }
  &__text {
    font-size: 16px;
    line-height: 29px;
    margin-bottom: 30px;
  }
  &__btn {
    width: 100%;
    border-radius: 63px;
    font-size: 18px;
    font-weight: 600;
    padding: 13px;
  }
}
.order-modal-success__footer {
  display: flex;
}
</style>
