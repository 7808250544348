import { Model } from "@/stores/models/Model";
import { getImageFromSrc } from "@/helpers/images";

export class MapSalon extends Model {
  constructor(raw = {}) {
    super();
    const data = raw.data || {};

    this.id = Number(raw.id);
    this.slug = String(raw.slug || "");
    this.name = String(raw.title || "");
    this.minPrice = Number(raw.minPrice || 0);
    this.address = String(raw.address || "–");
    this.cover = getImageFromSrc(data.cover);
    this.coordinates = [Number(raw.latitude || 0), Number(raw.longitude || 0)];
  }
}
