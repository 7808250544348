<template>
  <form @submit.prevent>
    <div class="registration__group">
      <label for="email" class="auth__label">{{
        $t("auth.enterMailOrPhone")
      }}</label>
      <div class="send__message--field">
        <div
          class="reg__field--block"
          :class="{ error: v$.loginField.$error }">
          <input
            type="email"
            id="email"
            v-model="form.loginField"
            name="email"
            @input="setTelephone()"
            class="reg__field"
            autocomplete="off" />
          <span v-if="hasStringNumber" class="choose__region">
            <button
              type="button"
              @click="clickOpenPopup"
              class="active__region">
              <span class="region">
                <country-flag
                  :country="popupStore.activeRegion.region"
                  rounded
                  size="normal" />
              </span>
              <svg class="region__arrow">
                <use xlink:href="#arrow_down"></use>
              </svg>
            </button>
          </span>
        </div>
        <div
          class="message__block"
          :class="{
            'message__block--active': showCodeWindow && form.loginField.length,
          }">
          <div class="message__code--block">
            <h3 class="message__code--title">
              {{ $t("auth.acceptEmail") }}
            </h3>
            <div
              class="message__code--input"
              :class="{ error: v$.pinCode.$error }">
              <PincodeInput
                v-model="form.pinCode"
                placeholder="0"
                :length="6" />
            </div>
            <div v-if="v$.pinCode.$error" class="errors">
              <p class="error__text" v-if="!v$.pinCode.required">
                {{ $t("auth.requiredToFill") }}
              </p>
              <p class="error__text" v-if="!v$.pinCode.numeric">
                {{ $t("auth.onlyNumber") }}
              </p>
              <p class="error__text" v-if="!v$.pinCode.minLength">
                {{ $t("auth.pinLength") }}
              </p>
            </div>
          </div>

          <div class="message__send--block">
            <h5 class="message__send--title" v-if="!isFirstSend">
              Не пришло оповещение?
            </h5>
            <button
              type="button"
              @click="sendRepeat"
              :disabled="rep_send.isRunning === true"
              class="message__send--btn">
              {{
                isFirstSend
                  ? $t("auth.sendCode")
                  : $t("auth.sendCodeAgain")
              }}
            </button>
            <div class="message__send--timer">
              {{ prettyTime }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="v$.$invalid && v$.loginField.$error" class="errors">
        <p class="error__text" v-if="!v$.loginField.required">
          {{ $t("auth.requiredToFill") }}
        </p>
        <span v-if="v$.loginField.required">
          <p
            class="error__text"
            v-if="!v$.loginField.email && !hasStringNumber">
            {{ $t("auth.phoneEmail") }}
          </p>
          <p
            class="error__text"
            v-if="!v$.loginField.numeric && hasStringNumber">
            {{ $t("auth.phoneWrong") }}
          </p>
          <p
            class="error__text"
            v-if="!v$.loginField.maxLength && hasStringNumber">
            {{ $t("auth.phoneWrong") }}
          </p>
        </span>
      </div>
    </div>

    <div v-if="showPassField" class="w-100">
      <div class="registration__group">
        <label for="password" class="auth__label">{{
          $t("auth.password")
        }}</label>
        <div
          class="reg__field--block"
          :class="{ error: v$.password.$error }">
          <input
            type="password"
            name="password"
            v-model="reg_form.password"
            id="password"
            class="reg__field"
            autocomplete="off" />
        </div>
        <div
          v-if="v$.$invalid && v$.password.$error"
          class="errors">
          <p class="error__text" v-if="!v$.password.required">
            {{ $t("auth.requiredToFill") }}
          </p>
        </div>
      </div>

      <div class="registration__group">
        <label
          for="rep_password"
          class="auth__label">{{ $t("auth.repeatPassword") }}</label>
        <div
          class="reg__field--block"
          :class="{ error: v$.rep_password.$error }">
          <input
            type="password"
            name="rep_password"
            v-model="reg_form.rep_password"
            id="rep_password"
            class="reg__field"
            autocomplete="off" />
        </div>
        <div
          v-if="v$.$invalid && v$.rep_password.$error"
          class="errors">
          <p
            class="error__text"
            v-if="!v$.rep_password.required">
            {{ $t("auth.requiredToFill") }}
          </p>
          <p
            class="error__text"
            v-if="!v$.rep_password.sameAsPassword">
            {{ $t("auth.passwordsNotSame") }}
          </p>
        </div>
      </div>
    </div>
    <div class="auth__footer-btn">
      <button
        type="button"
        class="auth__button w_100-468 mt-4"
        @click="handleForm()">
        {{ $t("auth.recoveryBtn") }}
      </button>
    </div>

  </form>
</template>


<script setup>
import CountryFlag from "vue-country-flag-next";
// import PincodeInput from "vue-pincode-input";

import { ref, reactive, computed, onMounted } from "vue";
const sendConfirmTime = 60 * 1;
const authStore = useAuth();
const popupStore = usePopup();
const registrationClinicModule = useRegistrationClinicModule();

const emit = defineEmits(["onRecovery", "changeLoading"]);

import {
  required,
  numeric,
  maxLength,
  email,
  minLength,
  sameAs,
} from "@vuelidate/validators";

const showCodeWindow = ref(false);
const isFirstSend = ref(true);

const rep_send = reactive({
  isRunning: false,
  minute: 1,
  second: 0,
  time: 0,
  timer: null,
});
const form = reactive({
  loginField: "",
  pinCode: "",
  name: "",
  surname: "",
  email: "",
  telephone: "",
  password: "",
  rep_password: "",
});
const hasStringNumber = computed(() => /^\+?([0-9]*)\d*$/.test(form.loginField));
const rules = computed(() => {
  if (hasStringNumber.value) {
    return {
      loginField: {
        required,
        numeric,
        maxLength: maxLength(20),
      },
      pinCode: {
        required,
        numeric,
        minLength: minLength(6),
      },
      password: {
        required,
      },
      rep_password: {
        required,
        sameAsPassword: sameAs("password"),
      },
    };
  } else {
    return {
      loginField: {
        required,
        email,
      },
      pinCode: {
        required,
        numeric,
        minLength: minLength(6),
      },
      password: {
        required,
      },
      rep_password: {
        required,
        sameAsPassword: sameAs("password"),
      },
    };
  }
});
import { useVuelidate } from "@vuelidate/core";
const v$ = useVuelidate(rules, form);



const showPassField = computed(() => form.pinCode.length === 6 ? true : false);
const prettyTime = computed(() => {
  let time = rep_send.time / 60;
  let minutes = parseInt(time);
  let secondes = Math.round((time - minutes) * 60);
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (secondes < 10) {
    secondes = `0${secondes}`;
  }
  return minutes + ":" + secondes;
});

onMounted(() => {
  setTime();
});

function startTimer() {
  rep_send.isRunning = true;
  if (!rep_send.timer) {
    rep_send.timer = setInterval(() => {
      if (rep_send.time > 0) {
        rep_send.time--;
      } else {
        clearInterval(rep_send.timer);
        if (rep_send.time === 0) {
          rep_send.isRunning = false;
        }
        rep_send.isRunning = false;
        setTime();
      }
    }, 1000);
  }
}
function setTime() {
  rep_send.time = rep_send.minute * 60 + rep_send.second;
}
function sendCode() {
  if (!v$.value.loginField.$error) {
    if (!hasStringNumber.value) {
      authStore.sendConfirmCode({
        email: form.loginField,
      });
    } else {
      authStore.sendConfirmCode({
        phone: form.loginField,
      });
    }
  }
}
function sendRepeat() {
  if (rep_send.isRunning === false) {
    registrationClinicModule.sendTelephone(telephone.value);
    startTimer(sendConfirmTime);
    sendCode();
    form.pinCode = "";
  }
  isFirstSend.value = false;
}
function setTelephone() {
  v$.value.$touch();
  showCodeWindow.value = !!(
    !v$.value.loginField.$error && form.loginField.length
  );
}
function handleForm() {
  if (!showPassField.value) return false;
  v$.value.$touch();
  emit("changeLoading", false);
  if (v$.value.$invalid) {
    return;
  } else {
    emit("changeLoading", true);
    if (!hasStringNumber.value) {
      authStore.resetPassword({
        email: form.loginField,
        code: form.pinCode,
        password: reg_form.password,
      }).then((response) => {
        if (response.data.ok) {
          emit("onRecovery");
        }
        emit("changeLoading", false);
      });
    } else {
      authStore.resetPassword({
        phone: form.loginField,
        code: form.pinCode,
        password: reg_form.password,
      }).then((response) => {
        if (response.data.ok) {
          emit("onRecovery");
        }
        emit("changeLoading", false);
      });
    }
  }
}
function clickOpenPopup() {
  activePopup.value = true;
}
</script>
