import { mainApi } from "@/stores/api/main-api";
import { Salon } from "@/stores/models/Salon";
import { MapSalon } from "@/stores/models/MapSalon";
import { Category } from "@/stores/models/Category";
import { Service } from "@/stores/models/Service";
import camelCase from "lodash/camelCase";

import { acceptHMRUpdate } from "pinia";

export const useSearch = defineStore("search", {
  state: () => ({
    isSalonsLoading: false,
    isNextSalonsLoading: false,
    salonsData: [],
    salonsCount: 0,
    mapSalonsData: [],
    mapSalonsCount: 0,
    mapMarksData: [],
    salonsOffset: 0,
    servicesData: [],
    salonTypes: [],
    serviceCategoriesData: [],
    bodyParts: [],
    genderOptions: [
      {
        id: "М",
        title: "Мужской",
      },
      {
        id: "Ж",
        title: "Женский",
      },
      {
        id: "У",
        title: "Унисекс",
      },
    ],
    brands: [
      { id: 1, title: "Бренд 1" },
      { id: 2, title: "Бренд 2" },
      { id: 3, title: "Бренд 3" },
      { id: 4, title: "Бренд 4" },
    ],
    reviewOptions: [
      { id: "positive", title: "Только положительные" },
      { id: "mixed", title: "Смешанные" },
      { id: "negative", title: "Только негативные" },
      { id: "all", title: "Все" },
    ],
    bookingOptions: [
      { id: "free-cancel", title: "Бесплатная отмена бронирования" },
      { id: "no-credit", title: "Бронирование без кредитной карты" },
      { id: "no-prepay", title: "Без предоплаты" },
    ],
  }),

  getters: {
    salons() {
      return this.salonsData.map((item) => new Salon(item));
    },
    salonById(state) {
      const salonsData = this.salonsData;
      return (id) => {
        const salon = salonsData.find((item) => item.id === id);
        return salon ? new Salon(salon) : null;
      };
    },
    mapSalons() {
      return this.mapSalonsData.map((item) => new Salon(item));
    },
    mapMarks() {
      return this.mapMarksData.map((item) => new MapSalon(item));
    },
    services() {
      return this.servicesData.map((item) => new Service(item));
    },
    serviceCategories(state) {
      return this.serviceCategoriesData.map((item) => new Category(item))
    },
  },

  actions: {
    setServices(services) {
      this.servicesData = [...services];
    },
    setSalonTypes(salonTypes = []) {
      this.salonTypes = [...salonTypes];
    },
    setServiceCategories(serviceCategories) {
      this.serviceCategoriesData = [...serviceCategories];
    },
    setSalons(salons) {
      this.salonsData = [...salons];
    },
    setSalonsCount(count) {
      this.salonsCount = count;
    },
    setMapSalons(salons) {
      this.mapSalonsData = [...salons];
    },
    setMapSalonsCount(count) {
      this.mapSalonsCount = count;
    },
    setMapMarks(marks) {
      this.mapMarksData = [...marks];
    },
    setSalonsOffset(offset) {
      this.salonsOffset = offset;
    },
    pushSalons(salons) {
      this.salonsData.push(...salons);
    },
    setBodyParts(parts) {
      this.bodyParts = [...parts];
    },
    setBrands(brands) {
      this.brands = [...brands];
    },
    setSalonsLoading(isLoading) {
      this.isSalonsLoading = isLoading;
    },
    setNextSalonsLoading(isLoading) {
      this.isNextSalonsLoading = isLoading;
    },

    getServices(search) {
      return mainApi
        .getServices({ title: search })
        .then((response) => {
          this.setServices(response.data.data);
          return response.data.data;
        })
        .catch((error) => {
          const { $doNoty: doNoty } = useNuxtApp();
          doNoty.error(error);
        });
    },

    getSalonTypes() {
      return mainApi
        .getSalonTypes()
        .then((response) => {
          this.setSalonTypes(response.data.data);
          return response.data.data;
        })
        .catch((error) => {
          const { $doNoty: doNoty } = useNuxtApp();
          doNoty.error(error);
        });
    },

    async getServiceCategories() {
      try {
        const response = await mainApi
          .getServiceCategories({
            is_active: 1,
            "data[showInHeader]": true,
          });
        this.setServiceCategories(response.data.data);
        return response.data.data;
      } catch (err) {
        const { $doNoty: doNoty } = useNuxtApp();
        doNoty.error(err);
      }
    },

    getBodyParts() {
      return mainApi
        .getBodyParts()
        .then((response) => {
          this.setBodyParts(response.data.data);
          return response.data.data;
        })
        .catch((error) => {
          const { $doNoty: doNoty } = useNuxtApp();
          doNoty.error(error);
        });
    },

    async getSalons(query) {
      this.setSalonsOffset(0);
      const { data } = await mainApi.searchSalons(
        composeSalonsQuery(query, this.salonsOffset, this.limit)
      );
      const { data: stats } = await mainApi.getReviewsStatistics({
        salon_id: data.data.map((item) => item.id),
      });

      this.setSalons(
        data.data.map((item) => ({
          ...(stats.find((s) => s.id === item.id)?.statistic || {}),
          ...item,
        }))
      );
      this.setSalonsCount(data.count);
      this.setSalonsOffset(this.salonsOffset + 1);
    },

    async getMapSalons(query) {
      const { data } = await mainApi.searchSalons(
        composeSalonsQuery(query, 0, this.limit)
      );
      const { data: stats } = await mainApi.getReviewsStatistics({
        salon_id: data.data.map((item) => item.id),
      });

      this.setMapSalons(
        data.data.map((item) => ({
          ...(stats.find((s) => s.id === item.id)?.statistic || {}),
          ...item,
        }))
      );
      this.setMapSalonsCount(data.count);
    },

    async getMapMarks(query) {
      const { data: { count } } = await mainApi.searchMapSalons(
        composeSalonsQuery(query, 0, 0)
      );
      this.setMapMarks([]);
      const limit = +import.meta.env.VITE_MAP_SALONS_LIMIT || 100;
      let mapMarksCount = count;
      let salonsOffset = 0;

      while (mapMarksCount > 0) {
        mainApi.searchMapSalons(
          composeSalonsQuery(query, salonsOffset, limit)
        )
          .then(({ data }) => {
            this.setMapMarks([
              ...data.data,
              ...this.mapMarksData,
            ]);
          });
        mapMarksCount -= limit;
        salonsOffset++;
      }
    },

    async getNextSalons(query) {
      this.setNextSalonsLoading(true);

      const { data } = await mainApi.searchSalons(
        composeSalonsQuery(query, this.salonsOffset)
      );
      const { data: stats } = await mainApi.getReviewsStatistics({
        salon_id: data.data.map((item) => item.id),
      });

      this.pushSalons(
        data.data.map((item) => ({
          ...(stats.find((s) => s.id === item.id)?.statistic || {}),
          ...item,
        }))
      );
      this.setSalonsOffset(this.salonsOffset + 1);
      this.setNextSalonsLoading(false);
    },
  },
});

function composeSalonsQuery(query, salonsOffset, limit) {
  const finalQuery = {
    is_confirmed: 1,
    limit: 6,
    limit_salon_service: 3,
    offset_salon_service: 0,
    offset: salonsOffset || 0,
    price_from: query.priceFrom,
    price_to: query.priceTo,
    service: query.service,
    gender: query.gender,
    latitude: query.latitude,
    longitude: query.longitude,
    radius: query.radius,
  };

  if (typeof limit !== "undefined") {
    finalQuery.limit = limit;
  }

  const arrayTypes = ["category_id", "salon_type_id", "body_part_id"];

  arrayTypes.forEach((item) => {
    const queryKey = camelCase(item);
    if (typeof query[queryKey] === "string") {
      query[queryKey].split(",").forEach((value, index) => {
        finalQuery[`${item}[${index}]`] = value;
      });
    } else {
      finalQuery[item] = query[queryKey];
    }
  });

  return finalQuery;
}

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSearch, import.meta.hot));
}
