const THOUSANDS_REGEX = /\d{1,3}(?=(\d{3})+(?!\d))/g;

export function formatMoney(amount) {
  if (isNaN(Number(amount))) {
    return String(amount || "");
  }

  const parsed = Number.parseFloat(String(amount)) || 0;
  return `${formatNumber(parsed, { precision: 2 })} ₽`;
}

export function formatNumber(value, params = {}) {
  if (isNaN(Number(value))) {
    return String(value);
  }

  const { precision = 0, useFixedPrecision = false } = params;
  const [wholePart, decimalPart = ""] = Number(value)
    .toFixed(precision)
    .split(".");

  const formattedWholePart = wholePart.replace(THOUSANDS_REGEX, "$& ");
  const formattedDecimalPart = useFixedPrecision
    ? decimalPart
    : decimalPart.replace(/0+$/g, "");

  return formattedDecimalPart
    ? [formattedWholePart, formattedDecimalPart].join(",")
    : formattedWholePart;
}
