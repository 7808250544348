<template>
  <div class="dropdown">
    <button
      class="btn payment-modal__pay-btn dropdown-toggle"
      :class="{ show: isOpen }"
      type="button"
      @click="isOpen = !isOpen"
    >
      <span class="payment-modal__pay-icon">
        <img :src="selectedOption.icon" alt="credit-card" />
      </span>

      {{ selectedOption.label }}
    </button>

    <ul
      class="dropdown-menu payment-modal__pay-menu"
      :class="{ show: isOpen }"
      aria-labelledby="payment-modal__paydropdown"
    >
      <div
        v-for="option in paymentTypeOptions"
        :key="option.value"
        class="payment-modal__pay-item"
        @click="selectOption(option)"
      >
        <span class="payment-modal__pay-icon">
          <img :src="option.icon" alt="credit-card" />
        </span>

        <div class="payment-modal__pay-name">{{ option.label }}</div>
      </div>
    </ul>
  </div>
</template>

<script>
import { PAYMENT_TYPES } from "@/stores/order";
import { paymentsApi } from "@/stores/api/payments-api";
import filter from "lodash/filter";
import creditCardIcon from "@/assets/img/icon/credit-card.svg";

export default {
  name: "PaymentTypeSelect",

  props: {
    value: { type: String, required: true },
  },

  data() {
    return {
      isOpen: false,
      savedCards: [],
    };
  },

  computed: {
    paymentTypeOptions() {
      return [
        {
          value: PAYMENT_TYPES.save,
          label: "С сохранением карты",
          icon: creditCardIcon,
        },
        {
          value: PAYMENT_TYPES.noSave,
          label: "Без сохранения карты",
          icon: creditCardIcon,
        },
        ...this.savedCards.map((item) => ({
          value: item.payment_id,
          label:
            item.title || `${item.card_type} ${item.first6} **** ${item.last4}`,
          icon: creditCardIcon,
        })),
      ];
    },

    selectedOption() {
      return this.paymentTypeOptions.find((item) => item.value === this.value);
    },
  },

  created() {
    this.loadSavedCards();
  },

  methods: {
    async loadSavedCards() {
      try {
        const { data } = await paymentsApi.getSavedCards();
        this.savedCards = filter(data.cards, { confirmed: true });
      } catch (err) {
        const { $doNoty: doNoty } = useNuxtApp();
        doNoty.error(err);
      }
    },

    selectOption(option) {
      this.$emit("input", option.value);
      this.isOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
#salon-page__payment-modal .payment-modal {
  &__pay-btn {
    &::after {
      transition: transform 250ms ease;
    }

    &.show {
      &::after {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }
}
</style>
