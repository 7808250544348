<template>
  <b-modal
    id="order-modal"
    hide-header
    hide-footer
    @show="onShow"
    @hide="onHide"
  >
    <div
      class="modal show"
      id="salon-page__order-modal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      @click="$bvModal.hide('order-modal')"
    >
      <div
        v-if="order"
        class="modal-dialog modal-dialog-scrollable modal-dialog-centered"
        @click.stop
      >
        <div class="modal-content">
          <div ref="body" class="modal-body">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="$bvModal.hide('order-modal')"
            />

            <SelectStep
              v-if="step === ORDER_STEPS.select"
              :specialist-selected="isSpecialistSelected"
              :service-selected="isServiceSelected"
              @next-click="setStep(ORDER_STEPS.confirm)"
            />

            <ConfirmStep
              v-else-if="step === ORDER_STEPS.confirm"
              @prev-click="setStep(ORDER_STEPS.select)"
              @next-click="setStep(ORDER_STEPS.success)"
            />

            <SuccessStep v-else @prev-click="setStep(ORDER_STEPS.confirm)" />
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import BModal from "@/components/Modals/BModal/BModal.vue";
import SelectStep from "./SelectStep.vue";
import ConfirmStep from "./ConfirmStep.vue";
import SuccessStep from "./SuccessStep.vue";

const ORDER_STEPS = {
  select: "select",
  confirm: "confirm",
  success: "success",
};

export default {
  name: "OrderModal",
  components: {
    BModal,
    SelectStep,
    ConfirmStep,
    SuccessStep,
  },

  data() {
    return {
      isSpecialistSelected: false,
      isServiceSelected: false,
      step: ORDER_STEPS.select,
    };
  },

  computed: {
    order() {
      const orderModule = useOrder();
      return orderModule.order;
    },

    ORDER_STEPS: () => ORDER_STEPS,
  },

  methods: {
    onShow() {
      this.isSpecialistSelected = Boolean(this.order.specialistId);
      this.isServiceSelected = Boolean(this.order.serviceId);

      if (this.$route.query.showSuccess) {
        this.setStep(ORDER_STEPS.success);

        this.$router.replace({
          query: {
            ...this.$router.query,
            showSuccess: undefined,
          },
        });
      }
    },

    onHide() {
      const orderModule = useOrder();
      this.step = ORDER_STEPS.select;
      orderModule.resetOrder();
    },

    setStep(step) {
      this.step = step;
      if (this.$refs.body) {
        this.$refs.body.scrollTop = 0;
      }
    },
  },
};
</script>

<style lang="scss">
@import "order-modal";

#order-modal {
  .row {
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
  & .modal-dialog > .modal-content {
    padding: 0px;
    border: 0;
    background: $white;
    border-radius: 5px;
  }

  & > .modal-dialog > .modal-content > .modal-body {
    padding: 0;
  }

  .modal {
    display: initial;
  }

  .date-time-picker__timepicker {
    max-width: 330px;
  }
}
</style>
