import { Model } from "@/stores/models/Model";
import { getRatingTitle } from "@/helpers/rating";
import { getNumberWithoutPercent } from "@/helpers/percents";
import { SpecialistService } from "./SpecialistService";
import { getImageFromSrc } from "@/helpers/images";

export class SalonService extends Model {
  constructor(raw = {}) {
    super();
    this.id = Number(raw.id);
    this.specialistServiceId = Number(raw.specialist_service_id);
    this.serviceId = Number(raw.service_id);
    this.salonId = Number(raw.salon_id);
    this.title = String(raw.title || "");

    const category = raw.category || {};
    this.categoryId = Number(category.id || raw.category_id);
    this.categoryTitle = String(category.title || "");

    this.duration = Number(raw.duration || 0);
    this.price = Number(raw.price || 0);
    this.priceWithDiscount =
      Math.floor(
        getNumberWithoutPercent(this.price, import.meta.env.VITE_DISCOUNT)
      ) || 0;
    this.priceWithCancelDiscount =
      Math.floor(
        getNumberWithoutPercent(
          this.price,
          import.meta.env.VITE_DISCOUNT_WITH_CANCEL
        )
      ) || 0;
    this.gender = String(raw.gender || "");

    const data = raw.data || {};
    this.bodyPartId = data.body_part_id;
    this.description = data.description || "";
    this.cover = getImageFromSrc(data.cover);
    this.isActive = Boolean(data.is_active);

    this.reviewsCount = Number(raw.review_count || 0);
    this.rating = Number(raw.rating || 0);
    this.ratingTitle = getRatingTitle(this.rating);

    this.specialistService = new SpecialistService(
      raw.specialist_service || {}
    );
  }
}
