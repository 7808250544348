import { i18n } from "@/plugins/i18n";

export function getDayStartDate(value) {
  const date = value !== undefined ? new Date(value) : new Date();

  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);

  return date;
}

export const format = (date, options = {}) => {
  const formatter = new Intl.DateTimeFormat(i18n.global.locale, options);
  return formatter.format(date);
}

export function formatDateTime(val) {
  if (!val) return "";

  const formatter = new Intl.DateTimeFormat(i18n.global.locale, {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  });

  try {
    return formatter.format(new Date(val));
  } catch (err) {
    return "";
  }
}

export function formatDate(val) {
  if (!val) return "";

  const formatter = new Intl.DateTimeFormat(i18n.global.locale, {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  return formatter.format(new Date(val));
}

export function formatTime(val) {
  if (!val) return "";

  const formatter = new Intl.DateTimeFormat(i18n.global.locale, {
    hour: "numeric",
    minute: "numeric",
  });

  return formatter.format(new Date(val));
}

export function getDateString(val) {
  if (!val) return "";

  const date = new Date(val);

  return [
    date.getFullYear(),
    String(date.getMonth() + 1).padStart(2, "0"),
    String(date.getDate()).padStart(2, "0"),
  ].join("-");
}

export function getDuration(val) {
  if (!val) return "";

  const hours = Math.floor(val / 60);
  const minutes = val % 60;

  return (hours ? `${hours} ч` : "")
    + (minutes ? ` ${minutes} м` : "");
}

export function formatISODate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

export function getMonthDiff(d1, d2) {
  let months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
}
