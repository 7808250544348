import { authApi } from "@/stores/api/auth-api";
import i18n from "@/plugins/i18n";

import { USER_STATUSES } from "@/constants/user-statuses";
import { auth } from "@/helpers/auth";
import { getCrmRedirectUrl } from "@/helpers/links";

import Cookie from "js-cookie";
import { paymentsApi } from "@/stores/api/payments-api";
import { CashbackLevel } from "@/stores/models/CashbackLevel";
import { useFavoritesModule } from "./favoritesModule";
import userAccountIcon from "@/assets/img/icon/profile-icon/user-account.svg";

const COOKIE_CITY_KEY = "city_name";
const COOKIE_LONGITUDE_KEY = "city_longitude";
const COOKIE_LATITUDE_KEY = "city_latitude";

export const useAccount = defineStore("account", {
  state: () => ({
    userId: "",
    email: "",
    phone: "",
    roles: [],
    userInfo: {},
    user: null,
    isUserInfoLoading: false,

    balance: 0,
    cashback: 0,
    spentAmount: 0,
    status: USER_STATUSES.classic,
    cashbackLevels: [],

    city: "",
    latitude: "55.76",
    longitude: "37.64",
  }),

  getters: {
    fullName() {
      return [this.userInfo.firstName || "", this.userInfo.lastName || ""]
        .filter((val) => val)
        .join(" ");
    },

    avatar() {
      return this.userInfo.avatar || userAccountIcon;
    },

    currentCashbackLevel() {
      return (
        this.cashbackLevels.find((item) => item.title === this.status) ||
        new CashbackLevel()
      );
    },

    nextCashbackLevel() {
      const index = this.cashbackLevels.indexOf(this.currentCashbackLevel);
      return this.cashbackLevels[index + 1] || new CashbackLevel();
    },

    discountType() {
      return this.userInfo.discountType || "";
    },

    // TODO:
    referralLink() {
      return `${import.meta.env.VITE_BASE_URL || ""}/register/?refid=11111`;
    },

    isAdmin() {
      return (
        this.roles.includes("admin-company") || this.roles.includes("super-admin")
      );
    },

    isSpecialist() {
      return this.roles.includes("specialist");
    },

    isWebmaster() {
      return this.roles.includes("webmaster");
    },

    crmLink() {
      return getCrmRedirectUrl();
    },

    favoriteSalons() {
      return this.userInfo.favoriteSalons;
    },

    favoriteSpecialists() {
      return this.userInfo.favoriteSpecialists;
    },

    favoriteServices() {
      return this.userInfo.favoriteServices;
    },
  },

  actions: {
    initCookies() {
      this.city = Cookie.get(COOKIE_CITY_KEY) || "";
      this.latitude = Cookie.get(COOKIE_LATITUDE_KEY) || "55.76";
      this.longitude = Cookie.get(COOKIE_LONGITUDE_KEY) || "37.64";
    },

    setUser(user) {
      const oldKeys = Object.keys(user.userinfo);
      const keys = Object.keys(user.userinfo).map((key) => {
        let keyArr = key.split("_");
        if (keyArr.length === 1) return keyArr[0];
        for (let i = 1; i < keyArr.length; i++) {
          keyArr[i] = keyArr[i].split("");
          keyArr[i][0] = keyArr[i][0].toUpperCase();
          keyArr[i] = keyArr[i].join("");
        }
        return keyArr.join("");
      });
      keys.forEach((key, i) => {
        if (key !== oldKeys[i]) {
          user.userinfo[key] = user.userinfo[oldKeys[i]];
          delete user.userinfo[oldKeys[i]];
        }
      });
      this.user = user;
    },

    async loadUserInfo() {
      const { $doNoty: doNoty } = useNuxtApp();
      try {
        const accessToken = Cookie.get("accessToken");
        if (!accessToken || this.isUserInfoLoading) return;
        this.isUserInfoLoading = true;

        const [
          user,
          { data: balance },
          { data: status },
          { data: cashback },
        ] = await Promise.all([
          auth.getUserInfo(accessToken),
          paymentsApi.getBalance({
            access_token: accessToken,
            currency: "RUB",
          }),
          paymentsApi.getAccountStatus({
            access_token: accessToken,
            currency: "RUB",
          }),
          paymentsApi.getCashbackSettings(),
        ]);

        const userId = await auth.getUserId(accessToken);
        this.userId = userId;
        this.setUser(user);
        this.balance = Number(balance.amount) || 0;
        this.cashback = Number(balance.cashback) || 0;
        this.status = status.status || USER_STATUSES.classic;
        this.cashbackLevels = (cashback.levels || []).map(
          (item) => new CashbackLevel(item)
        );

        this.email = user.email || "";
        this.phone = user.phone || "";
        this.roles = user.roles || [];
        this.userInfo = user.userinfo || {};

        const { data: spent } = await paymentsApi.getSpentAmount({
          access_token: accessToken,
          currency: "RUB",
          start_date:
            new Date(Date.now() - Number(cashback.check_period * 1000 || 0))
              .toISOString()
              .split(".")[0] + "Z",
        });
        this.spentAmount = Number(spent.amount || 0);

        this.isUserInfoLoading = false;
        const favoritesModule = useFavoritesModule();
        favoritesModule.init();
      } catch (err) {
        await auth.logout();
        this.isUserInfoLoading = false;
        console.error(err);
        doNoty.error(err);
      }
    },

    async updateUserInfo({ params, successMessage = "Данные успешно обновлены", }) {
      const { $doNoty: doNoty } = useNuxtApp();
      try {
        const updatedInfo = { ...this.userInfo, ...params };
        const { data } = await authApi.updateUserInfo({
          accessToken: Cookie.get("accessToken"),
          userinfo: updatedInfo,
        });

        if (data.ok) {
          this.userInfo = updatedInfo;
          doNoty.success(successMessage);
        } else {
          doNoty.error(data.msg);
        }
      } catch (err) {
        console.error(err);
        doNoty.error(err);
      }
    },

    async updateLogin(params) {
      const { $doNoty: doNoty } = useNuxtApp();
      try {
        if (!params.email && !params.phone)
          return doNoty.error(i18n.t("auth.requiredField"));
        if (params.email && !params.emailConfirmCode)
          return doNoty.error(i18n.t("auth.confirmEmail"));
        if (params.phone && !params.phoneConfirmCode)
          return doNoty.error(i18n.t("auth.confirmPhone"));

        const { data } = await authApi.changeLogin(params);

        if (data.ok) {
          if (params.email) this.email = params.email;
          if (params.phone) this.phone = params.phone;
          doNoty.success(i18n.t("auth.dataChanged"));
        } else {
          doNoty.error(data.msg);
        }
      } catch (err) {
        console.error(err);
        doNoty.error(err);
      }
    },

    changeCity({ cityName, countryCode, longitude, latitude } = {}) {
      this.city = countryCode || cityName || "Москва";
      if (/Moscow/i.test(this.city)) this.city = "Москва";
      this.latitude = latitude || "55.76";
      this.longitude = longitude || "37.64";

      Cookie.set(COOKIE_CITY_KEY, this.city, {
        expires: 30,
      });
      Cookie.set(COOKIE_LATITUDE_KEY, this.latitude, {
        expires: 30,
      });
      Cookie.set(COOKIE_LONGITUDE_KEY, this.longitude, {
        expires: 30,
      });
    },

    signOut() {
      this.userId = "";
      this.email = "";
      this.phone = "";
      this.roles = [];
      this.userInfo = {};

      this.balance = 0;
      this.cashback = 0;
      this.status = USER_STATUSES.classic;
    },
  },
});
