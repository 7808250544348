<template>
  <b-modal id="credit-card-modal" hide-header hide-footer @hide="onHide">
    <div class="modal" id="credit-card">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="credit-card__header position-relative">
            <div class="credit-card__title">Введите вашу карту</div>

            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="$bvModal.hide('credit-card-modal')"
            />
          </div>

          <div class="credit-card__body">
            <form class="payment-card">
              <div class="bank-card">
                <div class="bank-card__side bank-card__side_front">
                  <div
                    class="
                      bank-card__side-blocks
                      d-flex
                      justify-content-between
                    "
                  >
                    <div class="bank-card__side-left">
                      <div class="bank-card__inner">
                        <label class="bank-card__label bank-card__label_number">
                          <span class="bank-card__hint">Номер вашей карты</span>
                          <input
                            v-model="card.number"
                            type="text"
                            class="bank-card__field number-card"
                            name="number-card"
                            maxlength="19"
                            required
                          />
                        </label>
                      </div>

                      <div class="bank-card__inner bank-card__footer">
                        <div class="bank-card__hint">Срок действия</div>
                        <div class="bank-card__two d-flex">
                          <label
                            class="
                              bank-card__label bank-card__month
                              position-relative
                            "
                          >
                            <span class="bank-card__month-label">Месяц</span>
                            <input
                              v-model="card.expiryMonth"
                              type="text"
                              class="bank-card__field"
                              maxlength="2"
                              name="mm-card"
                              required
                            />
                          </label>

                          <label
                            class="
                              bank-card__label bank-card__year
                              position-relative
                            "
                          >
                            <span class="bank-card__year-label">Год</span>
                            <input
                              v-model="card.expiryYear"
                              type="text"
                              class="bank-card__field"
                              maxlength="2"
                              name="year-card"
                              required
                            />
                          </label>
                        </div>
                      </div>
                    </div>

                    <div class="bank-card__side-right">
                      <div class="bank-card__logo-bank">
                        <img src="@/assets/img/logoBank/visa.svg" alt="" />
                      </div>

                      <div class="bank-card__logo-bank">
                        <img
                          src="@/assets/img/logoBank/mastercard.svg"
                          alt=""
                        />
                      </div>

                      <div class="bank-card__logo-bank">
                        <img src="@/assets/img/logoBank/mir.png" alt="" />
                      </div>
                    </div>
                  </div>

                  <div
                    class="bank-card__inner d-flex mb-0 justify-content-between"
                  >
                    <label class="bank-card__label bank-card__label_holder">
                      <span class="bank-card__hint">
                        Имя владельца банковской карты
                      </span>
                      <input
                        v-model="card.cardholder"
                        type="text"
                        class="bank-card__field"
                        pattern="[A-Za-z, ]{2,}"
                        name="holder-card"
                        required
                      />
                    </label>

                    <div class="bank-card__cvc-model">
                      <label class="bank-card__label bank-card__cvc">
                        <span class="bank-card__hint">CVC/CVV</span>
                        <input
                          v-model="card.csc"
                          type="password"
                          class="bank-card__field"
                          placeholder=""
                          maxlength="3"
                          pattern="[0-9]{3}"
                          name="cvc-card"
                          required
                        />
                      </label>
                    </div>
                  </div>
                </div>

                <div class="bank-card__side bank-card__side_back">
                  <div class="bank-card__inner mb-0">
                    <label class="bank-card__label bank-card__cvc">
                      <span class="bank-card__hint">CVC/CVV</span>
                      <input
                        v-model="card.csc"
                        type="password"
                        class="bank-card__field"
                        placeholder=""
                        maxlength="3"
                        name="cvc-card"
                        required
                      />
                      <div class="bank-card__cvc-text">
                        Последние 3-4 цифры на обрантной стороне вашей карты
                      </div>
                    </label>
                  </div>
                </div>
              </div>

              <div class="payment-card__footer">
                <button
                  class="payment-card__button"
                  :disabled="isProcessing"
                  @click="submitPayment"
                >
                  <template v-if="isProcessing"> Создание платежа… </template>
                  <template v-else>Подтвердить</template>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import BModal from "@/components/Modals/BModal/BModal.vue";

export default {
  name: "CreditCartModal",

  components: {
    BModal,
  },

  data() {
    return {
      isProcessing: false,
      card: {
        number: "",
        expiryYear: "",
        expiryMonth: "",
        csc: "",
        cardholder: "",
      },
    };
  },

  methods: {
    async submitPayment() {
      const paymentModule = usePayment();
      try {
        this.isProcessing = true;
        paymentModule.updateCard(this.card);
        await paymentModule.createPayment();
        this.$bvModal.hide("credit-card-modal");
      } catch (err) {
        const { $doNoty: doNoty } = useNuxtApp();
        console.error(err);
        doNoty.error(err);
      } finally {
        this.isProcessing = false;
      }
    },

    onHide() {
      const paymentModule = usePayment();
      paymentModule.reset();
    },
  },
};
</script>

<style lang="scss">
#credit-card-modal {
  .modal-body {
    padding: 0;
  }

  .modal {
    display: initial;
  }
}
</style>

<style lang="scss" scoped>
@import "card-modal";
</style>
