<template>
  <b-modal id="cancel-modal" hide-header hide-footer>
    <div class="modal" id="modal-cancel">
      <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="modal-cancel__header">
              <div class="modal-cancel__header-title">Отмена</div>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                @click="$bvModal.hide('cancel-modal')"
              ></button>
            </div>

            <div class="modal-cancel__content">
              <div class="modal-cancel__content-title">
                Вы уверены, что хотите отменить бронирование?
              </div>
              <div class="modal-cancel__content-subtitle">
                Данное действие невозможно будет отменить!
              </div>

              <div class="modal-cancel__content-buttons">
                <button
                  type="button"
                  class="btn btn-black modal-cancel__content-button"
                  @click="cancelOrder"
                >
                  Да
                </button>

                <button
                  type="button"
                  class="btn btn-border modal-cancel__content-button"
                  data-bs-dismiss="modal"
                  @click="$bvModal.hide('cancel-modal')"
                >
                  Нет
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import BModal from "@/components/Modals/BModal/BModal.vue";

export default {
  name: "CancelModal",

  components: {
    BModal,
  },

  methods: {
    cancelOrder() {
      const ordersModule = useOrdersModule();
      ordersModule.cancelOrder();
      this.$bvModal.hide("cancel-modal");
    },
  },
};
</script>

<style lang="scss">
#cancel-modal {
  .modal-body {
    padding: 0;
  }

  .modal {
    display: initial;
  }
}
</style>

<style lang="scss" scoped>
@import "cancel-modal";
</style>
