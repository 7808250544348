<template>
  <div
    class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 populat-service__block"
    :class="{
      'populat-service__block--selected': selected,
      'populat-service__block--small': isSmall,
    }">
    <div class="populat-service__block-inner">
      <div class="populat-service__block-header">
        <div class="populat-service__block-icons">
          <div
            v-if="service.gender"
            role="tooltip"
            :data-microtip-position="'top'"
            data-microtip-size="medium"
            :aria-label="service.gender === 'Ж'
              ? 'Подходит женщинам'
              : service.gender === 'М'
                ? 'Подходит мужчинам'
                : 'Подходит всем'
            "
            class="populat-service__block-icon populat-service__block-gender">
            <img v-if="service.gender === 'Ж'" src="@/assets/img/icon/female.svg" />
            <img v-else-if="service.gender === 'М'" src="@/assets/img/icon/male.svg" />
            <img v-else src="@/assets/img/icon/male-female.svg" />
          </div>
        </div>
        <span
          v-if="!hideFavorite"
          class="shop-favorite"
          :class="{ active: isFavorite }"
          @click="toggleFavorite"></span>

        <button
          v-if="salon.isBestPrice"
          type="button"
          class="best-price-label"
          role="tooltip"
          data-microtip-position="top-right"
          data-microtip-size="medium"
          aria-label="Салон по лучшей цене"/>

        <div class="populat-service__block-img">
          <img :src="service.cover"/>
        </div>
      </div>

      <div class="populat-service__block-footer">
        <div class="populat-service__rating d-flex align-items-center justify-content-between">
          <div class="populat-service__rating-left d-flex align-items-center">
            <span class="populat-service__rating-value">
              {{ service.rating }}
            </span>

            <div class="populat-service__rating-reviews">
              {{ $tc("review.count", service.reviewsCount) }}
            </div>
          </div>

          <div class="populat-service__rating-right">
            <span class="promo-time-procedure">
              <span>{{ service.duration }} мин</span>
            </span>
          </div>
        </div>

        <span class="populat-service__name">
          {{ service.title }}
        </span>

        <div class="populat-service__prices">
          <template v-if="service.priceWithDiscount">
            <div class="populat-service__price old">
              от {{ service.price }} руб.
            </div>

            <div class="populat-service__price new">
              от {{ service.priceWithDiscount }} руб.
            </div>
          </template>
          <div v-else class="populat-service__price new">
            от {{ service.price }} руб.
          </div>
        </div>
      </div>

      <button
        v-if="!hideBooling"
        type="button"
        class="btn populat-service__block-btn btn-border"
        @click="$emit('book-click')">
        Забронировать
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServiceCard",

  props: {
    service: { type: Object, required: true },
    isSmall: { type: Boolean, default: false },
    selected: { type: Boolean, default: false },
    hideBooling: { type: Boolean, default: false },
    hideFavorite: { type: Boolean, default: false },
  },

  data: () => ({
    isFavorite: false,
  }),

  computed: {
    favoritesModule() {
      return useFavorites();
    },

    salonModule() {
      return useSalon();
    },

    salon() {
      return this.salonModule.salon;
    },

    specialistServices() {
      return this.salonModule.specialistServices.filter(
        (item) => item.serviceId === this.service.serviceId
      );
    },
  },

  created() {
    const specialist = this.favoritesModule.favoriteServices.find(
      (item) =>
        item.specialistId === this.service.specialistService?.specialistId
    );

    this.isFavorite =
      specialist && specialist.items.includes(this.service.serviceId);
  },

  methods: {
    toggleFavorite() {
      this.isFavorite = !this.isFavorite;
      this.favoritesModule.setFavoriteService({
        serviceId: this.service.serviceId,
        specialistId: this.service.specialistService?.specialistId,
        isFavorite: this.isFavorite,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/salon-service-card";
</style>
