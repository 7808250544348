<template>
  <div class="order-modal__order-price">
    <div class="order-modal__order-priceTitle">Выберите цену</div>

    <div class="order-modal__price-blocks">
      <div class="order-modal__price-blocksInner">
        <div
          v-for="orderType in orderTypes"
          :key="orderType.type"
          class="order-modal__price-block"
          :class="{
            cheap:
              isDecideYourselfMode &&
              isCustomPriceSelected &&
              orderType.type === order.type,
          }"
        >
          <label
            class="type-pay__block"
            :class="{
              checked: orderType.type === order.type,
              cheap: orderType.type === customType,
            }"
          >
            <input
              type="radio"
              name="type-pay"
              @change="changeOrderType(orderType)"
            />
            <div class="type-pay__block-content">
              <div
                v-if="
                  isDecideYourselfMode &&
                  isCustomPriceSelected &&
                  orderType.type === order.type
                "
                class="order-modal__price-block2"
              >
                <input
                  v-model="customPrice"
                  @input="customPriceError = ''"
                  @blur="validateCustomPrice"
                  placeholder="Введите вашу сумму"
                  class="order-modal__price-input"
                />
                <div class="your-choice__price-error">
                  {{ customPriceError }}
                </div>
                <button
                  @click="toggleDecideYourself"
                  class="order-modal__price-btn btn"
                >
                  Выбрать
                </button>
              </div>
              <template v-else>
                <div class="type-pay__block-title">
                  {{ orderType.title }}
                </div>
                <div
                  class="type-pay__block-change"
                  v-if="isCustomPriceSelected && orderType.type === order.type"
                  @click="toggleDecideYourself"
                >
                  Изменить
                </div>
              </template>
              <div class="type-pay__block-footer">
                <template
                  v-if="
                    !isDecideYourselfMode ||
                    !isCustomPriceSelected ||
                    orderType.type !== order.type
                  "
                >
                  {{
                    orderType.priceTitle && !orderType.price
                      ? orderType.priceTitle
                      : formatMoney(orderType.price)
                  }}
                </template>
                <div
                  v-if="orderType.tooltip"
                  class="type-pay__tooltip"
                  role="tooltip"
                  data-microtip-position="top"
                  data-microtip-size="medium"
                  :aria-label="orderType.tooltip"
                ></div>
              </div>
            </div>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { formatMoney } from "@/helpers/formatters";
</script>
<script>
import { ORDER_TYPES } from "@/stores/order";
import { getNumberWithoutPercent } from "@/helpers/percents";

export default {
  name: "PriceSelect",

  data() {
    return {
      isDecideYourselfMode: true,
      customPrice: "",
      customPriceError: "",
    };
  },

  computed: {
    order() {
      const orderModule = useOrder();
      return orderModule.order;
    },

    selectedService() {
      const orderModule = useOrder();
      return orderModule.selectedService;
    },

    serviceTitle() {
      return this.order.serviceTitle || this.order.serviceTitle;
    },

    orderTypes() {
      const defaultTypes = [
        {
          type: ORDER_TYPES.base,
          title: "Цена за " + this.order.serviceTitle,
          price: this.selectedService.priceWithDiscount,
        },
        {
          type: ORDER_TYPES.cancel,
          title:
            "Цена за " +
            this.order.serviceTitle +
            " + возможность отмены брони",
          price: this.selectedService.priceWithCancelDiscount,
          tooltip: "Вы можете отменить бронь без потери денег",
        },
        {
          type: ORDER_TYPES.custom,
          title: "Решай сам",
          price: Number(this.customPrice),
          priceTitle: "Хочу дешевле",
          tooltip: `Lorem ipsum dolor \nsit amet, consectetur \nadipiscing elit.`,
        },
      ];

      return defaultTypes;
    },
    isCustomPriceSelected() {
      return this.order.type === ORDER_TYPES.custom;
    },
    customType() {
      return ORDER_TYPES.custom;
    },
  },

  created() {
    this.changeOrderType(this.orderTypes[0]);
  },

  methods: {
    toggleDecideYourself() {
      if (!this.isDecideYourselfMode) {
        this.isDecideYourselfMode = true;
        return;
      }

      this.validateCustomPrice();
      if (this.customPriceError) return;

      this.isDecideYourselfMode = false;
      this.changeOrderType(this.orderTypes[2]);
    },

    validateCustomPrice() {
      if (!this.customPrice) {
        this.customPriceError = "Поле не должно быть пустым";
        return;
      }

      const price = Number(this.customPrice);
      const minPrice = Math.round(this.selectedService.price * 0.6);
      const maxPrice = this.selectedService.price;
      if (price > maxPrice || price < minPrice) {
        this.customPriceError = `Укажите цену в диапазоне ${minPrice} - ${maxPrice}`;
        return;
      }

      this.customPriceError = "";
    },

    changeOrderType(orderType) {
      const orderModule = useOrder();
      orderModule.updateOrder({
        type: orderType.type,
        price: orderType.price,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.type-pay {
  &__block-footer {
    display: flex;
    align-items: center;
  }

  &__block {
    width: 100%;
    .type-pay__tooltip:after {
      white-space: pre-wrap;
    }
    &.checked .type-pay__tooltip {
      background-image: url(@/assets/img/icon/question-white.svg);
    }
    &.cheap {
      .type-pay__tooltip {
        position: absolute;
        right: 13px;
        top: 10px;
        &:after {
          left: unset;
          right: 0;
          transform: unset;
        }
      }
    }
  }

  &__tooltip {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    background-image: url(@/assets/img/icon/question.svg);
    background-repeat: no-repeat;
    background-size: 20px;
    cursor: pointer;
  }
}

.your-choice {
  &__price {
    &-info {
      width: 100%;
    }

    &-error {
      font-size: 10px;
      line-height: 1.2;
      margin: 5px 0;
      color: $lightred;
    }

    &-inputBlock {
      margin-right: 0;
    }

    &-blocks {
      margin-bottom: 30px;
    }
  }
}
</style>
