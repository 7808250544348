import { createVfm } from "vue-final-modal";
import { ref } from "vue";

export const visibleModalCodes = ref([]);
export default defineNuxtPlugin((nuxtApp) => {
  const vfm = createVfm();
  nuxtApp.vueApp.use(vfm);
  nuxtApp.provide("bvModal", {
    show(code) {
      if (!visibleModalCodes.value.includes(code)) {
        visibleModalCodes.value.push(code);
      }
    },
    hide(code) {
      if (visibleModalCodes.value.includes(code)) {
        visibleModalCodes.value = visibleModalCodes.value.filter((item) => item !== code);
      }
    },
  });
});
