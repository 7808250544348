<template>
  <div class="modal-salons">
    <ModalSalonsSkeleton v-if="isLoading" :count="3" />

    <div v-else-if="searchStore.mapSalons.length" class="map-salon__items">
      <div
        v-for="salon in searchStore.mapSalons"
        :key="salon.id"
        class="map-salon__item d-block">
        <div class="map-salon__item-inner">
          <div class="map-salon__item-left">
            <router-link
              :to="getSalonLink(salon)"
              @click.native="$emit('click')">
              <img :src="salon.cover" />
            </router-link>
          </div>

          <div class="map-salon__item-right">
            <router-link
              class="map-salon__name"
              :to="getSalonLink(salon)"
              @click.native="$emit('click')">
              {{ salon.name }}
            </router-link>

            <div class="map-salon__rating">
              <div class="map-salon__rating-ball">{{ salon.rating }}</div>
              <router-link
                :to="`${getSalonLink(salon)}#reviews`"
                class="map-salon__rating-reviews">
                {{ $tc("review.count", salon.reviewsCount) }}
              </router-link>
            </div>

            <div class="map-salon__location">
              <i class="fas fa-map-marker-alt"></i>
              <div
                class="map-salon__location-address"
                @click="$emit('focusToMark', salon.coordinates)">
                {{ salon.city }}, {{ salon.address }}
              </div>
            </div>

            <div class="map-salon__services">
              <div class="shop-item__services">
                <span
                  v-for="category in salon.categories"
                  :key="category.id"
                  class="shop-item__service"
                  role="tooltip"
                  data-microtip-position="top"
                  data-microtip-size="medium"
                  :aria-label="category.title">
                  <i class="shop-item__service-inner">
                    <img :src="category.image" alt="" />
                  </i>
                </span>
              </div>
            </div>

            <div v-if="$route.query.service" class="map-salon__order">
              <button
                type="button"
                class="shop-item__service-itemBtn btn"
                @click.prevent="setOrder(salon)">
                Забронировать
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <p v-else class="modal-salons__not-found">Ничего не найдено</p>
  </div>
</template>

<script setup>
import ModalSalonsSkeleton from "@/components/Skeleton/ModalSalonsSkeleton.vue";
import { composeSalonLink } from "@/helpers/links";

const orderModule = useOrder();
const searchStore = useSearch();
import { useRoute } from "vue-router";
const route = useRoute();
const { $bvModal } = useNuxtApp();

const props = defineProps({
  isLoading: { type: Boolean, default: false },
});

function getSalonLink(salon) {
  return composeSalonLink(salon);
}

function setOrder(salon) {
  const service = salon.salonServices.find((item) => {
    const serviceTitle = String(
      item.specialistService.serviceTitle
    ).toLowerCase();

    return serviceTitle.includes(route.query.service.toLowerCase());
  });
  if (!service) return;

  orderModule.setOrder({
    salon,
    serviceId: service.specialistService.serviceId,
    serviceTitle: service.specialistService.serviceTitle,
    categoryId: service.specialistService.categoryId,
  });
  if (!orderModule.order.serviceId) return;

  $bvModal.show("order-modal");
}
</script>

<style lang="scss">
@import "modal-salons";

.map-salon {
  &__item {
    text-decoration: none;
    color: inherit;
  }

  &__item-left img {
    max-width: 124px;
    min-width: 124px;
    object-fit: cover;
  }
}

.modal-salons {
  &__not-found {
    text-align: center;
    width: 100%;
    margin: 60px auto;
    font-size: 18px;
    line-height: 24px;
    font-weight: 300;
  }
}

.shop-item__service-inner {
  padding: 4px;
  height: 40px;
  width: 40px;

  img {
    width: inherit;
    height: inherit;
  }
}

.map-salon__order {
  margin-top: 10px;
}
</style>
