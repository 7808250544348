<template>
  <b-modal
    id="checkout-modal"
    hide-header
    hide-footer
    @show="createWidget"
    @hide="onHide">
    <div id="youkassa-checkout" />
  </b-modal>
</template>

<script>
import BModal from "@/components/Modals/BModal/BModal.vue";

export default {
  name: "CheckoutModal",

  components: {
    BModal,
  },

  methods: {
    async createWidget() {
      if (!process.client) {
        return;
      }
      const { $doNoty: doNoty } = useNuxtApp();
      const paymentModule = usePayment();
      await this.$nextTick();

      if (!paymentModule.confirmationToken) {
        this.showSuccess();
        this.$bvModal.hide("checkout-modal");
        return;
      }
      const Vue = this;
      const checkout = new window.YooMoneyCheckoutWidget({
        confirmation_token: paymentModule.confirmationToken,
        error_callback: function (error) {
          doNoty.error(error);
        },
      });

      checkout.on("success", () => {
        if (paymentModule.linkCard) {
          doNoty.success("Карта успешно привязана!");
        }
        this.showSuccess();
        this.$bvModal.hide("checkout-modal");
      });

      checkout.on("fail", () => {
        const message = paymentModule.linkCard
          ? "Ошибка при привязке карты!"
          : "Ошибка при оплате";
        doNoty.error(message);
      });

      checkout.render("youkassa-checkout");
    },

    async showSuccess() {
      const orderModule = useOrder();
      await orderModule.loadOrder(this.$route.query.orderId);

      const query = {
        ...this.$route.query,
        showSuccess: true,
      };
      delete query.orderId;
      this.$router.replace({ query }).catch(()=>{});

      this.$bvModal.show("order-modal");
    },

    onHide() {
      const paymentModule = usePayment();
      paymentModule.reset();
    },
  },
};
</script>
