<template>
  <b-modal
    id="auth-modal"
    @hide="onHide">
    <template #modal-header>
      <div class="auth-modal__title">
        <span>Авторизация на</span>
        <img src="@/assets/img/logo-black.svg" />
      </div>
      <button
        @click="$bvModal.hide('auth-modal')"
        class="auth-modal__close"></button>
    </template>
    <div class="auth-modal">
      <div v-if="isLoading" class="auth-modal__loader">
        <semipolar-spinner
          :animation-duration="2000"
          :size="50"
          style="
            position: absolute;
            left: calc(50% - 25px);
            top: calc(50% - 25px);" />
      </div>
      <AuthForm
        @recoveryPassword="recoveryPassword"
        @onLogin="onLogin"
        @changeLoading="isLoading=$event" />
    </div>
    <template #modal-footer>
      <span
        @click="registration"
        class="auth-modal__reg">Регистрация</span>
    </template>
  </b-modal>
</template>

<script>
import { SemipolarSpinner } from "epic-spinners";
import BModal from "@/components/Modals/BModal/BModal.vue";
import AuthForm from "@/components/Auth/AuthForm/AuthForm";

export default {
  components: {
    SpriteAuth: () => import("@/components/Auth/SpriteAuth/SpriteAuth"),
    SemipolarSpinner,
    BModal,
    AuthForm,
  },

  data: () => ({
    view: "auth",
    redirectUrl: "",
    isLoading: false,
  }),

  created() {
    this.$eventHub.$on("show-auth-modal", this.showAuthModal);
  },

  methods: {
    showAuthModal({ redirectUrl = "" }) {
      this.$bvModal.show("auth-modal");
      this.redirectUrl = redirectUrl;
    },

    onLogin() {
      if (this.redirectUrl) {
        this.$router.push(this.redirectUrl);
        this.redirectUrl = "";
      }
      this.$bvModal.hide("auth-modal");
    },

    onHide() {
      this.redirectUrl = "";
    },

    recoveryPassword() {
      this.$bvModal.hide("auth-modal");
      this.$bvModal.show("recovery-password-modal");
    },

    registration() {
      this.$bvModal.hide("auth-modal");
      this.$bvModal.show("registration-user-modal");
    },
  },
};
</script>

<style lang="scss">
@import "auth-modal.scss";
</style>
