import { Model } from "@/stores/models/Model";
import { getImageFromSrc } from "@/helpers/images";

export class GalleryImage extends Model {
  constructor(raw = {}) {
    super();
    this.id = Number(raw.id);
    this.name = getImageFromSrc(raw.name, true, true);
    this.userId = String(raw.user_id);
  }
}
