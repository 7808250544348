<template>
  <NuxtLayout>
    <NuxtPage/>
  </NuxtLayout>
  <CreditCartModal />
  <ReviewModal />
  <DeceptionModal />
  <OrderModal />
  <MapModal />
  <MapOrderModal />
  <PushModal />
  <MessageModal />
  <CheckoutModal />
  <PaymentSuccessModal />
  <AcceptCookies />
  <CancelModal />
  <PostponeModal />
  <PaymentModal />
  <AvatarModal />
  <AuthModal />
  <RegistrationUserModal />
  <RecoveryPasswordModal />
  <DecideYourself />
  <preloader-component v-show="isLoading"></preloader-component>
</template>

<script setup>
import PreloaderComponent from "@/components/PreloaderComponent/PreloaderComponent.vue";
import CreditCartModal from "@/components/Modals/CreditCartModal";
import ReviewModal from "@/components/Modals/ReviewModal";
import DeceptionModal from "@/components/Modals/DeceptionModal";
import OrderModal from "@/components/Modals/OrderModal/OrderModal.vue";
import MapModal from "@/components/Modals/MapModal";
import MapOrderModal from "@/components/Modals/MapOrderModal";
import PushModal from "@/components/Modals/PushModal.vue";
import MessageModal from "@/components/Modals/MessageModal.vue";
import CheckoutModal from "@/components/Modals/CheckoutModal.vue";
import PaymentSuccessModal from "@/components/Modals/PaymentSuccessModal.vue";
import AcceptCookies from "@/components/Modals/AcceptCookies";
import CancelModal from "@/components/Modals/CancelModal.vue";
import PostponeModal from "@/components/Modals/PostponeModal.vue";
import PaymentModal from "@/components/Modals/PaymentModal/PaymentModal.vue";
import AvatarModal from "@/components/Modals/AvatarModal/AvatarModal.vue";
import AuthModal from "@/components/Modals/AuthModal/AuthModal.vue";
import RegistrationUserModal from "@/components/Modals/RegistrationModal/RegistrationUserModal.vue";
import RecoveryPasswordModal from "@/components/Modals/RecoveryPasswordModal/RecoveryPasswordModal.vue";
import DecideYourself from "@/components/Modals/DecideYourself";

import { ref } from "vue";
import { useRoute } from "vue-router";
const route = useRoute();

const isLoading = ref();

useAsyncData(() => {
  isLoading.value = true;
});
onMounted(() => {
  isLoading.value = false;
});

useHead({
  title: import.meta.env.VITE_DEF_TITLE,
  link: [
    {
      rel: "stylesheet",
      href: "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css",
      integrity: "sha512-1ycn6IcaQQ40/MKBW2W4Rhis/DbILU74C1vSrLJxCq57o941Ym01SwNsOMqvEBFlcgUa6xLiPY/NS5R+E6ztJQ==",
      crossorigin: "anonymous",
      referrerpolicy: "no-referrer",
    },
  ],
  script: [
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/js/all.min.js",
      integrity: "sha512-Tn2m0TIpgVyTzzvmxLNuqbSJH3JP8jm+Cy3hvHrW7ndTDcJ1w5mBiksqDBb8GpE2ksktFvDB/ykZ0mDpsZj20w==",
      crossorigin: "anonymous",
      referrerpolicy: "no-referrer",
    },
  ],
});
</script>
