import { AuthModule } from "@webdevep/auth-modul-vue";

export const auth = new AuthModule({
  baseUrl: import.meta.env.VITE_AUTH_API_URL,
  lifeTimeToken: import.meta.env.VITE_LIFE_TIME_TOKEN,
  lifeTimeTokenWithRemember: import.meta.env.VITE_LIFE_TIME_TOKEN_WITH_REMEBER,
});

export default defineNuxtPlugin((nuxtApp) => {
  return {
    provide: {
      auth,
    },
  };
});
