import { useAccount } from "@/stores/account";
import { i18n } from "@/plugins/i18n";

const guestPaths = ["/login", "/signup", "/business", "/recovery-password"];
export default defineNuxtRouteMiddleware(async (to, from) => {
  if (!process.client) {
    return;
  }

  const { $auth: auth } = useNuxtApp();

  const isAuthorized = await auth.isAuth();

  try {
    const accountModule = useAccount();
    if (isAuthorized) {
      if (!accountModule.user) {
        try {
          await accountModule.loadUserInfo();
        } catch (err) {
          await auth.logout();
        }
      }
    } else if (/\/user/.test(to.path)) {
      if (process.client) {
        const { $doNoty: doNoty } = useNuxtApp();
        doNoty.error(i18n.global.tc("helpers.notAuth"));
      }
      await accountModule.signOut();
      return navigateTo(`/login?redirectUrl=${to.path}`);
    }
  } catch(err) {
    console.log({err});
  }
});
