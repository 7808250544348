import { mainApi } from "@/stores/api/main-api";

import { Salon } from "@/stores/models/Salon";
import { Specialist } from "@/stores/models/Specialist";

import get from "lodash/get";
import { Service } from "@/stores/models/Service";
import { Category } from "@/stores/models/Category";
import { SalonService } from "@/stores/models/SalonService";
import { SearchService } from "@/stores/models/SearchService";
import { Review } from "@/stores/models/Review";
import { GalleryImage } from "@/stores/models/GalleryImage";
import { useFavorites } from "./favorites";

export const useSalon = defineStore("salon", {
  state: () => ({
    isLoading: false,

    _salon: {},
    _categories: [],
    _services: [],
    _specialists: [],
    _similarSalons: [],
    _reviews: [],
    _images: [],
    _reviewStatistics: {},

    servicesData: [],
    specialistsData: [],

    isFavorite: false,
    categoryId: "",
  }),

  getters: {
    salon() {
      return new Salon({
        ...this._reviewStatistics,
        ...this._salon,
      });
    },

    images() {
      return this._images.map((item) => new GalleryImage(item));
    },

    salonServices() {
      return this._salon.salon_services.map((item) => {
        const service =
          this.specialistServices.find(
            (s) => s.id === item.specialist_service_id
          ) || {};
        return {
          ...new SalonService({
            ...item,
            title: service.serviceTitle,
            service_id: service.serviceId,
            category: this._categories.find((c) => c.id === service.categoryId),
          }),
          specialistService: service,
        };
      });
    },

    salonCategoriesServices() {
      return this.servicesData.reduce((acc, serviceData) => {
        acc[serviceData.categoryId] = serviceData.items.map(
          (item) => new SearchService(item)
        );
        return acc;
      }, {});
    },

    salonSpecialistsServices() {
      return this.specialistsData.reduce((acc, specialistData) => {
        acc[specialistData.categoryId] = specialistData.items.map(
          (item) => new Specialist(item)
        );
        return acc;
      }, {});
    },

    services() {
      return this._services.map((item) => new Service(item));
    },

    reviews() {
      return this._reviews.map((item) => new Review(item));
    },

    specialists() {
      return this._specialists.map(
        (item) =>
          new Specialist({
            ...item,
            specialist_service: item.specialist_service.map((item) => ({
              ...item,
              service: this._services.find((s) => s.id === item.service_id),
            })),
          })
      );
    },

    categories() {
      return this._categories.map((item) => new Category(item));
    },

    serviceCategories() {
      return this._categories
        .map((item) => new Category(item))
        .filter((item) =>
          this.salonServices.find((service) => service.categoryId === item.id)
        );
    },

    specialistCategories() {
      return this._categories
        .map((item) => new Category(item))
        .filter((item) =>
          this.specialistServices.find(
            (service) => service.categoryId === item.id
          )
        );
    },

    specialistServices() {
      return this.specialists.reduce((acc, item) => {
        acc.push(...item.services);
        return acc;
      }, []);
    },

    similarSalons() {
      return this._similarSalons
        .map((item) => new Salon(item))
        .filter((item) => item.id !== this.salon.id);
    },
  },

  actions: {
    setCategoryId(id) {
      this.categoryId = id;
    },

    async loadSalon(id) {
      this.isLoading = true;
      const favoritesModule = useFavorites();

      try {
        favoritesModule.loadFavoriteSalons();
        favoritesModule.loadFavoriteServices();

        const { data: salon } = await mainApi.getSalonById(id);
        this._salon = salon;
        this._categories = salon.categories;

        this.servicesData = [];
        this.specialistsData = [];
        this._categories.forEach((category) => {
          this.servicesData.push({
            categoryId: category.id,
            items: [],
            isLoading: false,
            canLoadMore: true,
          });
          this.specialistsData.push({
            categoryId: category.id,
            items: [],
            isLoading: false,
            canLoadMore: true,
          });
        });

        await this.loadServices(this.salon);
        this.isFavorite = favoritesModule.favoriteSalons.includes(this.salon.id);

        this.loadSimilarSalons();
        this.isLoading = false;
        return true;
      } catch (err) {
        if (process.client) {
          const { $doNoty: doNoty } = useNuxtApp();
          doNoty.error(err);
        }
        if (err.status === 404) {
          this.isLoading = false;
        }
        return false;
      }

    },

    async loadServices(salon) {
      try {
        const salonId = salon.id;
        const [{ data: reviews }, { data: reviewStatistics }] = await Promise.all(
          [
            mainApi.getReviews({
              salon_id: salonId,
              random: 1,
              limit: 20,
            }),
            mainApi.getReviewsStatistics({
              salon_id: salonId,
            }),
          ]
        );

        this._reviews = reviews.data;
        this._reviewStatistics = reviewStatistics[0]?.statistic || {};

        try {
          if (salon.galleryId) {
            const { data: gallery } = await mainApi.getGallery(salon.galleryId);
            this._images = gallery.images;
          }
        } catch (galleryErr) {
          this._images = [];
        }
      } catch (err) {
        const { $doNoty: doNoty } = useNuxtApp();
        doNoty.error(err);
      }
    },

    async loadCategoryServices(categoryId) {
      const service = this.servicesData.find(
        (item) => item.categoryId === categoryId
      );
      if (!service.canLoadMore) {
        return;
      }
      service.isLoading = true;

      try {
        const { data } = await mainApi.getSalonServices(this.salon.id, {
          category_id: categoryId,
          is_confirmed: 1,
          limit: 8,
          offset: Math.ceil(service.items?.length / 8),
        });
        service.items = [...service.items, ...data.data];
        if (!data.data?.length || data.count <= service.items?.length) {
          service.canLoadMore = false;
        }
      } catch (err) {
        const { $doNoty: doNoty } = useNuxtApp();
        doNoty.error(err);
      }
      service.isLoading = false;
    },

    async loadCategorySpecialists(categoryId) {
      const specialist = this.specialistsData.find(
        (item) => item.categoryId === categoryId
      );
      if (!specialist.canLoadMore) {
        return;
      }
      specialist.isLoading = true;

      try {
        const { data } = await mainApi.getSpecialists({
          salon_id: this.salon.id,
          category_id: categoryId,
          is_confirmed: 1,
          limit: 8,
          offset: Math.ceil(specialist.items?.length / 8),
        });
        specialist.items = [...specialist.items, ...data.data];
        if (!data.data?.length || data.count <= specialist.items?.length) {
          specialist.canLoadMore = false;
        }
      } catch (err) {
        const { $doNoty: doNoty } = useNuxtApp();
        doNoty.error(err);
      }

      specialist.isLoading = false;
    },

    async toggleFavorite() {
      const { $doNoty: doNoty } = useNuxtApp();
      const favoritesModule = useFavorites();
      this.isFavorite = !this.isFavorite;
      favoritesModule.setFavoriteSalon({
        salonId: this.salon.id,
        isFavorite: this.isFavorite,
      });
      if (this.isFavorite && !accountModule.userId)
        doNoty.success("Добавлено в избранное");
    },

    async loadSimilarSalons() {
      const { data } = await mainApi.searchSalons({
        limit: 10,
        salon_type_id: this.salon.salonTypeId,
        is_confirmed: 1,
      });
      const { data: stats } = await mainApi.getReviewsStatistics({
        salon_id: data.data.map((item) => item.id),
      });

      this._similarSalons = data.data.map((item) => ({
        ...(stats.find((s) => s.id === item.id)?.statistic || {}),
        ...item,
      }));
    },
  },
});
