<template>
  <div class="map-salon__items">
    <div v-for="i in count" :key="i" class="map-salon__item d-block">
      <div class="map-salon__item-inner">
        <div class="map-salon__item-left">
          <skeleton-loader-vue
            type="rect"
            width="124px"
            height="130px"
            rounded
            :radius="18"
            animation="fade"
          />
        </div>

        <div class="map-salon__item-right">
          <p class="map-salon__name">
            <skeleton-loader-vue
              type="rect"
              :width="120"
              :height="24"
              rounded
              :radius="18"
              animation="fade"
            />
          </p>

          <div class="map-salon__rating">
            <skeleton-loader-vue
              class="map-salon__rating-ball"
              type="circle"
              :width="30"
              :height="30"
              rounded
              :radius="18"
              animation="fade"
            />

            <div class="map-salon__rating-reviews">
              <skeleton-loader-vue
                type="rect"
                :width="96"
                :height="17"
                rounded
                :radius="18"
                animation="fade"
              />
            </div>
          </div>

          <div class="map-salon__location">
            <skeleton-loader-vue
              type="rect"
              :width="128"
              :height="14"
              rounded
              :radius="18"
              animation="fade"
            />
          </div>

          <div class="map-salon__services">
            <div class="shop-item__services">
              <div v-for="i in 4" :key="i" class="shop-item__service">
                <skeleton-loader-vue
                  type="rect"
                  :width="22"
                  :height="22"
                  rounded
                  animation="fade"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import SkeletonLoaderVue from "@/components/Skeleton/BaseSkeleton";
</script>
<script>
export default {
  name: "ModalSalonsSkeleton",

  props: {
    count: Number,
  },

  data: () => ({
    isMobile: window.innerWidth < 768,
  }),

  mounted() {
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth < 768;
    });
  },
};
</script>

<style lang="scss" scoped>
.map-salon {
  &__item-left img {
    max-width: 124px;
  }
}
</style>
