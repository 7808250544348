import { Model } from "@/stores/models/Model";

export class CashbackLevel extends Model {
  constructor(raw = {}) {
    super();
    this.amount = Number(raw.amount_to_spent || 0);
    this.cashback = Number(raw.cashback || 0);
    this.title = String(raw.title || "");
  }
}
