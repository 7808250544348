<template>
  <b-modal id="avatar-modal" hide-header hide-footer>
    <div
      class="modal show"
      id="avatar-modal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      @show="onShow"
      @click="$bvModal.hide('avatar-modal')"
    >
      <div class="modal-dialog avatar-modal" @click.stop>
        <div class="modal-content">
          <div ref="body" class="modal-body avatar-modal__body">
            <div class="avatar-modal__part avatar-modal__avatar-handler">
              <cropper
                v-if="avatarOriginal"
                :src="avatarOriginal"
                :debounce="100"
                min-width="100"
                min-height="100"
                background-class="avatar-modal__cropper-background"
                :stencil-props="{
                  aspectRatio: 1,
                }"
                @change="crop"
                class="avatar-modal__cropper"></cropper>
              <div v-else class="avatar-modal__upload">
                <span>Загрузите изображение</span>
                <input
                  id="avatar-1"
                  name="avatar-1"
                  type="file"
                  @change="change"
                />
              </div>
            </div>
            <div class="avatar-modal__part avatar-modal__result">
              <img v-if="avatarToLoad" :src="avatarToLoad" />
              <img v-else src="@/assets/img/no-image.png" />
            </div>
            <div class="avatar-modal__buttons">
              <div class="avatar-modal__button" @click="uploadAvatar">
                Сохранить
              </div>
              <div class="avatar-modal__button" @click="$bvModal.hide('avatar-modal')">
                Отменить
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script setup>
import BModal from "@/components/Modals/BModal/BModal.vue";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
</script>
<script>
import { mainApi } from "@/stores/api/main-api";

export default {
  name: "AvatarModal",
  data: () => ({
    avatarOriginal: "",
    avatarToLoad: "",
    file: null,
  }),

  created() {
    this.onShow();
  },

  methods: {
    onShow() {
      this.avatarOriginal = "";
      this.avatarToLoad = "";
    },

    crop({ canvas }) {
      const avatar = canvas.toBlob(async (blob) => {
        this.changeAvatar(blob);
      });
    },

    async changeAvatar(file, isChangeAvatarImage = false) {
      const image = await this.createImage(file);
      if (isChangeAvatarImage) {
        this.avatarOriginal = image;
      }
      this.avatarToLoad = image;
      this.file = file;
    },

    async change(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      if (files[0].size > 512 * 512) {
        const { $doNoty: doNoty } = useNuxtApp();
        doNoty.error("Слишком большой файл");
        return;
      } else {
        this.file = files[0];
        this.changeAvatar(this.file, true);
      }
    },

    createImage(file) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          return resolve(e.target.result);
        };
        reader.readAsDataURL(file);
      });
    },

    async uploadAvatar() {
      const accountModule = useAccount();
      const { data } = await mainApi.createImage(this.file);
      await accountModule.updateUserInfo({
        params: { avatar: `${import.meta.env.VITE_API_URL}/main-back/storage/${data.name}` },
      });
      this.$bvModal.hide("avatar-modal");
    },
  },
};
</script>

<style lang="scss">
@import "avatar-modal.scss";
</style>
