import Cookie from "js-cookie";

export function composeSalonLink(salon) {
  return `/${salon.slug || salon.id}`;
}

export function getCrmRedirectUrl() {
  const savedPath =
    localStorage.getItem("CRM_SAVED_PATH") || Cookie.get("CRM_SAVED_PATH");
  const redirectUrl =
    savedPath && import.meta.env.VITE_CRM_REDIRECT_URL
      ? `${import.meta.env.VITE_CRM_REDIRECT_URL}${savedPath}`
      : import.meta.env.VITE_CRM_REDIRECT_URL ||
        import.meta.env.VITE_HOME_REDIRECT_URL || { path: "/" };

  return redirectUrl
    .replace(/\/\/+/g, "/")
    .replace(/https?:(\/)/, (match) => `${match}/`);
}
