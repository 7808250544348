import { Model } from "@/stores/models/Model";
import { getRatingTitle } from "@/helpers/rating";
import { SpecialistService } from "./SpecialistService";
import { getMonthDiff } from "@/helpers/date";
import { getImageFromSrc } from "@/helpers/images";

export class Specialist extends Model {
  constructor(raw = {}) {
    super();
    this.id = Number(raw.id);
    this.userId = String(raw.user_id || "");
    this.salonId = Number(raw.salon_id);

    // TODO: get from back end
    const user = raw.user || {};
    this.email = String(user.email || "");

    const userInfo = user.userinfo || {};
    this.name = String(userInfo.name || "–");
    this.name =
      `${userInfo.firstName || ""} ${userInfo.lastName || ""}`.trim() || "-";
    this.avatarSrc = getImageFromSrc(userInfo.avatar);
    this.cover = getImageFromSrc(userInfo.cover);
    this.jobTitle = String(userInfo.specialization || "–");
    this.description = String(userInfo.description || "–");
    this.workExp = userInfo.work_exp
      ? (getMonthDiff(new Date(userInfo.work_exp), new Date()) / 12).toFixed(1)
      : "0";

    this.reviewsCount = Number(raw.review_count || 0);
    this.rating = Number(raw.rating || 0);
    this.ratingTitle = getRatingTitle(this.rating);

    this.createdAt = new Date(raw.created_at);
    this.updatedAt = new Date(raw.updated_at);

    this.services = raw.specialist_service?.map(
      (item) => new SpecialistService(item)
    );
  }

  get minPrice() {
    return Math.min(...this.services.map((item) => item.price)) || 0;
  }

  get minPriceWithDiscount() {
    return (
      Math.min(...this.services.map((item) => item.priceWithDiscount)) || 0
    );
  }
}
