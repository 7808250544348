<template>
  <b-modal id="payment-modal" hide-header hide-footer>
    <div
      class="modal show"
      id="salon-page__payment-modal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      @click="$bvModal.hide('payment-modal')"
    >
      <div
        v-if="order"
        class="modal-dialog modal-dialog-scrollable modal-dialog-centered"
        @click.stop
      >
        <div class="modal-content">
          <div ref="body" class="modal-body">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="$bvModal.hide('payment-modal')"
            />

            <div class="payment-modal__step2">
              <div class="payment-modal__step2-header">
                <div class="payment-modal__step2-title">Оплата заказа</div>
              </div>

              <div class="payment-modal__step2-top">
                <div class="payment-modal__salon-banner">
                  <img v-if="salon.images[0]" :src="salon.images[0]" />
                  <img v-else src="@/assets/img/no-image.png" />
                  <div class="payment-modal__salon-name d-flex align-items-center">
                    <div class="logo">
                      <img :src="salon.logo" alt="" />
                    </div>
                    {{ salon.name }}
                  </div>
                </div>

                <div class="payment-modal__order-info">
                  <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-12">
                      <div class="payment-modal__info-block specialist">
                        <div class="payment-modal__info-photo">
                          <img :src="selectedSpecialist.avatarSrc" alt="" />
                        </div>

                        <div class="payment-modal__info-content">
                          <div class="payment-modal__info-title">
                            Специалист
                          </div>

                          <div class="payment-modal__info-text">
                            {{ selectedSpecialist.name }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-12">
                      <div class="payment-modal__info-block time">
                        <div class="payment-modal__info-icon">
                          <img
                            src="@/assets/img/icon/calendar-black.svg"
                            alt=""
                          />
                        </div>

                        <div class="payment-modal__info-content">
                          <div class="payment-modal__info-title">
                            Выбранная дата и время
                          </div>

                          <div class="payment-modal__info-text">
                            <span class="date">{{ formattedDate }}</span>
                            <span class="time">{{ formattedTime }}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-xl-12">
                      <div class="payment-modal__info-block address">
                        <div class="payment-modal__info-icon">
                          <img
                            src="@/assets/img/icon/carbon_location.svg"
                            alt=""
                          />
                        </div>
                        <div class="payment-modal__info-content">
                          <div class="payment-modal__info-title">Адрес</div>
                          <div class="payment-modal__info-text">
                            {{ salon.address }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-xl-12">
                      <div class="payment-modal__info-block service">
                        <div class="payment-modal__info-icon">
                          <img src="@/assets/img/icon/brush.svg" alt="" />
                        </div>
                        <div class="payment-modal__info-content">
                          <div class="payment-modal__info-title">
                            Выбранная услуга:
                          </div>
                          <div class="payment-modal__info-text">
                            {{ selectedService.serviceTitle }}
                            ({{ selectedService.duration }} мин)
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <template v-if="company.isConfirmed">
                  <div class="payment-modal__bonus">
                    <div class="payment-modal__bonus-title">Бонусы</div>
                    <div class="payment-modal__bonus-block">
                      <div class="payment-modal__bonus-actions">
                        <label class="payment-modal__bonus-label">
                          <input
                            v-model="bonuses"
                            type="radio"
                            value="hold"
                            name="bonus_action"
                            checked
                          />
                          <span class="payment-modal__bonus-text"
                            >Накопить</span
                          >
                        </label>

                        <label class="payment-modal__bonus-label">
                          <input
                            v-model="bonuses"
                            type="radio"
                            value="spend"
                            name="bonus_action"
                          />
                          <span class="payment-modal__bonus-text"
                            >Потратить</span
                          >
                        </label>
                      </div>

                      <div class="payment-modal__bonus-summa">
                        {{ formatMoney(displayedBalance) }}
                      </div>
                    </div>
                  </div>

                  <div class="payment-modal__pay">
                    <div class="payment-modal__pay-title">
                      Выберите способ оплаты
                    </div>

                    <PaymentTypeSelect v-model="paymentType" />
                  </div>

                  <div class="payment-modal__total">
                    <div class="payment-modal__total-title">Итого</div>
                    <div class="payment-modal__summa">
                      <div class="payment-modal__total-summa">
                        {{ formatMoney(order.price - balance) }}
                      </div>

                      <div v-if="false" class="payment-modal__summa-cashback">
                        Кэшбек {{ cashback }}%
                        <button
                          type="button"
                          class="payment-modal__tooltip"
                          role="tooltip"
                          data-microtip-position="top-left"
                          data-microtip-size="large"
                          aria-label="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Arcu purus felis, non commodo, quis placerat tortor."
                        >
                          ?
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="block-agree">
                    <div class="block-agree__inner">
                      <label class="block-agree__label checkbox-label">
                        <input
                          v-model="isTermsAccepted"
                          type="checkbox"
                          class="block-agree__input checkbox-input"
                        />
                        <span class="block-agree__text checkbox-text">
                          Я принимаю и соглашаюсь со всеми условиями, которые
                          содержит
                          <a href="#">условия оплаты</a> и
                          <a href="#">условия отмены</a>
                        </span>
                      </label>
                    </div>
                  </div>

                  <div class="payment-modal__footer">
                    <button
                      type="submit"
                      class="payment-modal__btn btn btn-black"
                      :disabled="!isTermsAccepted || isProcessing"
                      @click="createOrder"
                    >
                      <template v-if="isProcessing">
                        Создание заказа…
                      </template>

                      <template v-else> Перейти к оплате </template>
                    </button>
                  </div>
                </template>

                <p v-else class="payment-modal__not-confirmed">
                  Компания не подтверждена, оплата невозможна
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script setup>
import { formatMoney } from "@/helpers/formatters";
import BModal from "@/components/Modals/BModal/BModal.vue";
</script>
<script>
import PaymentTypeSelect from "./PaymentTypeSelect.vue";
import get from "lodash/get";
import { PAYMENT_TYPES } from "@/stores/order";
import { paymentsApi } from "@/stores/api/payments-api";
import jsCookie from "js-cookie";

export default {
  name: "PaymentModal",
  components: {
    PaymentTypeSelect,
  },

  data() {
    return {
      paymentType: PAYMENT_TYPES.save,
      isTermsAccepted: false,
      isProcessing: false,
      bonuses: "hold",
    };
  },

  computed: {
    accountModule() {
      return useAccount();
    },
    ordersModule() {
      return useOrdersModule();
    },
    paymentModule() {
      return usePayment();
    },
    salon() {
      return this.order.salon;
    },

    company() {
      return this.salon.company;
    },

    order() {
      return this.ordersModule.selectedOrder;
    },

    selectedSpecialist() {
      return this.order.specialist || {};
    },

    selectedService() {
      return this.order.specialistService;
    },

    formattedDate() {
      const formatter = new Intl.DateTimeFormat(this.$i18n.locale, {
        day: "numeric",
        month: "numeric",
        year: "numeric",
      });

      return formatter.format(this.order.date);
    },

    formattedTime() {
      const formatter = new Intl.DateTimeFormat(this.$i18n.locale, {
        hour: "numeric",
        minute: "numeric",
      });

      return formatter.format(this.order.startAt);
    },

    balance() {
      return this.bonuses === "spend"
        ? Math.floor(Math.min(this.accountModule.balance, this.order.price * 0.2))
        : 0;
    },

    displayedBalance() {
      return this.bonuses === "spend"
        ? this.balance
        : Math.floor(
            this.order.price * this.accountModule.currentCashbackLevel.cashback
          );
    },

    cashback() {
      return this.accountModule.currentCashbackLevel.cashback * 100;
    },
  },

  methods: {
    async createOrder() {
      this.isProcessing = true;
      try {
        if (this.balance > 0) {
          const { data } = await paymentsApi.withdrawBalance({
            access_token: jsCookie.get("accessToken"),
            amount: this.balance,
            currency: "RUB",
          });
          this.accountModule.loadUserInfo();

          if (!data.ok) throw new Error(data.message);
        }

        await this.paymentModule.init({
          amount: this.order.price - this.balance,
          metadata: {
            order_id: String(this.order.id),
            user_id: this.accountModule.userId,
          },
          redirect: `${location.href}#success`,
          transferId: `$shopAccount:${this.salon.companyId}`,
          isSaved: this.paymentType === PAYMENT_TYPES.save,
          paymentId: [PAYMENT_TYPES.save, PAYMENT_TYPES.noSave].includes(
            this.paymentType
          )
            ? ""
            : this.paymentType,
        });

        this.$bvModal.hide("payment-modal");
        this.$bvModal.show("checkout-modal");
      } catch (err) {
        const { $doNoty: doNoty } = useNuxtApp();
        const errors = get(err, "response.data.errors", {});
        const error = get(Object.values(errors), "[0][0]");
        doNoty.error(error || err);
      } finally {
        this.isProcessing = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "payment-modal";

#payment-modal {
  & .modal-dialog > .modal-content {
    padding: 0px;
    border: 0;
    background: $white;
    border-radius: 5px;
  }

  & > .modal-dialog > .modal-content > .modal-body {
    padding: 0;
  }

  .modal {
    display: initial;
  }

  .date-time-picker__timepicker {
    max-width: 330px;
  }
}

.payment-modal {
  &__order-info {
    .row {
      flex-wrap: wrap;
    }
  }

  &__not-confirmed {
    margin-top: 15px;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    color: $red;
  }
}
</style>
