import { authApi } from "@/stores/api/auth-api";
import { mainApi } from "@/stores/api/main-api";
import { i18n } from "@/plugins/i18n";
import Cookie from "js-cookie";
import { auth } from "@/helpers/auth";

export const useAuth = defineStore("auth", {
  state: () => ({
    mailing: [],
    isAuthorized: false,
    remember: false,
    activeError: i18n.global.tc("auth.requiredField"),
  }),

  getters: {
    errors() {return this.activeError},
  },

  actions: {
    changeAuthStatus({ isAuthorized }) {
      this.isAuthorized = isAuthorized;
    },

    setRemember(remember) {
      this.remember = remember;
    },

    async getRecaptchaSiteKey() {
      const response = await authApi.getRecaptchaSiteKey();
      return response.data;
    },

    async sendConfirmCode({ email, phone }) {
      const body = {
        email: email,
        phone: phone,
      };
      await authApi.sendConfirmCode(body);
    },

    async login(params) {
      const { $doNoty: doNoty } = useNuxtApp();
      try {
        const response = await auth.login(params);
        await doNoty.success(i18n.global.tc("auth.success_auth"));
        return response;
      } catch (error) {
        doNoty.error(error.msg);
      }
    },

    async registerCompany(params) {
      const { $doNoty: doNoty } = useNuxtApp();
      try {
        const response = await mainApi.registerCompany(params);
        if (!response.data.id) throw response;

        await doNoty.success(
          i18n.global.tc("auth.success_register_company")
        );

        return response;
      } catch (error) {
        doNoty.error(error);
      }
    },

    async registerBusiness(params) {
      const { $doNoty: doNoty } = useNuxtApp();
      try {
        const response = await authApi.registerBusiness(params);
        if (response && !response.data.ok) throw response.data;
        return response;
      } catch (error) {
        doNoty.error(error.msg);
      }
    },

    resetPassword(params) {
      const { $doNoty: doNoty } = useNuxtApp();
      return authApi
        .resetPassword(params)
        .then((response) => {
          if (response.data.ok) {
            doNoty.success(i18n.global.tc("auth.passChanged"));
          } else {
            doNoty.error(response.data.msg);
          }
          return response;
        })
        .catch((error) => {
          doNoty.error(error);
        });
    },

    async registerClient(params) {
      const { $doNoty: doNoty } = useNuxtApp();
      try {
        const response = await authApi.registerClient(params);
        if (!response.data.ok) throw response.data;

        await doNoty.success(i18n.global.tc("auth.succees_register"));
        return response;
      } catch (error) {
        doNoty.error(error.msg);
      }
    },

    getOAuthLink(params) {
      return authApi
        .getOAuthLink(params)
        .then((response) => {
          if (response.data) {
            return response;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    async getInfoFromInn(params) {
      try {
        const response = await authApi.getInfoFromInn(params);
        if (response.data) return response;
      } catch (err) {
        console.error(err);
      }
    },

    async oauthLogin(params) {
      try {
        const response = await authApi.oauthLogin(params);
        if (response.data) {
          return response;
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
});
