<template>
  <div v-if="isCookiesShown" class="accept-cookies">
    <div class="accept-cookies__main container">
      <p class="accept-cookies__msg">
        Наш сайт использует файлы cookies. Используя сайт,
        вы соглашаетесь c
        <router-link class="accept-cookies__link" to="/privacy">
          использованием нами файлов cookies
        </router-link>
      </p>

      <button class="accept-cookies__btn" @click="acceptCookies">
        Принять
      </button>
    </div>
  </div>
</template>

<script>
import jsCookie from "js-cookie";

export default {
  name: "AcceptCookies",

  data() {
    return {
      isCookiesShown: false,
    };
  },

  created() {
    if (process.client) {
      this.isCookiesShown = jsCookie.get("acceptCookies") !== "true";
    }
  },

  methods: {
    acceptCookies() {
      jsCookie.set("acceptCookies", "true", {
        expires: 365,
      });
      this.isCookiesShown = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.accept-cookies {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 30px 0;
  background-color: $black;
  z-index: 99;
  box-shadow: 0 -6px 16px rgba(0, 0, 0, 0.13);

  &__main {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: 40px;
  }

  &__msg {
    color: $white;
    font-size: 16px;
    line-height: 1.5;
    margin: 0;
  }

  &__link {
    color: $white;
  }

  &__btn {
    border: none;
    outline: none;
    padding: 18px 35px;
    border-radius: 41px;
    font-weight: 600;
    font-size: 18px;
    color: $black;
  }

  @media (max-width: 767px) {
    padding: 20px 0;

    &__main {
      grid-template-columns: 1fr;
      gap: 20px;
    }
  }
}
</style>
